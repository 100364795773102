import { createContext, Dispatch, SetStateAction } from "react";

interface UserRoleContextType {
  userRole: string | null;
  setUserRole: Dispatch<SetStateAction<string | null>>;
}

const UserRoleContext = createContext<UserRoleContextType | undefined>(
  undefined
);

export default UserRoleContext;

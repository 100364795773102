import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import UserRoleProvider from "./Contexts/UserRoleProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SignIn from "./Pages/Login/Login";
import Bookings from "./Pages/Bookings/Bookings";
import ProtectedRoute from "Routes/ProtectedRoute";
import MainLayout from "Layouts/MainLayout";
import Operation from "Pages/Operation/Operation";
import Reports from "Pages/Reports/Reports";
import msalInstance from "Config/MsalConfig";
import { DateContextProvider } from "Contexts/DateContext";

function App() {
  const getToken = () =>
    localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
  const [authenticated, setAuthenticated] = useState(!!getToken());
  const [userPortName, setUserPortName] = useState("");
  //const [date, setDate] = useState<DateContext>()

  // new from Joel start
  const [isMsalReady, setIsMsalReady] = useState(false);

  useEffect(() => {
    // Initialize MSAL and handle redirect
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
        await msalInstance.handleRedirectPromise();
        setIsMsalReady(true);
      } catch (error) {
        console.error("MSAL authentication error:", error);
        setIsMsalReady(true); // Allow the app to load even if there's an error
      }
    };

    initializeMsal();
  }, []);

  if (!isMsalReady) {
    return <div>Loading authentication...</div>;
  }

  // new from Joel end

  return (
    <UserRoleProvider>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter
          future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true,
          }}
        >
          <DateContextProvider>
            <Routes>
              {/* Public Route - SignIn */}
              <Route
                path="/login"
                element={<SignIn setAuthenticated={setAuthenticated} />}
              />

              {/* Protected Routes - Require Authentication */}
              <Route
                path="/"
                element={
                  <ProtectedRoute authenticated={authenticated}>
                    <MainLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Navigate to="/operations" replace />} />{" "}
                {/* Redirect when accessing / */}
                <Route path="operations" element={<Operation />} />
                <Route path="bookings" element={<Bookings />} />
                <Route path="reports" element={<Reports />} />
              </Route>

              {/* Redirect unknown routes to SignIn */}
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </DateContextProvider>
        </BrowserRouter>
      </DndProvider>
    </UserRoleProvider>
  );
}

export default App;

const BookingSearch = ({ filter, setFilter }: any) => {
   return (
     <>
       <div>
         <input
           type="text"
           placeholder="Search..."
           className="border px-2 py-1 rounded w-40"
           value={filter || ""}
           onChange={(e) => setFilter(e.target.value)}
         />
       </div>
       {/* <Tooltip 
          id="bookingPageIcons"
          place="top"
          className="bookingPageTooltip"
          /> */}
     </>
   );
}
export default BookingSearch

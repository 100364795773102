import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from "./Config/MsalConfig";
import './App.css';
import App from 'App';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    //<React.StrictMode>
      <MsalProvider instance={msalInstance}>
      <App />
      </MsalProvider>
    //</React.StrictMode>
  );
}

reportWebVitals();

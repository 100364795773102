import React, { forwardRef, useEffect, useRef } from "react";
import "./TideTable.css";
import { TideDTO } from "Models/TideDTO";
import moment from "moment";
import html2canvas from "html2canvas";

interface TideTableProps {
  handleCapturedTideTable: (imgData: string) => void;
  tide: TideDTO[];
}

const TideTable = forwardRef(
  ({ handleCapturedTideTable, tide }: TideTableProps, ref) => {
    const graphRef = useRef<HTMLDivElement>(null);

    const captureGraph = () => {
      if (graphRef.current) {
        html2canvas(graphRef.current).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          handleCapturedTideTable(imgData);
        });
      }
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        captureGraph();
      }, 3000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <div className="reportTideFirstContainer" ref={graphRef}>
        <div style={{ padding: "10px" }}>
          {/* <label style={{fontSize: "40px"}}>Tide Chart</label> */}
        </div>
        <table className="tide-table">
          <thead>
            <tr>
              <th>DATE</th>
              <th>TIME</th>
              <th>EL (m)</th>
            </tr>
          </thead>
          <tbody>
            {tide.map((data, index) => (
              <tr key={index}>
                <td>{moment(data.tideDateTime).format("MMM-DD-YYYY")}</td>
                <td>{moment(data.tideDateTime).format("hh:mm A")}</td>
                <td>{data.height.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);
export default TideTable;

import Sidebar from "Components/SideBar/Sidebar";
import React from "react";
import { Outlet } from "react-router-dom"; // This is where the nested routes will be rendered

const MainLayout: React.FC = () => {
  return (
    <div className="">
      {/* Sidebar Component - Fixed on the left */}
      {/* <div className="fixed top-0 left-0 h-full w-[250px]">
        <Sidebar />
      </div> */}

      {/* Main content area */}
      {/* <div className="flex-1 ml-[250px] overflow-x-auto">
        <div>
          <Outlet />
        </div>
      </div> */}
      <Outlet />
    </div>
  );
};

export default MainLayout;

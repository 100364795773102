import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  authenticated: boolean;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  authenticated,
  children,
}) => {
  // If not authenticated, redirect to login page
  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  // If authenticated, render the children components (MainLayout with inner routes)
  return <>{children}</>;
};

export default ProtectedRoute;

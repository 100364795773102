import React, { useState, useEffect, ReactNode } from "react";
import UserRoleContext from "./UserRoleContext";

interface UserRoleProviderProps {
  children: ReactNode;
}

const UserRoleProvider: React.FC<UserRoleProviderProps> = ({ children }) => {
  const [userRole, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    const storedRole =
      localStorage.getItem("userRole") || sessionStorage.getItem("userRole");
    if (storedRole) {
      setUserRole(storedRole);
    }
  }, []);

  return (
    <UserRoleContext.Provider value={{ userRole, setUserRole }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export default UserRoleProvider;

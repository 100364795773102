const BACK_END =
  process.env.NODE_ENV === "production"
    ? "https://portconbackend-dudmgsdtdzhybhhp.canadaeast-01.azurewebsites.net"
    : "https://localhost:7163";

const CONSTANTS = Object.freeze({
  BACK_END: BACK_END,
  FRONT_END: "http://localhost:3000/",
});

export default CONSTANTS;

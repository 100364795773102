/**
 * @file SignIn.tsx is a component that handles user login using MSAL and performs role/group based operations.
 * @author:Anthony Odu
 * @date 2021-02-21
 * Description:
 *   This component handles user authentication via MSAL. It displays a
 *   slideshow background and includes buttons for navigation (Demo, About,
 *   Contact Us). Upon clicking "Sign In", it triggers the login popup, stores
 *   tokens, sets the user role, and iterates through group IDs to fetch a port.
 *   The first valid port found will break the loop, and the user is navigated
 *   to the operations map on successful authentication.
 */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserRoleContext } from "../../Contexts/useUserRoleContext";
import msalInstance from "../../Config/MsalConfig";
import { useApi } from "../../api/api";
import { PortDTO } from "../../Models/PortDTO";

interface SignInProps {
  // Function to update the authentication state of the app
  setAuthenticated: (authenticated: boolean) => void;
}

/**
 * SignIn component handles user login using MSAL and performs role/group based operations.
 *
 * - It initiates a login popup for authentication.
 * - Retrieves the access token and stores it in local and session storage.
 * - Sets the user role based on the roles returned in the ID token claims.
 * - Iterates over group IDs and stops on the first successful API call to fetch a Port.
 * - Navigates the user to the operations map page upon successful authentication.
 */
function SignIn({ setAuthenticated }: SignInProps) {
  const { setUserRole } = useUserRoleContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Array of images for the slideshow background
  const images = [
    "/images/LandingPage14.jpg",
    "/images/LandingPage15.jpg",
    "/images/LandingPage16.jpg",
    "/images/LandingPage20.jpg",
    "/images/LandingPage18.jpg",
  ];

  // useApi hook returns a request function to perform API calls.
  // The API response is expected to have the shape: { data: PortDTO }
  const { request } = useApi<PortDTO>();

  /**
   * getPortId wraps the API call to fetch port data by a given objectId.
   * @param objectId - The unique identifier used to fetch a port.
   * @returns A Promise that resolves to the PortDTO if found, or null otherwise.
   */
  const getPortId = async (objectId: string): Promise<PortDTO | null> => {
    try {
      const response = await request(`/Port/portObjectId/${objectId}`, "GET");
      // Return the port data if it exists
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.error("Error fetching port:", error);
      return null;
    }
  };

  /**
   * handleLogin initiates the login process using MSAL.
   * - It triggers a login popup for the user to sign in.
   * - Stores the access token in both local and session storage.
   * - Sets the user role based on the idTokenClaims.
   * - Iterates through the user's groups (as object IDs) and stops once a port is found.
   * - Navigates to the operations map upon successful authentication.
   */

  const handleLogin = async () => {
    setLoading(true);
    try {
      // Initiate login via MSAL redirect with required scopes
      await msalInstance.loginRedirect({
        scopes: ["api://d7c2bf62-20bf-4852-8082-a615dd4aa053/App.Read"],
      });
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("Login Failed. Please try again.", {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };

  //new from joel
  // useEffect(() => {
  //   if (window.location.hash) {
  //     msalInstance.handleRedirectPromise().catch((error) => {
  //       console.error("Error handling redirect:", error);
  //     });
  //   }
  // }, []);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const response = await msalInstance.handleRedirectPromise();
        if (response) {
          //console.log("MSAL Redirect Response:", response);
          const { accessToken, account } = response;

          // Store the access token in local and session storage
          localStorage.setItem("jwtToken", accessToken);
          sessionStorage.setItem("jwtToken", accessToken);
          localStorage.setItem("userName", account.name || "User");
          sessionStorage.setItem("userName", account.name || "User");
          localStorage.setItem("userEmail", account.username);
          sessionStorage.setItem("userEmail", account.username);
          console.log("access token: ", accessToken)
          // Extract roles and groups from idTokenClaims
          const roles = (response.idTokenClaims as { roles: string[] }).roles;
          const groups = (response.idTokenClaims as { groups: string[] }).groups;

          if (!roles) {
            setUserRole("User");
            localStorage.setItem("userRole", "User");
            sessionStorage.setItem("userRole", "User");
          } else {
            setUserRole(roles[0]);
            localStorage.setItem("userRole", roles[0]);
            sessionStorage.setItem("userRole", roles[0]);
          }

          // Iterate through object IDs to find the first valid port
          if (response.idTokenClaims && groups) {
            for (let objectId of groups) {
              const foundPort = await getPortId(objectId);
              if (foundPort) {
                console.log("Port found:", foundPort.id);
                sessionStorage.setItem("userPortId", foundPort.id.toString());
                localStorage.setItem("userPortId", foundPort.id.toString());
                break;
              }
            }
          }

          // Set authenticated state and navigate to the operations page
          setAuthenticated(true);
          navigate("/operations");
        }
      } catch (error) {
        console.error("Error handling redirect:", error);
      }
    };

    handleRedirect();
  }, []);

  //new from joel


  /**
   * useEffect hook sets up an interval for a slideshow background.
   * It cycles through the images array by updating the currentImageIndex every 4 seconds.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [images.length]);

  const portConLogo =
    "/images/IconsAndLogos/PortconLogoHorizontalFullColor300dpi.jpg";

  return (
    <div className="login2Container1">
      <ToastContainer />
      <div className="login2Container2">
        <img
          src={portConLogo}
          style={{ width: "350px", height: "auto", marginLeft: "100px" }}
          alt="PortCon Logo"
        ></img>
        <div className="buttonGroup2">
          <button className="btn menuButton2">Demo</button>
          <button className="btn menuButton2">About</button>
          <button className="btn menuButton2">Contact Us</button>
        </div>
      </div>

      <div className="login2Container3">
        {/* Display images as a slideshow */}
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="Slideshow"
            className={currentImageIndex === index ? "active" : ""}
          />
        ))}

        {/* Button to initiate the login process */}
        <button
          onClick={handleLogin}
          className="btn login2Button"
          disabled={loading}
        >
          {loading ? "Logging in..." : "Sign In"}
        </button>
      </div>
    </div>
  );
}

export default SignIn;

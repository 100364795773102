/**
 * API Utility Module
 * @author:Anthony Odu
 * @date 2021-02-21
 * This module provides an Axios-based API client and a custom React hook (`useApi`)
 * to facilitate API interactions in a React application. It includes JWT authentication,
 * error handling, and a global interceptor for handling unauthorized responses.
 */

import { useState, useCallback } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Constants from "../Constants";

/**
 * Type definition for the API state managed by the `useApi` hook.
 *
 * @template T - The expected response data type.
 */
type ApiState<T> = {
  data?: T;
  loading: boolean;
  error?: string;
};

// Base URL for API requests
const API_BASE_URL = `${Constants.BACK_END}/api`;

// Create an Axios instance with default configuration
export const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Retrieves the JWT authentication token from sessionStorage or localStorage.
 *
 * @returns {string | null} The JWT token if available, otherwise null.
 */
const getAuthToken = () =>
  sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");

/**
 * Axios request interceptor to attach the JWT token to every request.
 */
apiClient.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

/**
 * Axios response interceptor to handle 401 Unauthorized responses globally.
 * If a user is unauthorized, their JWT token is removed and they are redirected to the login page.
 */
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.error("Unauthorized! Redirecting to login...");
      localStorage.removeItem("jwtToken"); // Ensure token removal from storage
      sessionStorage.removeItem("jwtToken");
      window.location.href = "/login"; // Redirect user to login page
    }
    return Promise.reject(error);
  }
);

/**
 * Custom React hook for making API requests with automatic state management.
 *
 * @template T - The expected response data type.
 * @returns An object containing `data`, `loading`, `error`, and a `request` function.
 */
export function useApi<T>() {
  // Initialize API state
  const [state, setState] = useState<ApiState<T>>({
    data: undefined,
    loading: false,
    error: undefined,
  });

  /**
   * Makes an API request using Axios with the given parameters.
   *
   * @param {string} url - The API endpoint (relative to the base URL).
   * @param {"GET" | "POST" | "PUT" | "DELETE"} method - HTTP method.
   * @param {any} [payload] - Optional data to send in POST/PUT requests.
   * @returns {Promise<T>} The response data of type T.
   */
  const request = useCallback(
    async (
      url: string,
      method: "GET" | "POST" | "PUT" | "DELETE",
      payload?: any
    ): Promise<T> => {
      // Set loading state and reset any previous error
      setState((prev) => ({ ...prev, loading: true, error: undefined }));

      // Construct the Axios request config
      const config: AxiosRequestConfig = {
        url,
        method,
        data: payload,
      };
      try {
        //console.log("API Request:", (config.baseURL || API_BASE_URL) + config.url,);
        // Execute the API request
        const response: AxiosResponse<T> = await apiClient(config);
        // Update state with successful response data
        setState({ data: response.data, loading: false, error: undefined });
        return response.data;
      } catch (err: any) {
        console.error("API Error:", err);

        // Extract a user-friendly error message
        const errorMessage =
          err.response?.data?.message || err.message || "Something went wrong";

        // Update state with the error message
        setState((prev) => ({
          ...prev,
          error: errorMessage,
          loading: false,
        }));

        return Promise.reject(errorMessage);
      }
    },
    []
  );

  return { ...state, request };
}

/**
 * Example Usage:
 *
 * // Fetching customers
 * const { data, loading, error, request } = useApi<{ data: CustomerDTO[] }>();
 *
 * useEffect(() => {
 *   request("/customers", "GET");
 * }, [request]);
 *
 * // Creating a new customer
 * const { request } = useApi<{ data: CustomerDTO }>();
 *
 * const handleCreateCustomer = async () => {
 *   try {
 *     const response = await request("/customers", "POST", { name, email });
 *     console.log("Customer created:", response.data);
 *   } catch (error) {
 *     console.error("Error creating customer:", error);
 *   }
 * };
 */

import React from "react";
import { Group, Image } from "react-konva";
import useImage from "use-image";


// Define the props interface
interface VesselImageProps {
  url: string;
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
rotation: number;
}

// Memoized VesselImage component
const VesselImage: React.FC<VesselImageProps> = React.memo(
  ({ url, x, y, scaleX, scaleY, rotation }) => {
    const [image] = useImage(url);

    if (!image) {
      return null; // Optionally show a placeholder or fallback
    }

    return (
      <Group x={x} y={y} scaleX={scaleX} scaleY={scaleY} rotation={rotation}>
        <Image image={image} />
      </Group>
    );
  }
);

export default VesselImage;
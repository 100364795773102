import useContextWrapper from "Config/ContextWrapper";
import moment from "moment";
import React, { createContext, useMemo, useState } from "react";

interface IDateContext {
  date: string;
  setDate: React.Dispatch<React.SetStateAction<IDateContext["date"]>>;
}

export const DateContext = createContext<IDateContext>({
  date: moment().format("YYYY-MM-DD"),
  setDate: () => {},
});

export const DateContextProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [date, setDate] = useState<IDateContext["date"]>(
    moment().format("YYYY-MM-DD")
  );

  const value = useMemo(() => ({ date, setDate }), [date]);

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
};

export const useDateContext = () =>
  useContextWrapper(DateContext, {
    contextName: useDateContext.name,
    providerName: DateContextProvider.name,
  });

import { toast } from "react-toastify";
import { useApi } from "../api/api";
import {
  BookingData,
  BookingResource,
  SubmitBookingData,
} from "../Models/Booking";
import { useApi as useApi2 } from "../api/api";
import { use, useCallback, useState } from "react";
import {
  AssemblyResourceData,
  AvailableResource,
  AvailableResourceForAssemblyResponse,
} from "../Models/Assembly";
import { BerthData } from "../Models/Berth";
import { AgentDTO, VesselDTO } from "../Models/VesselDTO";
import { VesselSpecialNoteDTO } from "../Models/VesselSpecialNoteDTO";

// Define the form data for a reservation
export interface ReservationFormData {
  bookingResourceId: number;
  bookingId: number;
  gangwayId: number;
  platformId?: number;
  vesselDoorId: number;
  startDateTime: string;
  endDateTime: string;
  assemblyId: number;
  resourceType: string;
  xcordinate?: number;
  ycordinate?: number;
}

export interface UpdateGangWayCordinatesReservationFormData {
  xcordinate: number;
  ycordinate: number;
}

export interface AvailableAssemblyResource {
  isGangwayRequired: boolean;
  isPlatformRequired: boolean;
  gangways: AssemblyResourceData[];
  platforms: AssemblyResourceData[];
}

// Define the return type for our hook
export interface UseOperationsMapBookingResourcesReturn {
  // bookingResources: BookingResource[];
  handleRemoveBookingResource: (
    bookingId: number,
    vesselDoorId: number
  ) => Promise<boolean | void>;
  handleSelectBookingResource: (
    selectedResource: BookingResource,
    berthId: number
  ) => Promise<void>;
  fetchBookingResources: (
    bookingId: number
  ) => Promise<BookingResource[] | void>;
  // hasBookingResource: (bookingId: number) => boolean;
  addGangwayReservation: (
    formData: ReservationFormData,
    closeModal: () => void
  ) => Promise<boolean | void>;
  updateGangwayReservation: (
    formData: ReservationFormData,
    closeModal: () => void
  ) => Promise<void>;
  getAvailableResourcesPerGroup: (
    modalAssemblyId: number,
    startDateTime: string,
    endDateTime: string
  ) => Promise<AvailableAssemblyResource | void>;
  checkBookingResourceExists: (bookingId: number) => Promise<boolean | void>;
  fetchBerthNames: () => Promise<BerthData[] | void>;
  fetchVessels: () => Promise<VesselDTO[] | void>;
  fetchVesselsSpecialNote: (
    value: string
  ) => Promise<VesselSpecialNoteDTO | void>;
  deleteBookingResource: (bookingId: number) => Promise<boolean | void>;
  handleBookingSubmit: (
    bookingId: number | null,
    formData: SubmitBookingData
  ) => Promise<boolean | void>;
  deleteBooking: (bookingId: number) => Promise<boolean | void>;
  updateGangwayResourcePosition: (
    bookingResourceId: number,
    xcordinate: number,
    ycordinate: number,
    rotation: number
  ) => Promise<void>;
  fetchAgent: () => Promise<AgentDTO[] | void>;
}

const useBookingResourceOperations =
  (): UseOperationsMapBookingResourcesReturn => {
    // API hooks for requests
    const { request: bookingResourceRequest } = useApi<BookingResource[]>();
    const { request: singleBookingResourceRequest } = useApi<BookingResource>();
    const { request: availableResourceRequest } =
      useApi2<AvailableResourceForAssemblyResponse>();
    const { request: fetchVesselsRequest } = useApi<VesselDTO[]>();
    const { request: fetchBerthNamesRequest } = useApi<BerthData[]>();
    const { request: fetchVesselsSpecialNoteRequest } =
      useApi<VesselSpecialNoteDTO>();
    const { request: bookingRequest } = useApi<void>();
    const { request: booleanRequest } = useApi<boolean>();
    const { request: agentRequest } = useApi<AgentDTO[]>();
    // POST a new gangway reservation
    const addGangwayReservation = async (
      formData: ReservationFormData,
      closeModal: () => void
    ): Promise<boolean | void> => {
      try {
        const response = await singleBookingResourceRequest(
          "/BookingResource",
          "POST",
          formData
        );
        toast.success("Reservation added successfully", {
          position: "top-center",
          hideProgressBar: true,
        });
        setTimeout(() => closeModal(), 1500);
        return true;
      } catch (error) {
        console.error("Error adding gangway reservation:", error);
        toast.error("Error adding reservation", {
          position: "top-center",
          hideProgressBar: true,
        });
      }
    };

    // PUT to update an existing gangway reservation
    const updateGangwayReservation = async (
      formData: ReservationFormData,
      closeModal: () => void
    ): Promise<void> => {
      try {
        const response = await singleBookingResourceRequest(
          `/BookingResource/gangwayUpdate/${formData.bookingResourceId}`,
          "PUT",
          formData
        );
        toast.success("Reservation updated successfully", {
          position: "top-center",
          hideProgressBar: true,
        });
        setTimeout(() => closeModal(), 1500);
      } catch (error) {
        console.error("Error updating gangway reservation:", error);
        toast.error("Error updating reservation", {
          position: "top-center",
          hideProgressBar: true,
        });
      }
    };

    const updateGangwayResourcePosition = async (
      bookingResourceId: number,
      xcordinate: number,
      ycordinate: number,
      rotation: number
    ): Promise<void> => {
      try {
        const response = await singleBookingResourceRequest(
          `/BookingResource/resourceUpdatePosition/${bookingResourceId}/${xcordinate}/${ycordinate}/${rotation}`,
          "PUT"
        );
        toast.success("Reservation updated successfully", {
          position: "top-center",
          hideProgressBar: true,
        });
      } catch (error) {
        console.error("Error updating gangway reservation:", error);
        toast.error("Error updating reservation", {
          position: "top-center",
          hideProgressBar: true,
        });
      }
    };

    // Handle selection of a booking resource (updates berth and posts resource)
    const handleSelectBookingResource = async (
      selectedResource: BookingResource,
      berthId: number
    ): Promise<void> => {
      const { bookingId } = selectedResource;
      try {
        // Update berth information
        await booleanRequest(
          `/Booking/updateBerth/${bookingId}/${berthId}`,
          "PUT"
        );

        // Post the selected resource
        await singleBookingResourceRequest(
          "/BookingResource",
          "POST",
          selectedResource
        );

        toast.success("Settings saved", {
          position: "top-center",
          hideProgressBar: false,
        });
      } catch (error) {
        console.error("Error updating booking resource:", error);
        toast.error("Error saving settings", {
          position: "top-center",
          hideProgressBar: true,
        });
      }
    };

    // Remove a booking resource
    const handleRemoveBookingResource = async (
      bookingId: number,
      vesselDoorId: number
    ): Promise<boolean | void> => {
      try {
        await bookingRequest(
          `/BookingResource/delete/bookingId/${bookingId}/vesselDoorId/${vesselDoorId}`,
          "DELETE"
        );
        toast.success("Resource removed", {
          position: "top-center",
          hideProgressBar: false,
        });
        return true;
      } catch (error) {
        console.error("Error deleting resource:", error);
        toast.error("Error removing resource", {
          position: "top-center",
          hideProgressBar: true,
        });
      }
    };

    // Fetch booking resources for a given bookingId and update state accordingly
    const fetchBookingResources = useCallback(
      async (bookingId: number): Promise<BookingResource[] | void> => {
        try {
          const response = await bookingResourceRequest(
            `/BookingResource/bookingId/${bookingId}`,
            "GET"
          );
          return response;
        } catch (error) {
          console.error("Error fetching booking resources:", error);
        }
      },
      [bookingResourceRequest]
    );

    // Fetch booking resources for a given bookingId and update state accordingly
    const checkBookingResourceExists = useCallback(
      async (bookingId: number): Promise<boolean | void> => {
        try {
          const response = await booleanRequest(
            `/BookingResource/exists/${bookingId}`,
            "GET"
          );
          return response;
        } catch (error) {
          console.error("Error fetching booking resources:", error);
        }
      },
      [bookingResourceRequest]
    );

    // Check if a booking has any associated resources
    // const hasBookingResource = (bookingId: number): boolean => {
    //   // fetch booking resources

    //   return bookingResources.some(
    //     (resource: BookingResource) => resource.bookingId === bookingId
    //   );
    // };

    // fetch berth names for a given userPortId
    const fetchBerthNames = useCallback(async (): Promise<
      BerthData[] | void
    > => {
      try {
        const userRole = localStorage.getItem("userRole");
        const userPortId = localStorage.getItem("userPortId");
        const url =
          userRole === "Admin"
            ? `/Berth/BerthNames`
            : `/Berth/portId/${userPortId}`;
        const response = await fetchBerthNamesRequest(url, "GET");
        return response;
      } catch (error) {
        console.error("Error fetching berth names:", error);
      }
    }, [bookingResourceRequest]);

    // fetch all vessels
    const fetchVessels = useCallback(async (): Promise<VesselDTO[] | void> => {
      try {
        const response = await fetchVesselsRequest("/Vessel", "GET");
        return response;
      } catch (error) {
        console.error("Error fetching vessels:", error);
      }
    }, [bookingResourceRequest]);

    const getAvailableResourcesPerGroup = useCallback(
      async (
        modalAssemblyId: number,
        startDateTime: string,
        endDateTime: string
      ): Promise<AvailableAssemblyResource | void> => {
        const toUtcZFormat = (dateString: string) => {
          const date = new Date(dateString);
          return date.toISOString(); // Format as ISO 8601 and ensure 'Z' without milliseconds
        };

        const adjustedStartDateTime = toUtcZFormat(startDateTime);
        const adjustedEndDateTime = toUtcZFormat(endDateTime);

        try {
          const response = await availableResourceRequest(
            `/Assembly/getAvailableResources/assemblyId/${modalAssemblyId}/startDateTime/${adjustedStartDateTime}/endDateTime/${adjustedEndDateTime}`,
            "GET"
          );
          const gangways =
            response.resources.find((item) => item.type === "Gangways")?.data ||
            [];
          const platforms =
            response.resources.find((item) => item.type === "Platforms")
              ?.data || [];

          return {
            isGangwayRequired: response.gangwayRequired,
            isPlatformRequired: response.platformRequired,
            gangways: gangways,
            platforms: platforms,
          };
        } catch (error) {
          console.error("Error fetching gangway data:", error);
        }
      },
      [availableResourceRequest]
    );

    //`${Constants.BACK_END}/api/VesselSpecialNote/port/${userPortId}/vesselId/${value}`,
    // fetch all vessels
    const fetchVesselsSpecialNote = useCallback(
      async (value: string): Promise<VesselSpecialNoteDTO | void> => {
        try {
          const userPortId = localStorage.getItem("userPortId");
          const response = await fetchVesselsSpecialNoteRequest(
            `/VesselSpecialNote/port/${userPortId}/vesselId/${value}`,
            "GET"
          );
          return response;
        } catch (error) {
          console.error("Error fetching vessels:", error);
        }
      },
      [bookingResourceRequest]
    );

    // Remove a booking resource
    const deleteBooking = useCallback(
      async (bookingId: number): Promise<boolean | void> => {
        try {
          const userRole = localStorage.getItem("userRole");
          if (userRole === "Admin" || userRole === "PortAdmin") {
            const response = await booleanRequest(
              `/Booking/delete/${bookingId}`,
              "DELETE"
            );
            return true;
          } else {
            toast.error(
              "You do not have the necessary permissions to delete this booking.",
              {
                position: "top-center",
                hideProgressBar: true,
              }
            );
          }
        } catch (error) {
          console.error("Error deleting resource:", error);
        }
      },
      [booleanRequest]
    );

    const deleteBookingResource = useCallback(
      async (bookingId: number): Promise<boolean | void> => {
        try {
          const response = await booleanRequest(
            `/BookingResource/delete/bookingId/${bookingId}`,
            "DELETE"
          );
          return true;
        } catch (error) {
          console.error("Error deleting resource:", error);
        }
      },
      [booleanRequest]
    );
    // handle booking submit
    const handleBookingSubmit = useCallback(
      async (
        bookingId: number | null,
        formData: SubmitBookingData
      ): Promise<boolean | void> => {
        const userRole = localStorage.getItem("userRole");
        if (userRole && !["PortAdmin"].includes(userRole)) {
          toast.error(
            "You do not have the necessary permissions to update this booking.",
            {
              position: "top-center",
              hideProgressBar: true,
            }
          );
          return;
        }
        try {
          if (bookingId) {
            await bookingRequest(`/Booking/${bookingId}`, "PUT", formData);
            toast.success("Booking updated successfully", {
              position: "top-center",
              hideProgressBar: true,
            });
            return true;
          } else {
            await bookingRequest(`/Booking`, "POST", formData);
            toast.success("Booking Added successfully", {
              position: "top-center",
              hideProgressBar: true,
            });
            return true;
          }
        } catch (error) {
          console.error(`Error submitting booking:${error}`, error);
          if (bookingId) {
            toast.error(`Error updating booking: ${error}`, {
              position: "top-center",
              hideProgressBar: true,
            });
          } else {
            toast.error(`Error adding booking: ${error}`, {
              position: "top-center",
              hideProgressBar: true,
            });
          }
        }
      },
      [bookingRequest]
    );

    // fetch all agents
    const fetchAgent = useCallback(async (): Promise<AgentDTO[] | void> => {
      try {
        const response = await agentRequest("/Agent", "GET");
        return response;
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    }, [bookingResourceRequest]);

    return {
      //   bookingResources,
      handleRemoveBookingResource,
      handleSelectBookingResource,
      fetchBookingResources,
      //  hasBookingResource,
      addGangwayReservation,
      updateGangwayReservation,
      getAvailableResourcesPerGroup,
      checkBookingResourceExists,
      fetchBerthNames,
      fetchVessels,
      fetchVesselsSpecialNote,
      deleteBookingResource,
      handleBookingSubmit,
      deleteBooking,
      updateGangwayResourcePosition,
      fetchAgent,
    };
  };

export default useBookingResourceOperations;

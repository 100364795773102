import React, { use, useContext, useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  TooltipProps,
} from "recharts";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import "moment-timezone";
import "react-toastify/dist/ReactToastify.css";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { TideDTO } from "../../../Models/TideDTO";

interface OperationsMapTideProps {
  tide: TideDTO[];
}
export const TideCustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const formattedDate = new Date(label).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    const height = payload[0]?.value;
    const formattedHeight =
      typeof height === "number" ? `${height.toFixed(2)} m` : "N/A";
    return (
      <div className="customTooltip">
        {`Time: ${formattedDate}    `}
        {`Height: ${formattedHeight}`}
      </div>
    );
  }
};

export const TideCustomTooltipDate = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const date = new Date(label);
    const formattedDate = date
      .toLocaleString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(",", ""); // Removes unwanted comma in some locales

    const height = payload[0]?.value;
    const formattedHeight =
      typeof height === "number" ? `${height.toFixed(2)} m` : "N/A";

    return (
      <div className="customTooltip">
        {`Time: ${formattedDate}    `}
        {`Height: ${formattedHeight}`}
      </div>
    );
  }
};


function OperationsMapTide({ tide }: OperationsMapTideProps) {
  return (
    <div className="operationsMapTideTopContainer">
      <label className="bookingLabels" htmlFor="selectedDate">
        Tide Data
      </label>
      <ResponsiveContainer width="100%" height={100}>
        <LineChart data={tide}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="tideDateTime"
            tickFormatter={(tick) =>
              new Date(tick).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
            }
          />
          <YAxis dataKey="height"> </YAxis>
          <Tooltip cursor={false} content={<TideCustomTooltip />} />
          <Line
            type="monotone"
            dataKey="height"
            stroke="#22418a"
            dot={false}
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
export default OperationsMapTide;

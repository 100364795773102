import { useApi } from "../api/api";
import { use, useCallback, useState } from "react";
import { CalculationsDTO, SlopeCalculation } from "Models/CalculationsDTO";

// Define the return type for our hook
export interface UseCalculateRequestProps {
  //doorSlopeData: CalculationsDTO[];
  fetchSlopData: (
    adjustedMooringDateTime: string,
    adjustedDepartureDateTime: string,
    doorId: number,
    berthId: number,
    assemblyId?: number
  ) => Promise<CalculationsDTO[] | void>;

  fetchDifference:(  adjustedMooringDateTime: string,
    adjustedDepartureDateTime: string,
    doorId: number,
    berthId: number,
    assemblyId?: number)=> Promise<CalculationsDTO[] | void>;
}

const useCalculateRequest = (): UseCalculateRequestProps => {
  // API hooks for requests
  const { request: calculationRequest } = useApi<CalculationsDTO[]>();
  // const [doorSlopeData, setDoorSlopeData] = useState<CalculationsDTO[]>([]);

  const fetchSlopData = useCallback(
    async (
      adjustedMooringDateTime: string,
      adjustedDepartureDateTime: string,
      doorId: number,
      berthId: number,
      assemblyId?: number
    ): Promise<CalculationsDTO[] | undefined> => {
      try {
        const apiUrlGetSlope = assemblyId
          ? `/Calculations/slopes/assemblyId/${assemblyId}/mooringDateTime/${adjustedMooringDateTime}/departureDateTime/${adjustedDepartureDateTime}/doorId/${doorId}/berthId/${berthId}`
          : `/Calculations/slopes/mooringDateTime/${adjustedMooringDateTime}/departureDateTime/${adjustedDepartureDateTime}/doorId/${doorId}/berthId/${berthId}`;
        const response = await calculationRequest(apiUrlGetSlope, "GET");

        if (!Array.isArray(response)) {
          console.error("Unexpected API response format:", response);
          return;
        }

        return response.map((assembly: CalculationsDTO) => ({
          ...assembly,
          slopeCalculations: Array.isArray(assembly.slopeCalculations)
            ? assembly.slopeCalculations.map((slopeItem: SlopeCalculation) => ({
                ...slopeItem,
                slopeMultiples: [
                  (Math.abs(slopeItem.slope) + 1) * 10000,
                  (Math.abs(slopeItem.slope) + 1) * -10000,
                ],
              }))
            : [],
        }));
      } catch (error) {
        console.error("Error fetching slopes:", error);
      }
    },
    [calculationRequest]
  );

  
  const fetchDifference = useCallback(
    async (
      adjustedMooringDateTime: string,
      adjustedDepartureDateTime: string,
      doorId: number,
      berthId: number,
    ): Promise<CalculationsDTO[] | undefined> => {
      try {
        const apiUrlGetSlope = `/Calculations/differences/mooringDateTime/${adjustedMooringDateTime}/departureDateTime/${adjustedDepartureDateTime}/doorId/${doorId}/berthId/${berthId}`;
          const response = await calculationRequest(apiUrlGetSlope, "GET");

        if (!Array.isArray(response)) {
          console.error("Unexpected API response format:", response);
          return;
        }

        return response;
      } catch (error) {
        console.error("Error fetching slopes:", error);
      }
    },
    [calculationRequest]
  );
  return {
    // doorSlopeData,
    fetchSlopData,
    fetchDifference,
  };
};

export default useCalculateRequest;

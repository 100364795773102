import { useState, useEffect } from "react";
import useImage from "use-image";

// Custom hook for image preloading
const usePreloadImage = (src: string) => {
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImage(img);
    };

    // Clean up when component unmounts
    return () => {
      setImage(undefined);
    };
  }, [src]);

  return image;
};

const BackgroundComponent = () => {
  const userPortId =
    sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
  const [backgroundImageSrc, setBackgroundImageSrc] = useState<string>("");

  useEffect(() => {
    const cachedImage = localStorage.getItem(
      `backgroundImageSrc-${userPortId}`
    );
    if (cachedImage) {
      setBackgroundImageSrc(cachedImage);
    } else {
      switch (userPortId) {
        case "1":
          setBackgroundImageSrc("/images/InUse/PortCharlottetown-fullhd.jpg");
          break;
        case "2":
          setBackgroundImageSrc("/images/InUse/PortSaintJohn-fullhd3.png");
          break;
        case "3":
          setBackgroundImageSrc("/images/InUse/PortCharlottetown-fullhd.jpg");
          break;
        case null:
          setBackgroundImageSrc(
            "/images/InUse/PortCharlottetown-fullhd-null.jpg"
          );
          break;
        case undefined:
          setBackgroundImageSrc(
            "/images/InUse/PortCharlottetown-fullhd-undefined.jpg"
          );
          break;
        default:
          setBackgroundImageSrc(
            "/images/InUse/PortCharlottetown-fullhd-default.jpg"
          );
          break;
      }
    }
  }, [userPortId]);

  // Preload the image in the background
  const backgroundImage = usePreloadImage(backgroundImageSrc);

  // Store the image source and dimensions in localStorage
  useEffect(() => {
    if (backgroundImage?.width && backgroundImage?.height) {
      localStorage.setItem(
        "backgroundImageWidth",
        backgroundImage.width.toString()
      );
      localStorage.setItem(
        "backgroundImageHeight",
        backgroundImage.height.toString()
      );
      localStorage.setItem(
        `backgroundImageSrc-${userPortId}`,
        backgroundImageSrc
      );
    }
  }, [backgroundImage, backgroundImageSrc, userPortId]);

  return backgroundImage;
};

export default BackgroundComponent;

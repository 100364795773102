import React, { useState } from "react";
import "./Switch.css";

interface ToggleSwitchProps {
  label: string;
  onChange: (isChecked: boolean) => void; // Add onChange prop
}

const Switch = ({ label, onChange }: ToggleSwitchProps) => {
  const [isChecked, setIsChecked] = useState(false); // Add state

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    onChange(checked); // Call the onChange callback
  };

  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        className="checkbox"
        name={label}
        id={label}
        checked={isChecked} // Bind checked state
        onChange={handleCheckboxChange} // Handle change event
      />
      <label className="label" htmlFor={label}>
        <span className="inner" />
        <span className="switch" />
      </label>
    </div>
  );
};

export default Switch;

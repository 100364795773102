import React, { useCallback, useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  FilterValue,
  TablePropGetter,
  TableProps,
  TableBodyPropGetter,
  TableBodyProps,
  HeaderGroup,
  Row,
} from "react-table";
import { Columns } from "../Operations/CustomViews/Columns";
import BookingGlobalFilter from "./BookingGlobalFilter";
import { EnrichedBooking } from "Models/VesselDoorDTO";
import { FaSort } from "react-icons/fa6";
import moment from "moment";
import { BookingData } from "Models/Booking";
import CustomLoader from "Components/Loader/CustomLoader ";
import { BookingTableColumn } from "./BookingTableColumn";

interface BookingTableBodyProps {
  loading: boolean;
  bookings: BookingData[];
  hideCancelled: boolean;
  handleSelectSingleBooking: (booking: BookingData) => void;
  navigate: (path: string) => void;
  setSelectedDate: (date: string) => void;
  setGlobalFilter: (filterValue: FilterValue) => void;
  getTableProps: (
    propGetter?: TablePropGetter<BookingData> | undefined
  ) => TableProps;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<BookingData> | undefined
  ) => TableBodyProps;
  headerGroups: HeaderGroup<BookingData>[];
  prepareRow: (row: Row<BookingData>) => void;
  rows: Row<BookingData>[];
}

function BookingTableBody({
  loading,
  bookings,
  hideCancelled,
  handleSelectSingleBooking,
  navigate,
  setSelectedDate,
  setGlobalFilter,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  rows,
}: BookingTableBodyProps) {

  const getRowColor2 = useCallback(
    (date: string) => {
      if (!bookings) return "bg-white hover:bg-gray-100"; // Default to white with hover

      const dateOnly = moment(date).format("YYYY-MM-DD");
      const count = bookings.filter(
        (b) => moment(b.mooringDateTime).format("YYYY-MM-DD") === dateOnly
      ).length;

      switch (count) {
        case 1:
          return "hover:bg-gray-100"; // Ensures hover is visible
        case 2:
          return "bg-green-100 hover:bg-green-200"; // Slightly darker on hover
        case 3:
          return "bg-yellow-100 hover:bg-yellow-200"; // Slightly darker on hover
        default:
          return "bg-red-100 hover:bg-red-200"; // Slightly darker on hover
      }
    },
    [bookings]
  );

  const getRowColor = useCallback(
    (date: string) => {
      if (!date) return "bg-white hover:bg-gray-100"; // Default fallback

      const dayOfWeek = moment(date).day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

      // Assign colors based on the day of the week
      const dayColors = [
        "bg-red-300 hover:bg-red-400", // Sunday
        "bg-blue-300 hover:bg-blue-400", // Monday
        "bg-green-300 hover:bg-green-400", // Tuesday
        "bg-yellow-300 hover:bg-yellow-400", // Wednesday
        "bg-purple-300 hover:bg-purple-400", // Thursday
        "bg-pink-300 hover:bg-pink-400", // Friday
        "bg-gray-300 hover:bg-gray-400", // Saturday
      ];

      return dayColors[dayOfWeek];
    },
    [bookings]
  );

  return (
    <>
      <div className="flex-1 overflow-y-auto">
        <table className=" table-fixed min-w-full" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => {
              const { key: headerGroupKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr {...headerGroupProps} key={headerGroupKey}>
                  {headerGroup.headers.map((column) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        className={`px-6 py-3 th-equal-width ${
                          index === 0 ? "th-big" : ""
                        }`}
                        key={columnKey}
                        {...columnProps}
                      >
                        <div className="flex items-center justify-start space-x-1">
                          {column.render("Header")}
                          {headerGroup.headers[
                            headerGroup.headers.length - 1
                          ] !== column && (
                            <span style={{ }}>
                              <FaSort />
                            </span>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td
                  colSpan={headerGroups[0]?.headers.length}
                  className="text-center"
                >
                  <div className="flex justify-center items-center h-64">
                    <CustomLoader />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                const { key, ...rowProps } = row.getRowProps();
                const bookingStatus = row.original.status;
                if (hideCancelled && bookingStatus == "CANCELLED") {
                  return null;
                }
                return (
                  <tr
                    key={row.id}
                    {...rowProps}
                    className={` hover:bg-gray-200 cursor-pointer ${getRowColor(
                      row.values.mooringDateTime
                    )}`}
                    onClick={() => handleSelectSingleBooking(row.original)}
                  >
                    {row.cells.map((cell, index) => {
                      const { key, ...cellProps } = cell.getCellProps();
                      const isLastCell = index === row.cells.length - 1; // Check if it's the last cell

                      return (
                        <td
                          key={key}
                          className="px-4 py-2"
                          {...cellProps}
                          onClick={(e) => isLastCell && e.stopPropagation()} // Prevent row click for last cell
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}

export default BookingTableBody;

import Konva from "konva";
import { Box } from "konva/lib/shapes/Transformer";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { Line, Transformer, Group, Text, Rect } from "react-konva";

export const Ruler = () => {
  const userPortId = parseInt(
    sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId") || "1"
  );
  const groupRef = useRef<Konva.Transformer>(null)
  const transformerRef = useRef<Konva.Transformer>(null);
  const [selectedGroup, setSelectedGroup] = useState<Konva.Transformer | null>(null);

  const handleSelect = () => {
    const node = groupRef.current;
    if (selectedGroup === node) {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(node);
      if (node) {
        node.getLayer()?.batchDraw();
      }
    }
  };

  useEffect(() => {
    if (transformerRef.current && selectedGroup) {
      transformerRef.current.nodes([selectedGroup]);
      transformerRef.current.getLayer()?.batchDraw();
    }
  }, [selectedGroup]);

  const boundBoxFunc = (oldBox: Box, newBox: Box) => {
    return {
      ...oldBox,
      rotation: newBox.rotation, // Allow rotation
      width: oldBox.width, // Prevent resizing
      height: oldBox.height, // Prevent resizing
    };
  };

  const handleDragEndGroup = () => {
    setSelectedGroup(null);
  };

  const handleTransformEnd = () => {
    setSelectedGroup(null);
  };

  //   const handleDeselect = (e) => {
  //     const clickedOnEmpty = e.target === e.target.getStage();
  //     if (clickedOnEmpty) {
  //       setSelectedGroup(null);
  //     }
  //   };

  const getDimensions = useMemo(() => {
    if (userPortId === 1 || userPortId === 3) {
      return {
        thirtyMeter: 67,
        endTickHeight: 7,
        fiveMeterTickHeight: 5.5,
        oneMeterTickHeight: 3,
        oneMeterTickXDistance: 67 / 30,
        startX: 1014,
        startY: 742,
      };
    } else {
      return {
        thirtyMeter: 67,
        endTickHeight: 7,
        fiveMeterTickHeight: 5.5,
        oneMeterTickHeight: 3,
        oneMeterTickXDistance: 67 / 30,
        startX: 1545,
        startY: 530,
      };
    }
  }, [userPortId]);

  const {
    thirtyMeter,
    endTickHeight,
    fiveMeterTickHeight,
    oneMeterTickHeight,
    oneMeterTickXDistance,
    startX,
    startY,
  } = getDimensions;

  const generateRuler = (startX: number, startY: number) => {
    // Generate 1-meter tick marks
    const oneMeterTicks = [];
    for (let i = 1; i < 30; i++) {
      oneMeterTicks.push({
        points: [
          startX + i * oneMeterTickXDistance,
          startY + oneMeterTickHeight,
          startX + i * oneMeterTickXDistance,
          startY - oneMeterTickHeight,
        ],
      });
    }
    const linesData = [
      // 30 meter line
      { points: [startX, startY, startX + thirtyMeter, startY] },
      // 0 meter tick
      {
        points: [
          startX,
          startY + endTickHeight,
          startX,
          startY - endTickHeight,
        ],
      },
      // 5 meter tick
      {
        points: [
          startX + thirtyMeter / 6,
          startY + fiveMeterTickHeight,
          startX + thirtyMeter / 6,
          startY - fiveMeterTickHeight,
        ],
      },
      // 10 meter tick
      {
        points: [
          startX + thirtyMeter / 3,
          startY + endTickHeight,
          startX + thirtyMeter / 3,
          startY - endTickHeight,
        ],
      },
      // 15 meter tick
      {
        points: [
          startX + thirtyMeter / 2,
          startY + fiveMeterTickHeight,
          startX + thirtyMeter / 2,
          startY - fiveMeterTickHeight,
        ],
      },
      // 20 meter tick
      {
        points: [
          startX + (thirtyMeter * 2) / 3,
          startY + endTickHeight,
          startX + (thirtyMeter * 2) / 3,
          startY - endTickHeight,
        ],
      },
      // 25 meter tick
      {
        points: [
          startX + (thirtyMeter * 5) / 6,
          startY + fiveMeterTickHeight,
          startX + (thirtyMeter * 5) / 6,
          startY - fiveMeterTickHeight,
        ],
      },
      // 30 meter tick
      {
        points: [
          startX + thirtyMeter,
          startY + endTickHeight,
          startX + thirtyMeter,
          startY - endTickHeight,
        ],
      },
    ];
    return [...linesData, ...oneMeterTicks].map((line, index) => (
      <Line key={index} points={line.points} stroke="black" strokeWidth={1} />
    ));
  };

  const rulerLabels = (startX: number, startY: number) => {
    return (
      <>
        <Text
          key="label-0"
          x={startX - 2}
          y={startY - 13}
          text="0"
          fontSize={7}
          fill="black"
        />
        {[10, 20, 30].map((label) => (
          <Text
            key={`label-${label}`}
            x={startX + (thirtyMeter / 30) * label - 5}
            y={startY - 13}
            text={`${label}`}
            fontSize={7}
            fill="black"
          />
        ))}
      </>
    );
  };

  return (
    <>
      <Group
        ref={groupRef}
        draggable
        onDragEnd={handleDragEndGroup}
      //onClick={handleSelect}
      >
        <Rect
          x={startX}
          y={startY - 10}
          width={thirtyMeter}
          height={20}
          fill="transparent"
          onClick={handleSelect}
        />
        {generateRuler(startX, startY)}
        {rulerLabels(startX, startY)}
      </Group>

      {selectedGroup && (
        <Transformer
          ref={transformerRef}
          nodes={[groupRef.current]}
          rotateEnabled={true}
          boundBoxFunc={boundBoxFunc}
          onTransformEnd={handleTransformEnd}
          enabledAnchors={[]}
        />
      )}
    </>
  );
};

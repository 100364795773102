import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from "react";
//import axios from 'axios';
import "bootstrap/dist/css/bootstrap.css";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
//import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
//import UserRoleContext from '../../Contexts/UserRoleContext';
//import Constants from '../../Constants';
//import moment from 'moment';
import "moment-timezone";
import "react-toastify/dist/ReactToastify.css";
//import { useTideContext } from "../../Contexts/TideContext";
import html2canvas from "html2canvas";
import { TideDTO } from "Models/TideDTO";

interface ReportsTideProps {
  handleCapturedTideGraph: (imgData: string) => void;
  tide: TideDTO[];
}

const ReportsTide = forwardRef(
  ({ handleCapturedTideGraph, tide }: ReportsTideProps, ref) => {
    const graphRef = useRef<HTMLDivElement>(null);

    const captureGraph = () => {
      if (graphRef.current) {
        html2canvas(graphRef.current).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          handleCapturedTideGraph(imgData);
        });
      }
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        captureGraph();
      }, 3000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <div className="reportTideFirstContainer" ref={graphRef}>
        <div style={{ padding: "10px" }}>
          {/* <label style={{fontSize: "40px"}}>Tide Chart</label> */}
        </div>
        <ResponsiveContainer>
          <LineChart data={tide} width={400} height={225}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="tideDateTime"
              tickFormatter={(tick) =>
                new Date(tick).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              }
              tick={{ style: { fontWeight: "bold", fontSize: "40px" } }}
              interval={20}
            />
            <YAxis
              dataKey="height"
              tick={{ style: { fontWeight: "bold", fontSize: "40px" } }}
            >
              {" "}
            </YAxis>
            <Line
              type="monotone"
              dataKey="height"
              stroke="#22418a"
              dot={false}
              activeDot={{ r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
);
export default ReportsTide;

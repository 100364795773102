import { FaMap } from "react-icons/fa";
import "bootstrap-icons/font/bootstrap-icons.css";
import moment from "moment";
import { Column } from "react-table";
import { BookingData } from "Models/Booking";

const formattedDateTime = (dateTimeString: string) => {
  return new Date(dateTimeString).toLocaleString(undefined, {
    dateStyle: "long",
    timeStyle: "short",
  });
};

interface ColumnsProps {
  navigate: (path: string) => void;
  setDate: (date: string) => void;
}

export const BookingTableColumn = ({ navigate, setDate }: ColumnsProps) => {
  const handleViewOperation = (booking: BookingData) => {
    const formattedDate = moment(booking.mooringDateTime).format("YYYY-MM-DD");
    setDate(formattedDate);
    navigate("/operations");
  };

  const Columns: Column<BookingData>[] = [
    {
      Header: "Vessel Name",
      accessor: "vesselName",
    },
    {
      Header: "Mooring Date and Time",
      accessor: "mooringDateTime",
      Cell: ({ cell: { value } }) => formattedDateTime(value),
    },
    {
      Header: "Departure Date and Time",
      accessor: "departureDateTime",
      Cell: ({ cell: { value } }) => formattedDateTime(value),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell: { value } }) => (
        <span
          className={`badge badge-pill badge-${
            value === "CONFIRMED"
              ? "success"
              : value === "TENTATIVE"
              ? "warning"
              : "danger"
          }`}
        >
          {value}
        </span>
      ),
    },
    {
      Header: "Booking note",
      accessor: "specialNote",
    },
    {
      Header: "Berth Name",
      accessor: "berthName",
    },
    {
      Header: "Passengers",
      accessor: "passengers",
    },
    {
      Header: "Crew",
      accessor: "crew",
    },
    {
      Header: "Vessel Note",
      accessor: "vesselNote",
    },
    {
      Header: "View in Operations",
      Cell: ({ row }) => (
        <button
          // className="btn viewInOperations"
          className="btn navOperationsMap"
          onClick={() => handleViewOperation(row.original)}
        >
          <FaMap className="operationsMapIcon" />
        </button>
      ),
    },
  ];
  return Columns;
};

// dataFetchers.ts
import { BookingResource } from "Models/Booking";
import {
  MooringLineBookingResourceDTO,
  MooringLineDTO,
} from "Models/MooringLine";
import { TideDTO } from "Models/TideDTO";
import { EnrichedBooking } from "Models/VesselDoorDTO";
import moment from "moment";
import { Refresh } from "Pages/Operation/Operation";
import { BookingsDetailsData } from "Services/BookingRequests";

export const fetchDataFromAPI = async (
  locationState: any, // Adjust types as needed
  selectedDate: string | null,
  fetchAllBookings: (date: string) => Promise<BookingsDetailsData>, // Adjust types
  fetchAllTides: (date: string) => Promise<TideDTO[] | void>, // Adjust types
  fetchMooringLines: () => Promise<MooringLineDTO[] | undefined>, // Adjust types
  isInitialRender: React.MutableRefObject<boolean>,
  setLoading: (loading: boolean) => void,
  setBookingDetails: (details: any) => void, // Adjust types
  setSelectedDate: (date: string) => void,
  setTides: (tides: any[]) => void, // Adjust types
  setMooringLines: (lines: any[]) => void, // Adjust types
  setRefresh: (refresh: Refresh) => void,
  isSameDayInUserTimezone: (date1: string, date2: string) => boolean,
  setLocationState: (state: any) => void // Adjust types
) => {
  setLoading(true);
  setBookingDetails(null);

  try {
    const useDate = moment(selectedDate).format("YYYY-MM-DD");
    setSelectedDate(useDate);

    const result = await fetchAllBookings(useDate);
    const tide = (await fetchAllTides(moment(useDate).format())) || [];
    setTides(tide);

    const yesterday = moment(useDate).subtract(1, "days").format("YYYY-MM-DD");
    const yesterdayResult = await fetchAllBookings(yesterday);

    if (yesterdayResult?.bookings) {
      const localDate = moment(selectedDate).format();
      const updatedResult = yesterdayResult.bookings.filter((booking) =>
        isSameDayInUserTimezone(booking.departureDateTime, localDate)
      );
      // const updatedTodaysResult = result.bookings.filter((booking) =>
      //   isSameDayInUserTimezone(booking.mooringDateTime, localDate)
      // );
      result.bookings = Array.from(
        new Map(
          [...result.bookings, ...updatedResult].map((b) => [b.id, b])
        ).values()
      );
    }

    setBookingDetails(result);

    const mooringLines = await fetchMooringLines();
    if (mooringLines) {
      setMooringLines(mooringLines);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
    setRefresh({
      refresh: false,
      showLoading: false,
    });
    setLocationState(null);
  }
};

export const fetchAllResourcesData = async (
  filteredBookings: EnrichedBooking[], // Adjust types
  fetchBookingResources: (
    bookingId: number
  ) => Promise<BookingResource[] | void>, // Adjust types
  fetchMooringLineBookingResource: (
    bookingId: number
  ) => Promise<MooringLineBookingResourceDTO[] | undefined>, // Adjust types
  setBookingResources: (resources: BookingResource[]) => void, // Adjust types
  setMooringLineBookingResources: (
    resources: Record<number, MooringLineBookingResourceDTO[]>
  ) => void,
  // Adjust types
  setVesselOrientations: (
    orientations: Record<number, "PORT" | "STARBOARD">
  ) => void,
  setImageUrls: (images: Record<number, string>) => void
) => {
  const initialOrientations: Record<number, "PORT" | "STARBOARD"> = {};
  const initialImages: Record<number, string> = {};
  const resourcesPromises = filteredBookings.map(async (booking) => {
    initialOrientations[booking.id] = booking.vesselOrientation as
      | "PORT"
      | "STARBOARD";
    initialImages[booking.id] =
      booking.vessel.images.find(
        (img) => img.orientation === booking.vesselOrientation
      )?.imagePath || "";

    try {
      const res = await fetchBookingResources(booking.id);
      const mooringLineRes = await fetchMooringLineBookingResource(booking.id);
      return { res: res || [], mooringLineRes: mooringLineRes || [] };
    } catch (error) {
      console.error(
        `Error fetching resources for booking ${booking.id}:`,
        error
      );
      return { res: [], mooringLineRes: [] };
    }
  });

  try {
    const results = await Promise.all(resourcesPromises);
    const resources = results.flatMap((result) => result.res);
    const mooringLineResourcesGrouped = results.reduce<Record<number, MooringLineBookingResourceDTO[]>>(
      (acc, result) => {
        if (result.mooringLineRes.length > 0) {
          const bookingId = result.mooringLineRes[0]?.bookingId; // Extract bookingId from first item
          if (bookingId !== undefined) {
            acc[bookingId] = result.mooringLineRes;
          }
        }
        return acc;
      },
      {}
    );


    setBookingResources(resources);
    setMooringLineBookingResources(mooringLineResourcesGrouped);
    setVesselOrientations(initialOrientations);
    setImageUrls(initialImages);
  } catch (error) {
    console.error("Error fetching all resources:", error);
  }
};

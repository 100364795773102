import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
} from "@react-pdf/renderer";
import { styles } from "./StyleSheet";

interface ReportsDayReportPDFProps {
  picture: string;
  graphs: string[];
  selectedDate: string;
  tide: string[];
  tideTable: string[];
  hvvwGraphs?: string[];
}

const ReportsDayReportPDF = ({
  picture,
  graphs,
  selectedDate,
  tide,
  tideTable,
  hvvwGraphs,
}: ReportsDayReportPDFProps) => {
  const [todayDate, setTodayDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setTodayDate(formattedDate);
  }, []);

  Font.register({
    family: "Montserrat",
    fonts: [
      { src: "/fonts/Montserrat-Regular.ttf", fontWeight: "normal" },
      { src: "/fonts/Montserrat-Bold.ttf", fontWeight: "bold" },
      { src: "/fonts/Montserrat-Italic.ttf", fontStyle: "italic" },
    ],
  });

  return (
    <Document>
      <Page size="LETTER" orientation="portrait" style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={styles.textTitle}>Operations for {selectedDate}</Text>
          <View style={styles.titleUnderline} />
        </View>
        <View style={styles.topRightContainer}>
          <Text style={styles.topRightDate}>Generated on: {todayDate}</Text>
        </View>
        <View style={styles.detailsTitlesContainer}>
          {/* <View style={styles.developedContainer}>
            <Text style={styles.legendTitle}>Developed on: </Text>
          </View> */}

          <View style={styles.developedContainer}>
            <Text style={styles.legendTitle}>Tide Data </Text>
          </View>

          <View style={styles.tideContainer}>
            <Text style={styles.legendTitle}>Tide Chart</Text>
          </View>

          <View style={styles.legendContainer}>
            <Text style={styles.legendTitle}>Chart Legend</Text>
          </View>
        </View>

        <View style={styles.detailsContainer}>
          {/* <View style={styles.developedContainer}>
            <Text style={styles.textSubTitle}>{todayDate}</Text>
          </View> */}

          {tide && tide.length > 0 ? (
            <>
              <View style={styles.tideContainer}>
                {tideTable.map((tideImage, index) => (
                  <Image key={index} style={styles.tide} src={tideImage} />
                ))}
              </View>
              <View style={styles.tideContainer}>
                {tide.map((tideImage, index) => (
                  <Image key={index} style={styles.tide} src={tideImage} />
                ))}
              </View>
            </>
          ) : (
            <View style={styles.tideContainer}>
              <Text style={styles.textSubTitle}>No tide graphs available</Text>
            </View>
          )}

          <View style={styles.legendContainer}>
            <View style={styles.legendItem}>
              <View style={styles.safeLine} />
              <Text style={styles.legend}>Safe Gangway Slope</Text>
            </View>
            <View style={styles.legendItem}>
              <View style={styles.cautionLine} />
              <Text style={styles.legend}>Caution Gangway Slope</Text>
            </View>
            <View style={styles.legendItem}>
              <View style={styles.unsafeLine} />
              <Text style={styles.legend}>Unsafe Gangway Slope</Text>
            </View>
            <View style={styles.legendItem}>
              <View style={styles.percentSlopeLine} />
              <Text style={styles.legend}>% Gangway Slope</Text>
            </View>
          </View>
        </View>

        <View style={styles.titleUnderline} />

        <View style={styles.container}>
          <Text style={styles.legendTitle}>% Gangway Slope Graph</Text>
        </View>

        {graphs && graphs.length > 0 ? (
          <View>
            {graphs.map((graph, index) => (
              <View key={index} style={styles.graphContainer}>
                <Image style={styles.hvvwGraphs} src={graph} />
              </View>
            ))}
          </View>
        ) : (
          <View style={styles.container}>
            <Text style={styles.textSubTitle}>No gangway graphs available</Text>
          </View>
        )}
      </Page>

      {hvvwGraphs && hvvwGraphs.length > 0 && (
        <>
          <Page size="LETTER" orientation="portrait" style={styles.page}>
            <View style={styles.topRightContainer}>
              <Text style={styles.topRightDate}>Generated on: {todayDate}</Text>
            </View>
            <View style={styles.topRightContainer}>
              <Text style={styles.topRightDate}>Generated on: {todayDate}</Text>
            </View>
            <View style={styles.container}>
              <View style={styles.titleHvvwUnderline} />
              <Text style={styles.operationsMapTitle}>HVVW Tide Graph</Text>
            </View>
            <View>
              {hvvwGraphs.map((graph, index) => (
                <View key={index} style={styles.hvvwGraphsContainer}>
                  <Image style={styles.hvvwGraphs} src={graph} />
                </View>
              ))}
            </View>
            {/* <View style={styles.container}>
              <Text style={styles.textSubSubTitle}>
                See Next Page for Operations Map
              </Text>
            </View> */}
            <View style={styles.titleUnderline} />
          </Page>
        </>
      )}
      <Page size="LETTER" orientation="landscape" style={styles.picturePage}>
        <View style={styles.topRightContainer}>
          <Text style={styles.topRightDate}>Generated on: {todayDate}</Text>
        </View>
        <View style={styles.container}>
          <View style={styles.titleUnderline} />
          <Text style={styles.operationsMapTitle}>Operations Map</Text>
        </View>
        <View style={styles.container}>
          {picture ? (
            <Image style={styles.image} src={picture} />
          ) : (
            <Text style={styles.textSubTitle}>No image available</Text>
          )}
        </View>
        <View style={styles.container}>
          <View style={styles.titleUnderline} />
        </View>
      </Page>
    </Document>
  );
};

export default ReportsDayReportPDF;

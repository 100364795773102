import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceArea,
  ReferenceLine,
  ReferenceDot,
  Legend,
} from "recharts";
import { EnrichedBooking } from "Models/VesselDoorDTO";
import { TideDTO } from "Models/TideDTO";
import Colors from "Theme";
import CustomLegend from "Components/Operations/Tides/CustomLegendHvvw";
import { DepartureDetails, ReferenceAreaItem } from "./HwwvesselTideReportGraph";

interface HwwVesselDepatureTideGraphProps {
  departureTideData: TideDTO[];
  topTwoDepartureTides: TideDTO[];
  bookings: EnrichedBooking;
  channelDepth: number;
  departureDetails: DepartureDetails;
  height: number;
  graphRef: React.RefObject<HTMLDivElement | null>;
}

function NonHwwVesselDepatureTideGraph({
  departureDetails,
  departureTideData,
  topTwoDepartureTides,
  bookings,
  channelDepth,
  height,
  graphRef,
}: HwwVesselDepatureTideGraphProps) {
  const referenceAreas: ReferenceAreaItem[] = [];
  const chartRef = useRef<any>(null); // Use ref to access the ComposedChart instance
  const [lengthOfStay, setLengthOfStay] = useState<string>("");
  if (departureTideData.length > 0 && topTwoDepartureTides.length > 0) {
    const calculateMinTideHt = (percentageUKC: boolean = false): number => {
      let result = 0;

      if (percentageUKC) {
        // 10% UKC calculation
        result = (bookings.vessel?.maxDraft ?? 0) * 0.1 - channelDepth;
      } else {
        // 2m UKC calculation
        result = (bookings.vessel?.maxDraft ?? 0) + 2 - channelDepth;
      }
      // Round the result to 2 decimal places
      result = Math.round(result * 100) / 100;

      return result;
    };
    //   // Normalize mooring tide data timestamps to formatted strings
    let normalizedMooringTideData = departureTideData.map((item) => ({
      ...item,
      tideDateTime: moment(item.tideDateTime).format(),
    }));

    const expandedTideData = () => {
      const intersections: {
        height: number;
        tideDateTime: string;
      }[] = [];
      const referenceYValue = calculateMinTideHt(); // 2m UKC line

      for (let i = 0; i < normalizedMooringTideData.length - 1; i++) {
        const currentPoint = normalizedMooringTideData[i];
        const nextPoint = normalizedMooringTideData[i + 1];

        if (
          (currentPoint.height <= referenceYValue &&
            nextPoint.height >= referenceYValue) ||
          (currentPoint.height >= referenceYValue &&
            nextPoint.height <= referenceYValue)
        ) {
          intersections.push({
            height: currentPoint.height,
            tideDateTime: currentPoint.tideDateTime,
          }); // Store the x value of the first point of the intersection.
        }
      }
      return intersections;
    };

    if (expandedTideData().length > 0) {
      referenceAreas.push({
        x1: normalizedMooringTideData[0].tideDateTime,
        x2: expandedTideData()[0].tideDateTime,
        fill: Colors.safeZone, // red
      });
      for (let i = 0; i < expandedTideData().length; i += 2) {
        if (i + 1 < expandedTideData().length) {
          referenceAreas.push({
            x1: expandedTideData()[i].tideDateTime,
            x2: expandedTideData()[i + 1].tideDateTime,
            fill: Colors.redZone, //red
          });

          if (i + 2 < expandedTideData().length) {
            referenceAreas.push({
              x1: expandedTideData()[i + 1].tideDateTime,
              x2: expandedTideData()[i + 2].tideDateTime,
              fill: Colors.safeZone, // red
            });
          } else {
            referenceAreas.push({
              x1: expandedTideData()[i + 1].tideDateTime,
              x2: normalizedMooringTideData[
                normalizedMooringTideData.length - 1
              ].tideDateTime,
              fill: Colors.safeZone, // red
            });
          }
        }
      }
    } else {
      referenceAreas.push({
        x1: normalizedMooringTideData[0].tideDateTime,
        x2: normalizedMooringTideData[normalizedMooringTideData.length - 1]
          .tideDateTime,
        fill: Colors.safeZone, // red
      });
    }
    // get the hightst height
    const highestHeight = Math.max(
      ...normalizedMooringTideData.map((item) => item.height)
    );

    return (
      <div className="hvvwTideGrapContainer" ref={graphRef}>
        <div className="hvvwTideGraphTideTitle">
          {" "}
          DEPARTURE DATE -{" "}
          {moment(bookings.departureDateTime).format("DD/MM/YYYY")}
        </div>
        <>
          <div className="hvvw-title-section">
            <span className="">{bookings.vessel.name.toUpperCase()}</span>
          </div>
        </>
        <div className="hvvwTideGraphModal">
          <ResponsiveContainer>
            <ComposedChart
              ref={chartRef}
              width={1200}
              height={300}
              data={normalizedMooringTideData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              {/* <CartesianGrid strokeDasharray="1 1" /> */}
              <CartesianGrid
                strokeDasharray="3 3"
                // vertical={true}
                // horizontal={false}
              />
              <XAxis
                dataKey="tideDateTime"
                tickFormatter={(tick) => moment(tick).format("HH:mm")}
                ticks={normalizedMooringTideData
                  .map((entry) => entry.tideDateTime)
                  .filter((time) => moment(time).minute() === 0)} // Only show full hours
                tick={{ stroke: "grey", strokeWidth: 1, fontSize: 12 }}
                tickLine={false} // Remove X-axis tick lines
                axisLine={false} // Remove X-axis line
                label={{
                  value: "Time",
                  position: "insideBottom",
                  dy: 5, // Moves label downward
                }}
              />

              <YAxis
                domain={[0, (dataMax: number) => Math.ceil(dataMax)]} // Round max value up and add 1
                dataKey="height"
                tickLine={false} // Remove Y-axis tick lines
                axisLine={true} // Remove Y-axis line
                tickCount={
                  Math.ceil(
                    Math.max(...normalizedMooringTideData.map((d) => d.height))
                  ) + 2
                } // Ensures proper tick count
                interval={0} // Ensures every tick is displayed
                allowDecimals={false} // Ensures only whole numbers
                label={{
                  value: "Tides (m)",
                  angle: -90,
                }}
              />
              {/* <Legend content={<CustomLegend />} /> */}
              {/* Render reference areas for background segments */}
              {referenceAreas.map((area, index) => (
                <ReferenceArea
                  key={`area-${index}`}
                  x1={area.x1}
                  x2={area.x2}
                  fill={area.fill}
                  y1={0}
                />
              ))}

              {/* Render vertical reference lines */}
              {expandedTideData() &&
                expandedTideData().length > 1 &&
                expandedTideData().map((item, index) => {
                  const randomColor = `#${Math.floor(
                    Math.random() * 16777215
                  ).toString(16)}`; // Generate random color
                  return (
                    <React.Fragment key={`line-${index}`}>
                      <ReferenceLine
                        // key={`line-${index}`}
                        // x={item.tideDateTime}
                        //stroke={Colors.navigationWindow}
                        strokeWidth={2}
                        ifOverflow="visible"
                        segment={[
                          { x: item.tideDateTime, y: 0 },
                          { x: item.tideDateTime, y: highestHeight },
                        ]}
                        label={{
                          value: `${moment(item.tideDateTime).format("HH:mm")}`,
                          position: "top",
                          fill: "black",
                          fontSize: 12,
                        }}
                      />
                      <ReferenceDot
                        x={item.tideDateTime}
                        y={highestHeight}
                        r={5}
                        fill={randomColor}
                        ifOverflow="visible"
                      />
                    </React.Fragment>
                  );
                })}

              {/* { UKC LINE on grpah} */}
              {/* <ReferenceLine
                y={calculateMinTideHt()}
                stroke="red"
                strokeWidth={3}
                label={{
                  value: "2m UKC",
                  position: "top",
                  fill: "black",
                  fontSize: 12,
                  dy: -1,
                }}
              /> */}

              {/* {Vessel and pob lines on the graph} */}
              {departureDetails && (
                <React.Fragment>
                  {/* For the Vessel Depature // Vessel Line */}
                  <>
                    <ReferenceLine
                      stroke="green"
                      strokeWidth={3}
                      segment={[
                        {
                          x: departureDetails.vesselDepartureDate,
                          y: height,
                        },
                        {
                          x: departureDetails.vesselDepartureDate,
                          y: height + 1.0,
                        },
                      ]}
                      label={{
                        value: `${moment(
                          departureDetails.vesselDepartureDate
                        ).format("HH:mm")}`,
                        position: "top",
                        fill: "black",
                        fontSize: 12,
                      }}
                    />
                    <ReferenceLine
                      stroke="green"
                      strokeWidth={3}
                      segment={[
                        {
                          x: departureDetails.vesselArrivalDate,
                          y: height,
                        },
                        {
                          x: departureDetails.vesselDepartureDate,
                          y: height,
                        },
                      ]}
                    />
                  </>
                  {/* For the POB ARRIVAL */}
                  <>
                    <ReferenceLine
                      stroke="blue"
                      strokeWidth={3}
                      segment={[
                        { x: departureDetails.popArrivalDate, y: height },
                        { x: departureDetails.popDepartureDate, y: height },
                      ]}
                    />

                    <ReferenceLine
                      stroke="blue"
                      strokeWidth={3}
                      segment={[
                        { x: departureDetails.popArrivalDate, y: height },
                        {
                          x: departureDetails.popArrivalDate,
                          y: height + 1.0,
                        },
                      ]}
                      label={{
                        value: `POB: ${moment(
                          departureDetails.popArrivalDate
                        ).format("HH:mm")}`,
                        position: "top",
                        fill: "black",
                        fontSize: 12,
                        dy: -10, // Shift the label upward
                        dx: -15, // Shift the label to the right
                      }}
                    />
                  </>
                  <ReferenceDot
                    x={departureDetails.popArrivalDate}
                    y={height}
                    r={3}
                    fill={"yellow"}
                    ifOverflow="visible"
                  />
                  <ReferenceDot
                    x={departureDetails.vesselDepartureDate}
                    y={height}
                    r={3}
                    fill={"yellow"}
                    ifOverflow="visible"
                  />
                </React.Fragment>
              )}

              {/* <ReferenceArea y2={calculateMinTideHt()} fill={"red"} /> */}
              <Line
                type="monotone"
                dataKey="height"
                stroke={Colors.tideHeight}
                dot={false}
                strokeWidth={1}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default NonHwwVesselDepatureTideGraph;

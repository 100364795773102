import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";
import { ArrivalDetails, ReferenceAreaItem } from "./HwwvesselTideReportGraph";
import Draggable from "react-draggable";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceArea,
  ReferenceLine,
  ReferenceDot,
  Legend,
} from "recharts";
import { EnrichedBooking } from "Models/VesselDoorDTO";
import { TideDTO } from "Models/TideDTO";
import Colors from "Theme";
import CustomLegend from "Components/Operations/Tides/CustomLegendHvvw";

interface HwwVesselArrivalTideGraphProps {
  bookings: EnrichedBooking;
  mooringTideData: TideDTO[];
  topTwoMooringTides: TideDTO[];
  mooringDetails: ArrivalDetails;
  height: number;
  channelDepth: number;
  graphRef: React.RefObject<HTMLDivElement | null>;
}
function HwwVesselArrivalTideGraph({
  bookings,
  mooringTideData,
  topTwoMooringTides,
  mooringDetails,
  height,
  channelDepth,
  graphRef,
}: HwwVesselArrivalTideGraphProps) {
  const referenceAreas: ReferenceAreaItem[] = [];
  const chartRef = useRef<any>(null); // Use ref to access the ComposedChart instance
  // const graphRef = useRef<HTMLDivElement>(null);
  const [lengthOfStay, setLengthOfStay] = useState<string>("");
  // Check if both data arrays are populated
  if (mooringTideData.length > 0 && topTwoMooringTides.length > 0) {
    let normalizedMooringTideData = mooringTideData.map((item) => ({
      ...item,
      tideDateTime: moment(item.tideDateTime).format(),
    }));
    // Normalize mooring tide data timestamps to formatted strings
    normalizedMooringTideData = mooringTideData.map((item) => ({
      ...item,
      tideDateTime: moment(item.tideDateTime).format(),
    }));

    // Calculate expanded tide data by subtracting and adding time
    const expandedTideData = topTwoMooringTides.flatMap((item) => {
      const originalDateTime = moment(item.tideDateTime);
      const subtractTime = originalDateTime
        .clone()
        .subtract(3, "hours")
        .subtract(15, "minutes");
      const addTime = originalDateTime
        .clone()
        .add(1, "hours")
        .add(15, "minutes");

      /**
       * Finds the closest time in normalizedMooringTideData to the target time.
       * @param targetTime The time to find the closest match for.
       * @returns The formatted string of the closest time.
       */
      const findClosestTime = (targetTime: Moment): string => {
        const mooringTimes = normalizedMooringTideData.map((data) =>
          moment(data.tideDateTime)
        );

        if (targetTime.isBefore(mooringTimes[0])) {
          return mooringTimes[0].format();
        }
        if (targetTime.isAfter(mooringTimes[mooringTimes.length - 1])) {
          return mooringTimes[mooringTimes.length - 1].format();
        }

        let closestTime = targetTime;
        let minDifference = Infinity;

        mooringTimes.forEach((mooringTime) => {
          const difference = Math.abs(targetTime.diff(mooringTime));
          if (difference < minDifference) {
            minDifference = difference;
            closestTime = mooringTime;
          }
        });

        return closestTime.format();
      };

      return [
        {
          tideDateTime: findClosestTime(subtractTime),
          height: item.height,
        },
        {
          tideDateTime: findClosestTime(addTime),
          height: item.height,
        },
      ];
    });

    // Calculate reference areas for alternating red and green backgrounds
    referenceAreas.push({
      x1: normalizedMooringTideData[0].tideDateTime,
      x2: expandedTideData[0].tideDateTime,
      fill: Colors.redZone, // red
    });

    for (let i = 0; i < expandedTideData.length; i += 2) {
      if (i + 1 < expandedTideData.length) {
        referenceAreas.push({
          x1: expandedTideData[i].tideDateTime,
          x2: expandedTideData[i + 1].tideDateTime,
          fill: "rgba(102, 187, 106, 0.6)",
        });

        if (i + 2 < expandedTideData.length) {
          referenceAreas.push({
            x1: expandedTideData[i + 1].tideDateTime,
            x2: expandedTideData[i + 2].tideDateTime,
            fill: Colors.redZone, // red
          });
        } else {
          referenceAreas.push({
            x1: expandedTideData[i + 1].tideDateTime,
            x2: normalizedMooringTideData[normalizedMooringTideData.length - 1]
              .tideDateTime,
            fill: Colors.redZone, // red
          });
        }
      }
    }

    // get the hightst height
    const highestHeight = Math.max(
      ...expandedTideData.map((item) => item.height)
    );

    const calculateMinTideHt = (percentageUKC: boolean = false): number => {
      let result = 0;

      if (percentageUKC) {
        // 10% UKC calculation
        result = (bookings.vessel?.maxDraft ?? 0) * 0.1 - channelDepth;
      } else {
        // 2m UKC calculation
        result = (bookings.vessel?.maxDraft ?? 0) + 2 - channelDepth;
      }
      // Round the result to 2 decimal places
      result = Math.round(result * 100) / 100;

      return result;
    };

    return (
      <div className="hvvwTideGrapContainer" ref={graphRef}>
        <div className="hvvwTideGraphTideTitle">
          {" "}
          ARRIVAL DATE - {moment(bookings.mooringDateTime).format("DD/MM/YYYY")}
        </div>
        <>
          <div className="hvvw-title-section">
            <span className="">{bookings.vessel.name.toUpperCase()}</span>
          </div>
        </>
        <div className="hvvwTideGraphModal">
          <ResponsiveContainer>
            <ComposedChart
              ref={chartRef}
              width={1200}
              height={300}
              data={normalizedMooringTideData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              {/* <CartesianGrid strokeDasharray="1 1" /> */}
              <CartesianGrid
                strokeDasharray="3 3"
                // vertical={true}
                // horizontal={false}
              />
              <XAxis
                dataKey="tideDateTime"
                tickFormatter={(tick) => moment(tick).format("HH:mm")}
                ticks={normalizedMooringTideData
                  .map((entry) => entry.tideDateTime)
                  .filter((time) => moment(time).minute() === 0)} // Only show full hours
                tick={{ stroke: "grey", strokeWidth: 1, fontSize: 12 }}
                tickLine={false} // Remove X-axis tick lines
                axisLine={false} // Remove X-axis line
                label={{
                  value: "Time",
                  position: "insideBottom",
                  dy: 5, // Moves label downward
                }}
              />

              <YAxis
                domain={[0, (dataMax: number) => Math.ceil(dataMax)]} // Round max value up and add 1
                dataKey="height"
                tickLine={false} // Remove Y-axis tick lines
                axisLine={true} // Remove Y-axis line
                tickCount={
                  Math.ceil(
                    Math.max(...normalizedMooringTideData.map((d) => d.height))
                  ) + 2
                } // Ensures proper tick count
                interval={0} // Ensures every tick is displayed
                allowDecimals={false} // Ensures only whole numbers
                label={{
                  value: "Tides (m)",
                  angle: -90,
                }}
              />
              {/* <Legend content={<CustomLegend />} /> */}
              {/* Render reference areas for background segments */}
              {referenceAreas.map((area, index) => (
                <ReferenceArea
                  key={`area-${index}`}
                  x1={area.x1}
                  x2={area.x2}
                  fill={area.fill}
                  y1={0}
                />
              ))}

              {/* Render vertical reference lines */}
              {expandedTideData &&
                expandedTideData.length > 1 &&
                expandedTideData.map((item, index) => {
                  const randomColor = `#${Math.floor(
                    Math.random() * 16777215
                  ).toString(16)}`; // Generate random color
                  return (
                    <React.Fragment key={`line-${index}`}>
                      <ReferenceLine
                        // key={`line-${index}`}
                        // x={item.tideDateTime}
                        stroke={Colors.navigationWindow}
                        strokeWidth={2}
                        ifOverflow="visible"
                        segment={[
                          { x: item.tideDateTime, y: 0 },
                          { x: item.tideDateTime, y: highestHeight },
                        ]}
                        label={{
                          value: `${moment(item.tideDateTime).format("HH:mm")}`,
                          position: "top",
                          fill: "black",
                          fontSize: 12,
                        }}
                      />
                      <ReferenceDot
                        x={item.tideDateTime}
                        y={highestHeight}
                        r={5}
                        fill={randomColor}
                        ifOverflow="visible"
                      />
                    </React.Fragment>
                  );
                })}

              {/* Render the 2m UKC line and label */}
              {/* <ReferenceLine
                y={calculateMinTideHt()}
                stroke="red"
                strokeWidth={3}
                label={{
                  value: "2m UKC",
                  position: "top",
                  fill: "black",
                  fontSize: 12,
                  dy: -1,
                }}
              /> */}
              {mooringDetails && (
                <React.Fragment>
                  {/* For the POB ARRIVAL */}

                  <ReferenceLine
                    stroke="blue"
                    strokeWidth={3}
                    segment={[
                      { x: mooringDetails.popArrivalDate, y: height },
                      { x: mooringDetails.popDepartureDate, y: height },
                    ]}
                  />

                  <ReferenceLine
                    stroke="blue"
                    strokeWidth={3}
                    segment={[
                      { x: mooringDetails.popArrivalDate, y: height },
                      { x: mooringDetails.popArrivalDate, y: height + 1.0 },
                    ]}
                    label={{
                      value: `POB: ${moment(
                        mooringDetails.popArrivalDate
                      ).format("HH:mm")}`,
                      position: "top",
                      fill: "black",
                      fontSize: 12,
                      dy: -10, // Shift the label upward
                      dx: -15, // Shift the label to the right
                    }}
                  />
                  <ReferenceDot
                    x={mooringDetails.popArrivalDate}
                    y={height}
                    r={3}
                    fill={"yellow"}
                    ifOverflow="visible"
                  />

                  {/* For the Vessel ARRIVAL // Vessel Line */}
                  <ReferenceLine
                    stroke="green"
                    strokeWidth={3}
                    segment={[
                      { x: mooringDetails.vesselArrivalDate, y: height },
                      {
                        x: mooringDetails.vesselArrivalDate,
                        y: height + 1.0,
                      },
                    ]}
                    label={{
                      value: `${moment(mooringDetails.vesselArrivalDate).format(
                        "HH:mm"
                      )}`,
                      position: "top",
                      fill: "black",
                      fontSize: 12,
                    }}
                  />
                  <ReferenceLine
                    stroke="green"
                    strokeWidth={3}
                    segment={[
                      { x: mooringDetails.vesselArrivalDate, y: height },
                      { x: mooringDetails.vesselDepartureDate, y: height },
                    ]}
                  />
                  <ReferenceDot
                    x={mooringDetails.vesselArrivalDate}
                    y={height}
                    r={3}
                    fill={"yellow"}
                    ifOverflow="visible"
                  />
                </React.Fragment>
              )}

              {/* <ReferenceArea y2={calculateMinTideHt()} fill={"red"} /> */}
              <Line
                type="monotone"
                dataKey="height"
                stroke={Colors.tideHeight}
                dot={false}
                strokeWidth={1}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default HwwVesselArrivalTideGraph;

import { PublicClientApplication, Configuration } from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    clientId: "7d021f6b-1e83-4b02-9dd2-13698d76069b",
    authority:
      "https://login.microsoftonline.com/78d4545c-43b0-4a7e-b4cb-d91916d6b1cd",
    redirectUri:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        // adjusted from "https://www.portcon.ca" to https://www.portcon.ca/auth.html" Joel
        : "https://www.portcon.ca",
  },
  //new from Joel
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true, // Helps prevent issues with hash clearance
  },
  //new from joel
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;

import "bootstrap/dist/css/bootstrap.css";
import React, { useState, useEffect, useContext, useRef, use } from "react";
import "react-datetime/css/react-datetime.css";
import "moment-timezone";
import { FaTrash, FaCalendarPlus } from "react-icons/fa";
import AddGangwayReservationForm from "../Gangway/AddGangwayReservationForm";
import {
  ComposedChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Line,
} from "recharts";
import { Tooltip as ReactToolTip } from "react-tooltip";
import { EnrichedBooking, VesselDoorDTO } from "../../../Models/VesselDoorDTO";
import { SlopeCalculation } from "../../../Models/CalculationsDTO";
import { BookingResource } from "../../../Models/Booking";
import { getColorBasedOnValue } from "../OperationUtils/OperationUtility";
import { CustomTooltip } from "../CustomViews/CustomToolTip";
import { Refresh } from "Pages/Operation/Operation";

interface DoorpGraphModalProps {
  closeModal: () => void;
  vesselDoorId: number;
  vesselDoorName: string;
  vesselDoorHeight: number;
  booking: EnrichedBooking;
  doorSlopeData: SlopeCalculation[];
  modalAssemblyId: number;
  modalAssemblyName: string;
  positiveSlopeCaution: number;
  positiveSlopeAcceptable: number;
  negativeSlopeAcceptable: number;
  negativeSlopeCaution: number;
  bookingResources: BookingResource[];
  handleRemoveBookingResource: (
    bookingId: number,
    vesselDoorId: number
  ) => Promise<boolean | void>;
  setRefresh: React.Dispatch<React.SetStateAction<Refresh>>;
}

const OperationGraphModaL: React.FC<DoorpGraphModalProps> = ({
  closeModal,
  vesselDoorId,
  vesselDoorName,
  vesselDoorHeight,
  booking,
  doorSlopeData,
  modalAssemblyId,
  modalAssemblyName,
  positiveSlopeCaution,
  positiveSlopeAcceptable,
  negativeSlopeAcceptable,
  negativeSlopeCaution,
  bookingResources,
  handleRemoveBookingResource,
  setRefresh,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const [showGangwayReservation, setShowGangwayReservation] = useState(false);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, [closeModal]);

  const isResourceFound = bookingResources.some(
    (resource) =>
      resource.bookingId === booking.id &&
      resource.vesselDoorId === vesselDoorId
  );

  const foundResource = bookingResources.find(
    (resource) =>
      resource.bookingId === booking.id &&
      resource.vesselDoorId === vesselDoorId
  );

  return (
    <div className={`operationsListModal${isResourceFound ? "Border" : ""}`}>
      <div className="pictureGraphModalContainer">
        <div className="operationsGraphModal" ref={modalRef}>
          <div className="iconContainer">
            <p>
              Door Name: {vesselDoorName}, Door Height: {vesselDoorHeight},
              Assembly type: {modalAssemblyName}
            </p>
            {isResourceFound ? (
              <button
                data-tooltip-id="operationsMapGraphModalIcons"
                data-tooltip-content="Remove Gangway Reservation"
                className="btn delete"
                onClick={async () => {
                  const result = await handleRemoveBookingResource(
                    booking.id,
                    vesselDoorId
                  );
                  if (result) {
                    closeModal();
                    setRefresh({
                      refresh: true,
                      showLoading: true,
                    });
                  }
                }}
              >
                <FaTrash className="deleteIcon" />
              </button>
            ) : (
              <button
                data-tooltip-id="operationsMapGraphModalIcons"
                data-tooltip-content="Add Gangway Reservation"
                className="btn save"
                onClick={() => setShowGangwayReservation(true)}
              >
                <FaCalendarPlus className="deleteIcon" />
              </button>
            )}
            <ReactToolTip
              id="operationsMapGraphModalIcons"
              place="right"
              className="operationsMapGraphModalTooltip"
            />
          </div>
          <button className="graphModalCancel" onClick={closeModal}>
            <span>&times;</span>
          </button>
          <div className="chartContainer">
            <ResponsiveContainer>
              <ComposedChart
                width={1200}
                height={225}
                data={doorSlopeData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={(tick) =>
                    new Date(tick).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })
                  }
                />
                <YAxis
                  label={{
                    value: "% Slope",
                    angle: -90,
                    position: "insideLeft",
                    style: { textAnchor: "middle", fontSize: 15 },
                  }}
                  domain={[-40, 40]}
                  allowDataOverflow={true}
                />
                <Tooltip
                  cursor={false}
                  content={({ active, payload, label }) => (
                    <CustomTooltip
                      active={active}
                      payload={payload}
                      label={label}
                    />
                  )}
                />
                <Bar dataKey="slopeMultiples" barSize={40}>
                  {doorSlopeData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getColorBasedOnValue(
                        entry.slope,
                        positiveSlopeCaution,
                        positiveSlopeAcceptable,
                        negativeSlopeAcceptable,
                        negativeSlopeCaution
                      )}
                      stroke={getColorBasedOnValue(
                        entry.slope,
                        positiveSlopeCaution,
                        positiveSlopeAcceptable,
                        negativeSlopeAcceptable,
                        negativeSlopeCaution
                      )}
                      strokeWidth={10}
                    />
                  ))}
                </Bar>
                <ReferenceLine
                  x={booking.mooringDateTime}
                  stroke="green"
                  label="Mooring Time"
                />
                <ReferenceLine
                  x={booking.departureDateTime}
                  stroke="red"
                  label="Departure Time"
                />
                <Line
                  type="monotone"
                  dataKey="slope"
                  stroke="#8884d8"
                  dot={false}
                  strokeWidth={2}
                />
              </ComposedChart>
            </ResponsiveContainer>

            {/* <div className="iconContainer">
              {isResourceFound ? (
                <button
                  className="btn delete"
                  onClick={() =>
                    handleRemoveBookingResource(booking.id, vesselDoorId)
                  }
                >
                  <FaTrash className="deleteIcon" />
                </button>
              ) : (
                <button
                  className="btn save"
                  onClick={() => setShowGangwayReservation(true)}
                >
                  <FaCalendarPlus className="deleteIcon" />
                </button>
              )}
              <ReactToolTip
                id="operationsMapGraphModalIcons"
                place="right"
                className="operationsMapGraphModalTooltip"
              />
            </div> */}
          </div>

          {showGangwayReservation && (
            <AddGangwayReservationForm
              closeModal={closeModal}
              bookingResourceId={foundResource?.id || 0}
              bookingId={booking.id}
              gangwayId={foundResource?.gangwayId || 0}
              platformId={foundResource?.platformId}
              modalAssemblyId={modalAssemblyId}
              vesselDoorId={vesselDoorId}
              mooringDateTime={booking.mooringDateTime}
              departureDateTime={booking.departureDateTime}
              setRefresh={setRefresh}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OperationGraphModaL;

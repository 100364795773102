import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import "moment-timezone";
import "react-toastify/dist/ReactToastify.css";
import { ReservationFormData } from "../../../Services/BookingResourcesRequest";
import { BookingResourceChartData } from "./OperationsGangwayReservations";
import { NewErrors } from "./AddGangwayReservationForm";
import { Refresh } from "Pages/Operation/Operation";

interface OperationShowGangwayReservationModalProps {
  closeModal: () => void;
  bookingId: number;
  selectedReservation: BookingResourceChartData;
  updateGangwayReservation: (
    reservation: ReservationFormData,
    closeModal: () => void
  ) => Promise<void>;
  fetchBookingResources: (bookingId: number) => void;
  handleRemoveBookingResource: (
    bookingId: number,
    vesselDoorId: number
  ) => Promise<boolean | void>;
  setRefresh: React.Dispatch<React.SetStateAction<Refresh>>;
}

const OperationShowGangwayReservationModal: React.FC<
  OperationShowGangwayReservationModalProps
> = ({
  closeModal,
  bookingId,
  selectedReservation,
  updateGangwayReservation,
  fetchBookingResources,
  handleRemoveBookingResource,
  setRefresh,
}) => {
  const timestamps: number[] = selectedReservation.y || [];
  const [reservationDateError, setReservationDateError] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<NewErrors | null>(null);

  const [formData, setFormData] = useState<ReservationFormData>({
    bookingResourceId: 0,
    bookingId: bookingId,
    gangwayId: 0,
    platformId: 0,
    vesselDoorId: 0,
    startDateTime: "",
    endDateTime: "",
    assemblyId: 0,
    resourceType: "Gangway",
  });

  useEffect(() => {
    setFormData({
      bookingResourceId: selectedReservation.id,
      bookingId: selectedReservation.bookingId,
      gangwayId: selectedReservation.gangwayId || 0,
      platformId: selectedReservation.platformId || 0,
      vesselDoorId: selectedReservation.vesselDoorId || 0,
      startDateTime: moment(selectedReservation.y[0]).format(),
      endDateTime: moment(selectedReservation.y[1]).format(),
      assemblyId: selectedReservation.assemblyId || 0,
      resourceType: "Gangway",
    });
  }, [selectedReservation]);

  const handleDateChange = (date: moment.Moment | string, id: string) => {
    const localDate = moment(date).format();
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, [id]: localDate };
      if (newFormData.startDateTime && newFormData.endDateTime) {
        setReservationDateError(
          moment(newFormData.startDateTime).isSameOrAfter(
            moment(newFormData.endDateTime)
          )
        );
      }
      return newFormData;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formattedDates = {
      ...formData,
      vesselDoorId: selectedReservation.vesselDoorId || 0,
      startDateTime: formData.startDateTime
        ? moment(formData.startDateTime).format()
        : "",
      endDateTime: formData.endDateTime
        ? moment(formData.endDateTime).format()
        : "",
    };
    if (validateForm()) {
      try {
        await updateGangwayReservation(formData, closeModal);
        setRefresh({
          refresh: true,
          showLoading: true,
        });
      } catch (error) {
        console.error("Error updating gangway reservation:", error);
      }
    }
  };

  const validateForm = () => {
    const newErrors: NewErrors = {
      startDateTime: "",
      endDateTime: "",
      gangwayId: "",
      platformId: "",
      vesselName: "",
    };
    if (!formData.startDateTime) {
      newErrors.startDateTime = "Start Time is required";
    }
    if (
      formData.startDateTime &&
      formData.endDateTime &&
      moment(formData.startDateTime).isSameOrAfter(moment(formData.endDateTime))
    ) {
      newErrors.startDateTime = "Start Time is after end time";
    }
    if (!formData.endDateTime) {
      newErrors.endDateTime = "End Time is required";
    }
    if (!formData.gangwayId) {
      newErrors.gangwayId = "Choose a gangway";
    }
    // if (!formData.platformId) {
    //   newErrors.platformId = "Choose a platform";
    // }
    // Remove empty errors (optional)
    Object.keys(newErrors).forEach((key) => {
      if (!newErrors[key as keyof NewErrors])
        delete newErrors[key as keyof NewErrors];
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row gx-0">
          <div
            className="text-center"
            style={{ width: 150, marginRight: "10px", marginLeft: "10px" }}
          >
            <label className="modalLables" htmlFor="contactDate">
              Start Time <span className="required">*</span>
            </label>
            <DateTime
              dateFormat={false}
              className="form-control modalDisplay"
              value={
                formData.startDateTime ? moment(formData.startDateTime) : ""
              }
              onChange={(date) =>
                handleDateChange(date as moment.Moment, "startDateTime")
              }
            />
          </div>
          <div className="text-center" style={{ width: 150 }}>
            <label className="modalLables" htmlFor="contactDate">
              End Time <span className="required">*</span>
            </label>
            <DateTime
              dateFormat={false}
              className="form-control modalDisplay"
              value={formData.endDateTime ? moment(formData.endDateTime) : ""}
              onChange={(date) =>
                handleDateChange(date as moment.Moment, "endDateTime")
              }
            />
          </div>
        </div>
        <div className="text-center" style={{ width: 330 }}>
          <button className="btn gangSave" type="submit">
            Save
          </button>
          <button className="btn gangClose" type="button" onClick={closeModal}>
            Close
          </button>
          <button
            className="btn gangDelete"
            type="button"
            onClick={async () => {
              const result = await handleRemoveBookingResource(
                selectedReservation.bookingId,
                selectedReservation.vesselDoorId || 0
              );
              if (result) {
                closeModal();
                setRefresh({
                  refresh: true,
                  showLoading: true,
                });
              }
            }}
          >
            Delete
          </button>
        </div>
      </form>
    </div>
  );
};

export default OperationShowGangwayReservationModal;

import { toast } from "react-toastify";
import { useApi } from "../api/api";
import { useCallback } from "react";
import { MooringLineBookingResourceDTO } from "../Models/MooringLine";

// Hook for fetching Mooring Line Booking Resources
export const useMooringLineBookingResourceRequests = () => {
    const { request } = useApi<MooringLineBookingResourceDTO[]>();
    const { request: updateRequest } = useApi<MooringLineBookingResourceDTO[]>();
    const { request: deleteRequest } = useApi<MooringLineBookingResourceDTO[]>();

    // Fetch Mooring Line Booking Resources
    const fetchMooringLineBookingResource = useCallback(async (bookingId: number): Promise<MooringLineBookingResourceDTO[] | undefined> => {
        try {
            const apiUrl = `/MooringLineBookingResource/bookingId/${bookingId}`;
            const response = await request(apiUrl, "GET");
            if (!Array.isArray(response)) {
                console.error("Unexpected API response format for MooringLineBookingResource:", response);
                return undefined;
            }
            return response;
        } catch (error) {
            console.error("Error fetching mooring line booking resources:", error);
            toast.error("Error fetching mooring line booking resources");
            return undefined;
        }
    }, [request]);

    const setMooringLineResource = useCallback(async (
        bookingId: number,
        mooringLineId: number,
        xCoordinateFactor: number,
        yCoordinateFactor: number
    ): Promise<boolean> => {
        try {
            const apiUrl = `/MooringLineBookingResource/bookingId/${bookingId}/mooringLineId/${mooringLineId}/xCF/${xCoordinateFactor}/yCF/${yCoordinateFactor}`;
            await updateRequest(apiUrl, "PUT", { bookingId, mooringLineId, xCoordinateFactor, yCoordinateFactor })
            // toast.success("Mooring line updated successfully", {
            //      position: "top-center",
            //      hideProgressBar: true,
            //  });

            return true;
        } catch (error) {
            console.error("Error updating mooring line:", error);
            toast.error("Error updating mooring line");
            return false;
        }
    }, []);

    const deleteMooringLineResource = useCallback(async (
        bookingId: number,
        mooringLineId: number,
    ): Promise<boolean> => {
        try {
            const apiUrl = `/MooringLineBookingResource/delete/bookingId/${bookingId}/mooringLineId/${mooringLineId}`;
            await deleteRequest(apiUrl, "DELETE", { bookingId, mooringLineId, })
            //    toast.success("Mooring line deleted successfully", {
            //        position: "top-center",
            //       hideProgressBar: true,
            //   });
            return true;
        } catch (error) {
            console.error("Error updating mooring line:", error);
            toast.error("Error updating mooring line");
            return false;
        }
    }, []);

    return { fetchMooringLineBookingResource, setMooringLineResource, deleteMooringLineResource };

};

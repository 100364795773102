// Interface for vesselFactors where the vesselId maps to a single factor (number)
interface VesselFactorsProps {
  [vesselId: number]: number;
}

export const VesselFactors: VesselFactorsProps = {
  // factors for full hd are 0.501 and 0.775 and 0.244
  // factors for original were 1.34 and 2.06
  // factors for master are 2.67 and 4.12
  // factors for qhd are 0.667 and 1.033
  1: 0.775, //ADVENTURE OF THE SEAS
  2: 0.501, //AMADEA
  3: 0.244, //AMBIENCE  
  4: 0.501, //AMERA
  5: 0.501, //ARCADIA
  6: 0.770, //AURORA
  7: 0.244, //AZAMARA PURSUIT
  8: 0.501, //BAIE ST PAUL
  9: 0.785, //BOLETTE
  10: 0.501, //CARIBBEAN PRINCESS
  11: 0.244, //CARNIVAL SUNSHINE       
  12: 0.244, //CARNIVAL VENEZIA        
  13: 0.244, //CELEBRITY ECLIPSE
  14: 0.775, //CELEBRITY SUMMIT
  15: 0.775, //CROWN PRINCESS
  16: 0.244, //CRYSTAL SERENITY
  17: 0.501, //DISNEY DREAM
  18: 0.501, //DISNEY FANTASY
  19: 0.501, //DISNEY MAGIC
  20: 0.501, //DISNEY WONDER
  21: 0.501, //EMERALD PRINCESS
  22: 0.775, //EMPRESS OF THE SEAS
  23: 0.775, //ENCHANTMENT OF THE SEAS
  24: 0.785, //EUROPA
  25: 0.501, //EXPLORA 1
  26: 0.785, //EXPLORER OF THE SEAS
  27: 0.775, //GRANDEUR OF THE SEAS
  28: 0.81, //GRANDIOSA
  29: 0.244, //HANSEATIC INSPIR
  30: 0.775, //HANSEATIC NATURE
  31: 0.775, //INSIGNIA
  //32: 0.244, //IRVING ACADIAN           
  33: 0.244, //JEWEL OF THE SEAS        
  34: 0.244, //LE BELLOT
  35: 0.244, //MAJESTIC PRINCESS        
  36: 0.775, //MARCO POLO
  //37: 0.775, //MS MARINA                   
  38: 0.785, //MARINER OF THE SEAS
  39: 0.785, //MEIN SCHIFF 1
  40: 0.785, //MEIN SCHIFF 2
  41: 0.775, //MEIN SCHIFF 3
  42: 0.775, //MEIN SCHIFF 4
  43: 0.775, //MEIN SCHIFF 5
  44: 0.775, //MEIN SCHIFF 6
  45: 0.510, //MERAVIGLIA
  46: 0.505, //MSC POESIA
  47: 0.785, //NAVIGATOR OF THE SEAS
  48: 0.775, //NIEUW STATENDAM
  49: 0.820, //NORWEGIAN BLISS
  50: 0.820, //NORWEGIAN ENCORE
  51: 0.775, //NORWEGIAN GEM
  52: 0.244, //NORWEGIAN JADE
  53: 0.244, //NORWEGIAN JEWEL            
  54: 0.820, //NORWEGIAN JOY
  55: 0.775, //NORWEGIAN PEARL
  56: 0.505, //NORWEGIAN SKY
  57: 0.565, //OASIS OF THE SEAS
  58: 0.244, //OCEAN ENDEAVOUR
  59: 0.501, //OCEAN EXPLORER
  //60: 0.501, //OCEAN NAVIGATOR
  //61: 0.775, //OCEAN VOYAGER             
  62: 0.501, //PEARL MIST
  63: 0.244, //QUEEN MARY 2               
  //64: 0.775, //RADIANCE CLASS SHIPS   
  65: 0.775, //RCYC EVRIMA
  66: 0.810, //ROYAL PRINCESS
  67: 0.244, //SAPPHIRE PRINCESS           
  68: 0.244, //SCENIC ECLIPSE
  69: 0.244, //SEA DISCOVERER
  70: 0.501, //SEABOURN ODYSSEY
  71: 0.365, //SEABOURN OVATION           
  72: 0.775, //SEABOURN QUEST
  73: 0.501, //SEABOURN SOJOURN
  74: 0.775, //SEVEN SEAS MARINER
  75: 0.501, //SEVEN SEAS NAVIGATOR
  76: 0.775, //SILVER SHADOW
  77: 0.777, //SILVER WHISPER
  78: 0.501, //SKY PRINCESS
  79: 0.501, //SPIRIT OF ADVENTURE
  80: 0.501, //THUNDER BAY
  81: 0.501, //VIKING JUPITER
  82: 0.501, //VIKING MARS
  83: 0.501, //VIKING OCTANTIS
  84: 0.501, //VIKING ORION
  85: 0.501, //VIKING POLARIS
  86: 0.501, //VIKING SEA
  87: 0.501, //VIKING SKY
  88: 0.501, //VIKING STAR
  89: 0.501, //VIKING VENUS
  90: 0.810, //VIRTUOSA
  91: 0.365, //VOLENDAM
  92: 0.775, //VOYAGER OF THE SEAS
  93: 0.501, //WORLD DREAM
  94: 0.775, //ZAANDAM
  95: 0.365, //ZUIDERDAM

  96: 0.244, //AMBITION
  97: 0.244, //AZAMARA JOURNEY
  98: 0.244, //MS MARINA
  99: 0.244, //NORWEGIAN STAR
  100: 0.244, //RADIANCE OF THE SEAS
  101: 0.244, //SEVEN SEAS SPLENDOR
  102: 0.244, //THE WORLD
  103: 0.244, //VICTORY I
  104: 0.244, //VICTORY II
  105: 0.244, //VIKING NEPTUNE
  107: 0.244, //AZAMARA QUEST
  108: 0.244, //BRILLIANCE OF THE SEAS
  109: 0.244, //CELEBRITY EQUINOX
  110: 0.244, //CELEBRITY REFLECTION
  111: 0.244, //CELEBRITY SILHOUETTE
  112: 0.244, //CELEBRITY SOLSTICE
  114: 0.244, //CRYSTAL SYMPHONY
  115: 0.244, //EXPLORA III
  116: 0.244, //EXPLORA IV
  117: 0.244, //LE BOUGAINVILLE
  118: 0.244, //LE CHAMPLAIN
  119: 0.244, //LE DUMONT-D'URVILLE
  120: 0.244, //LE JACQUES CARTIER
  121: 0.244, //LE LAPEROUSE
  122: 0.244, //SERENADE OF THE SEAS
  123: 0.244, //ISLAND PRINCESS
};

import React from "react";
import {
  ComposedChart,
  Bar,
  Cell,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from "recharts";
import { EnrichedBooking, VesselDoorDTO } from "../../../Models/VesselDoorDTO";
import {
  CalculationsDTO,
  SlopeCalculation,
} from "../../../Models/CalculationsDTO";
import { BookingResource } from "../../../Models/Booking";
import { getColorBasedOnValue } from "../OperationUtils/OperationUtility";
import {
  CustomTooltip,
  CustomTooltipPower,
} from "../CustomViews/CustomToolTip";
import Draggable from "react-draggable";
import { DoorType } from "../DoorRendring/AllDoorRendring";

/**
 * Props interface for OperationsShowGraphs component
 */
interface OperationsShowGraphsProps {
  vesselId: number;
  selectedDoor: VesselDoorDTO;
  doorSlopeDataAssemblies: CalculationsDTO[];
  bookingResources: BookingResource[];
  handleGraphModalOpen: (
    vesselDoorId: number,
    vesselDoorName: string,
    vesselDoorHeight: number,
    vessel: EnrichedBooking,
    assemblyDataForDoor: SlopeCalculation[],
    assemblyId?: number,
    assembly?: string,
    positiveSlopeCaution?: number,
    positiveSlopeAcceptable?: number,
    negativeSlopeAcceptable?: number,
    negativeSlopeCaution?: number
  ) => void;
  bookingData: EnrichedBooking[];
}

/**
 * OperationsShowGraphs component
 * Displays a draggable graph of slope data for a selected vessel door
 */
const OperationsShowGraphs = ({
  vesselId,
  selectedDoor,
  doorSlopeDataAssemblies,
  bookingResources,
  handleGraphModalOpen,
  bookingData,
}: OperationsShowGraphsProps) => {
  // Find the corresponding booking based on vesselId
  const booking = bookingData.find((v) => v.vesselId === vesselId);
  if (!booking || booking.berthId === 5) return null; // Exit if no booking or berthId is 5 (Tender)

  // Mapping berthId to berth name
  const berthMap: { [key: number]: string } = {
    2: "South East",
    3: "South West",
    4: "East",
    5: "Tender",
  };
  // Mapping berthId to graph position
  // const positionMap: { [key: number]: { x: number; y: number } } = {
  //   2: { x: 1545, y: 350 },
  //   3: { x: 130, y: 350 },
  //   4: { x: 1545, y: 100 },
  // };
  const berthName = booking.berthId
    ? berthMap[booking.berthId] || "Berth Unknown"
    : "Berth Unknown";
  // const position = booking.berthId
  //   ? positionMap[booking.berthId] || { x: 0, y: 0 }
  //   : { x: 0, y: 0 };

  const arrangePowerDoorData = (data: CalculationsDTO[]) => {
    const result = data.reduce(
      (acc: Record<number, CalculationsDTO[]>, item) => {
        if (!acc[item.doorId]) {
          acc[item.doorId] = [];
        }
        acc[item.doorId].push(item);
        return acc;
      },
      {}
    );
    return result;
  };

  return (
    <Draggable>
      <div
        className="operationsPictureShowGraphs"
        style={{ position: "absolute", cursor: "grab" }}
      >
        {/* Display Berth and Door Details */}
        <div className="chartTitle">Berth: {berthName}</div>
        <div className="chartTitle">
          Door Name: {selectedDoor.name}, Door Height: {selectedDoor.height}
        </div>

        {selectedDoor.doorType.toLocaleLowerCase() === DoorType.PASSENGER ? (
          Object.entries(doorSlopeDataAssemblies)
            .sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
            .map(([assembly, assemblyData]) => {
              const assemblyDataForDoor = assemblyData.slopeCalculations || [];
              const assemblyName = assemblyData.assemblyName || "";
              const {
                positiveSlopeCaution,
                positiveSlopeAcceptable,
                negativeSlopeAcceptable,
                negativeSlopeCaution,
                assemblyId,
              } = assemblyData;

              // Check if resource exists for the booking
              const isResourceFound = bookingResources.some(
                (resource) =>
                  resource.bookingId === booking.id &&
                  resource.vesselDoorId === selectedDoor.id &&
                  resource.assemblyId === assemblyId
              );

              return (
                <div
                  key={assembly}
                  className={`operationsListModal${isResourceFound ? "Border" : ""
                    }`}
                >
                  <div className="chartTitle">
                    Assembly Type: {assemblyName}
                  </div>
                  <ComposedChart
                    width={300}
                    height={100}
                    data={assemblyDataForDoor}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    onClick={() =>
                      handleGraphModalOpen(
                        selectedDoor.id,
                        selectedDoor.name,
                        selectedDoor.height,
                        booking,
                        assemblyDataForDoor,
                        assemblyId,
                        assemblyName,
                        positiveSlopeCaution,
                        positiveSlopeAcceptable,
                        negativeSlopeAcceptable,
                        negativeSlopeCaution
                      )
                    }
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(tick) =>
                        new Date(tick).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })
                      }
                      interval={15}
                      tick={{ fontSize: 12 }}
                    />
                    <YAxis
                      domain={[-40, 40]}
                      allowDataOverflow={true}
                      tick={{ fontSize: 12 }}
                      label={{
                        value: "% Slope",
                        angle: -90,
                        position: "insideCenter",
                        style: { textAnchor: "middle", fontSize: 10 },
                      }}
                    />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Bar dataKey="slopeMultiples" barSize={20}>
                      {assemblyDataForDoor.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={getColorBasedOnValue(
                            entry.slope,
                            positiveSlopeCaution,
                            positiveSlopeAcceptable,
                            negativeSlopeAcceptable,
                            negativeSlopeCaution
                          )}
                          stroke={getColorBasedOnValue(
                            entry.slope,
                            positiveSlopeCaution,
                            positiveSlopeAcceptable,
                            negativeSlopeAcceptable,
                            negativeSlopeCaution
                          )}
                          strokeWidth={2}
                        />
                      ))}
                    </Bar>
                    <ReferenceLine
                      x={booking.mooringDateTime}
                      stroke="green"
                      label="MT"
                    />
                    <ReferenceLine
                      x={booking.departureDateTime}
                      stroke="red"
                      label="DT"
                    />
                    <Line
                      type="monotone"
                      dataKey="slope"
                      stroke="#8884d8"
                      dot={false}
                      strokeWidth={2}
                    />
                  </ComposedChart>
                </div>
              );
            })
        ) : selectedDoor.doorType.toLocaleLowerCase() ===
          DoorType.SHORE_CONNECTION ? (
          <ComposedChart
            width={300}
            height={100}
            data={
              arrangePowerDoorData(doorSlopeDataAssemblies)[selectedDoor.id]
            }
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
            barCategoryGap={0}
            barGap={0}
            onClick={() => {
              handleGraphModalOpen(
                selectedDoor.id,
                selectedDoor.name,
                selectedDoor.height,
                booking,
                doorSlopeDataAssemblies[selectedDoor.id].slopeCalculations
              );
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(tick) =>
                new Date(tick).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
              }
              interval={15}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              dataKey="differenceFromDock"
              domain={["auto", "auto"]}
              allowDataOverflow={true}
              tick={{ fontSize: 12 }}
            />
            <Tooltip cursor={false} content={<CustomTooltipPower />} />

            <ReferenceLine
              x={booking.mooringDateTime}
              stroke="green"
              label="MT"
            />
            <ReferenceLine
              x={booking.departureDateTime}
              stroke="red"
              label="DT"
            />
            <Line
              type="monotone"
              dataKey="differenceFromDock"
              stroke="#8884d8"
              dot={false}
              strokeWidth={2}
            />
          </ComposedChart>
        ) : (
          <p>Unknown door type</p> // Default case (else)
        )}

        {/* Loop through door slope data and generate charts */}
      </div>
    </Draggable>
  );
};

export default OperationsShowGraphs;

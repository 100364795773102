  import {
    StyleSheet,
  } from "@react-pdf/renderer";

  export const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
    },
    picturePage: {
      backgroundColor: "white",
      flexDirection: "column",
      justifyContent: "center",
    },
    container: {
      alignItems: "center",
    },
    graphContainer: {
      margin: 10,
      alignItems: "center",
    },
    titleContainer: {
      margin: 10,
      alignItems: "flex-end",
    },
    image: {
      width: "100%",
      objectFit: "contain",
      borderRadius: 10,
      padding: "10px",
    },
    graph: {
      width: "100%",
      objectFit: "contain",
      padding: "5px",
      // borderRadius: 10,
    },
    hvvwGraphs: {
      width: "100%",
      objectFit: "contain",
      // padding: "5px",
    },
    hvvwGraphsContainer: {
      margin: 10,
      alignItems: "center",
    },
    tide: {
      width: "100%",
      objectFit: "contain",
      padding: "10px",
    },
    detailsContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 5,
      height: "100px",
    },
    detailsTitlesContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    header: {
      flex: 1,
      padding: 10,
    },
    text: {
      fontSize: 10,
      paddingTop: 10,
      paddingBottom: 10,
      fontFamily: "Montserrat",
      color: "#1b2a4e",
    },
    textTitle: {
      fontSize: 32,
      textAlign: "center",
      color: "#1b2a4e",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      paddingTop: 20,
      paddingBottom: 2,
    },
    textSubTitle: {
      fontSize: 12,
      paddingTop: 10,
      paddingBottom: 10,
      fontFamily: "Montserrat",
      textAlign: "center",
      color: "#1b2a4e",
    },
    textSubSubTitle: {
      fontSize: 8,
      paddingTop: 10,
      paddingBottom: 10,
      fontFamily: "Montserrat",
      color: "#1b2a4e",
    },
    date: {
      fontSize: 20,
      textAlign: "center",
      color: "#555",
    },
    titleUnderline: {
      height: 3,
      backgroundColor: "#1b2a4e",
      width: "95%",
      alignSelf: "center",
      marginVertical: 10,
    },
    titleHvvwUnderline: {
      height: 3,
      backgroundColor: "#1b2a4e",
      width: "95%",
      alignSelf: "center",
      marginVertical: 30,
    },
    sectionUnderline: {
      height: 2,
      backgroundColor: "#1b2a4e",
      width: "95%",
      alignSelf: "center",
      opacity: 0.6,
      marginVertical: 20,
    },
    safeLine: {
      height: 4,
      backgroundColor: "#aad4aa",
      width: "15%",
      alignSelf: "flex-start",
    },
    cautionLine: {
      height: 4,
      backgroundColor: "#ffeb99",
      width: "15%",
      alignSelf: "flex-start",
      paddingLeft: 5,
    },
    unsafeLine: {
      height: 4,
      backgroundColor: "#f4a6a6",
      width: "15%",
      alignSelf: "flex-start",
      paddingLeft: 5,
    },
    percentSlopeLine: {
      height: 4,
      backgroundColor: "blue",
      width: "15%",
      alignSelf: "flex-start",
      paddingLeft: 5,
    },
    legend: {
      fontSize: 10,
      fontFamily: "Montserrat",
      textAlign: "center",
      paddingLeft: 5,
      color: "#1b2a4e",
    },
    legendTitle: {
      fontSize: 15,
      fontWeight: 700,
      fontFamily: "Montserrat",
      textAlign: "center",
      color: "#1b2a4e",
    },
    legendItem: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 5,
    },
    developedContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    tideContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    legendContainer: {
      flex: 1,
      justifyContent: "center",
    },
    operationsMapTitle: {
      fontSize: 15,
      fontWeight: 700,
      fontFamily: "Montserrat",
      textAlign: "center",
      color: "#1b2a4e",
    },
    topRightContainer: {
      position: "absolute",
      top: 10, // Adjust as needed
      right: 10, // Adjust as needed
    },
    topRightDate: {
      fontSize: 12, // Adjust font size as needed
      fontWeight: "bold",
    },
  });

import { useEffect, useState, useMemo, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.css";

import {
  FaSort,
  FaRegCalendarAlt,
  FaRegListAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { FaRegSquarePlus } from "react-icons/fa6";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import useBookingRequestOperation from "../../Services/BookingRequests";
import useBookingResourceOperations from "../../Services/BookingResourcesRequest";

import OperationsBookingsModal from "../../Components/Operations/Bookings/OperationsBookingModal";
import { BookingData } from "../../Models/Booking";
import BookingsCalendar from "../../Components/Bookings/BookingsCalendar";
import { EnrichedBooking } from "Models/VesselDoorDTO";
import BookingHeader from "../../Components/Bookings/BookingHeader";
import BookingTableBody from "../../Components/Bookings/BookingTableBody";
import { ToastContainer } from "react-toastify";
import { BerthData } from "Models/Berth";
import OperationHeader from "Components/Operations/OperationHeader/OperationHeader";
import { BookingTableColumn } from "Components/Bookings/BookingTableColumn";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { useDateContext } from "Contexts/DateContext";

function Bookings() {
  const { fetchAllBookingsByDate, fetchAllBookingsByYear } =
    useBookingRequestOperation();
  const { fetchBerthNames } = useBookingResourceOperations();

  const [selectedBooking, setSelectedBooking] = useState<BookingData | null>(
    null
  );

  const [tableBookings, setTableBookings] = useState<BookingData[]>([]); // For table view
  const [calendarBookings, setCalendarBookings] = useState<BookingData[]>([]); // For calendar view
  const [berthNames, setBerthNames] = useState<BerthData[]>([]);

  const [showBookingsUpdateModal, setShowUpdateNewBookingModal] =
    useState(false);
  const [showAddNewBookingModal, setShowAddNewBookingModal] = useState(false);

  const [selectedYear, setSelectedYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const { date, setDate } = useDateContext(); // Access the context
  const [showCalendar, setShowCalendar] = useState(false);
  const [showBerthInCaleder, setShowBerthInCaleder] = useState(false);
  const [searchCalender, setSearchCalender] = useState("");

  const fetchBookingsByDateForTable = async (date: string) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const result = await fetchAllBookingsByDate(formattedDate); // Stable, memoized function
    setTableBookings(result?.bookings ?? []); // Only updating table bookings
  };

  const fetchBookingsByYearForCalender = async (year: string) => {
    const result = await fetchAllBookingsByYear(selectedYear); // Stable, memoized function
    setCalendarBookings(result?.bookings ?? []); // Only updating calendar bookings
  };

  const fetchBookingsByYearForTable = async (year: string) => {
    const result = await fetchAllBookingsByYear(selectedYear); // Stable, memoized function
    setTableBookings(result?.bookings ?? []); // Only updating table bookings
  };
  // const fetchBookingsByYear = useCallback(
  //   async (year?: string) => {
  //     const result = await fetchBookingsWithDetailsByYear(year);
  //     setBookingDetails(result);
  //   },
  //   [fetchBookingsWithDetailsByYear]
  // );

  useEffect(() => {
    const fetchBookings = async () => {
      const result = await fetchAllBookingsByYear(selectedYear);
      const berthNames = (await fetchBerthNames()) ?? [];
      setTableBookings(result?.bookings ?? []); // Set both table and calendar bookings
      setCalendarBookings(result?.bookings ?? []); // Set both table and calendar bookings
      setBerthNames(berthNames);
      setLoading(false);
    };
    fetchBookings();
  }, [selectedYear]); // Make sure to depend on memoized functions

  const [hideCancelled, setHideCancelled] = useState(false);

  const [showList, setShowList] = useState(true);

  const yearsForDropdown = Array.from(
    new Array(50),
    (val, index) => index + 2024
  );
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedDateFromCalendar, setSelectedDateFromCalendar] =
    useState<Date | null>(null);
  const [showNewBookingModalFromCalendar, setShowNewBookingModalFromCalendar] =
    useState(false);

  const showCalendarView = () => {
    setShowCalendar(true);
    setShowList(false);
  };

  const showListView = () => {
    setShowCalendar(false);
    setShowList(true);
  };

  const handleSelectSingleBooking = useCallback((booking: BookingData) => {
    setSelectedBooking(booking);
    setShowUpdateNewBookingModal(true);
    const formattedDate = moment(booking.mooringDateTime).format("YYYY-MM-DD");
    setDate(formattedDate);
  }, []);

  const clearSelections = () => {
    setSelectedBooking(null);
  };

  const closeModal = (date?: string) => {
    if (date) {
      fetchBookingsByDateForTable(date);
      fetchBookingsByYearForCalender(selectedYear);
      // trigger
    }
    setShowAddNewBookingModal(false);
    setShowUpdateNewBookingModal(false);
    setShowNewBookingModalFromCalendar(false);
  };

  const navigateToHome = () => {
    navigate("/operations"); // Adjust the path as needed
  };

  const columns = useMemo(
    () => BookingTableColumn({ navigate, setDate }),
    [navigate, setDate]
  );

  // Filter by vesselName
  const filteredBookings = calendarBookings.filter((booking) =>
    booking.vesselName.toLowerCase().includes(searchCalender.toLowerCase())
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    { columns, data: tableBookings },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="flex flex-col h-screen">
        <ToastContainer />
        <BookingHeader
          navigateToHome={navigateToHome}
          fetchBookingsByDate={fetchBookingsByDateForTable}
          fetchBookingByYear={fetchBookingsByYearForTable}
          setHideCancelled={setHideCancelled}
          hideCancelled={hideCancelled}
          yearsForDropdown={yearsForDropdown}
          setSelectedYear={setSelectedYear}
          selectedYear={selectedYear}
          selectedDate={date}
          setSelectedDate={setDate}
          showCalendarView={showCalendarView}
          showListView={showListView}
          setShowAddNewBookingModal={setShowAddNewBookingModal}
          hideFilter={false}
          setShowBerth={setShowBerthInCaleder}
          setGlobalFilter={setGlobalFilter}
          state={state}
          searchCalender={searchCalender}
          setSearchCalender={setSearchCalender}
        />

        {showCalendar && (
          <BookingsCalendar
            bookings={filteredBookings}
            hideCancelled={hideCancelled}
            handleSelect={handleSelectSingleBooking}
            setShowNewBookingModalFromCalendar={
              setShowNewBookingModalFromCalendar
            }
            setSelectedDateFromCalendar={setSelectedDateFromCalendar}
            setSelectedDate={setDate}
            berthNames={berthNames}
            showBerthInCalendar={showBerthInCaleder}
          />
        )}

        {showList && (
          <BookingTableBody
            loading={loading}
            bookings={tableBookings}
            hideCancelled={hideCancelled}
            handleSelectSingleBooking={handleSelectSingleBooking}
            navigate={navigate}
            setSelectedDate={setDate}
            setGlobalFilter={setGlobalFilter}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            prepareRow={prepareRow}
            rows={rows}
          />
        )}

        {showBookingsUpdateModal && (
          <OperationsBookingsModal
            booking={selectedBooking}
            closeModal={closeModal}
            clearSelections={clearSelections}
            selectedDate={date}
          />
        )}
        {showAddNewBookingModal && (
          <OperationsBookingsModal
            booking={null}
            closeModal={closeModal}
            clearSelections={clearSelections}
            selectedDate={date}
          />
        )}
        {showNewBookingModalFromCalendar && (
          <OperationsBookingsModal
            booking={selectedBooking}
            closeModal={closeModal}
            clearSelections={clearSelections}
            selectedDate={date}
          />
        )}
      </div>
    </>
  );
}

export default Bookings;

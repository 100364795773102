import "bootstrap/dist/css/bootstrap.css";
import React, { useState, useEffect, useRef, use } from "react";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import "moment-timezone";
import "react-toastify/dist/ReactToastify.css";
import { BookingData, SubmitBookingData } from "../../../Models/Booking";
import useBookingResourceOperations from "../../../Services/BookingResourcesRequest";
import { BerthData } from "../../../Models/Berth";
import { AgentDTO, VesselDTO } from "../../../Models/VesselDTO";
import ReactDatetimeClass from "react-datetime";
import useBookingRequestOperation from "Services/BookingRequests";
import { TideDTO } from "Models/TideDTO";
import {
  convertDateAndTimeFromLocal,
  getTopSixTides,
} from "../OperationUtils/OperationUtility";
import { TideCustomTooltipDate } from "../Tides/OperationsMapTide";
import CustomTides from "../Tides/HwwvCustomTides";
import LoadingScreen from "Components/Loader/Loader";
import HvvvWCustomTides from "../Tides/HwwvCustomTides";
import NonHvvwCustomTides from "../Tides/NonHwwwCustomTides";
import { TimeUtils } from "Services/TimeUtils";

export interface HvvWVesselTime {
  mooringDate?: string;
  mooringTime?: string;
  departureDate?: string;
  departureTime?: string;
  pobArrivalDate?: string;
  pobArrivalTime?: string;
  pobDepartureDate?: string;
  pobDepartureTime?: string;
  newPobArrivalDate?: string;
  newPobArrivalTime?: string;
  newPobDepartureDate?: string;
  newPobDepartureTime?: string;
}

interface OperationsBookingsUpdateModalProps {
  booking: BookingData | null;
  closeModal: (selectedDate?: string) => void;
  clearSelections: () => void;
  selectedDate: string;
}

interface BookingError {
  vesselId?: string;
  agentId?: string;
  mooringDateTime?: string;
  departureDateTime?: string;
  berthName?: string;
  status?: string;
  contactDateTime?: string;
  vesselOrientation?: string;
  departureDateTime2?: string;
  mooringTime?: string;
  departureTime?: string;
}

/**
 * OperationsBookingsModal component is responsible for updating or adding booking details.
 * It handles form submissions, validations, and interactions with booking resources.
 *
 * @param {OperationsBookingsUpdateModalProps} props - The component properties.
 * @returns {JSX.Element} The rendered OperationsBookingsModal component.
 */
function OperationsBookingsModal({
  booking,
  closeModal,
  clearSelections,
  selectedDate,
}: OperationsBookingsUpdateModalProps) {
  // State variables for managing form data, errors, and modal visibility.
  const [hasBookingResource, setHasBookingResource] = useState(false);
  const [vesselNames, setVesselNames] = useState<VesselDTO[]>([]);
  const [agentNames, setAgentNames] = useState<AgentDTO[]>([]);
  const [berthNames, setBerthNames] = useState<BerthData[]>([]);
  const modalRef = useRef<HTMLFormElement | null>(null);
  const [bookingDateError, setBookingDateError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errors, setErrors] = useState<BookingError>();
  const [isHwwv, setIsHwwv] = useState(false);
  const [isStJohn, setStJohn] = useState(false);
  const [mooringDate, setMooringDate] = useState<string>("");
  const [mooringTime, setMooringTime] = useState<string>("");
  const [departureDate, setDepartureDate] = useState<string>("");
  const [departureTime, setDepartureTime] = useState<string>("");
  const [mooringTideData, setMooringTideData] = useState<TideDTO[]>([]);
  const [departureTideData, setDepartureTideData] = useState<TideDTO[]>([]);
  const [topSixMooringTides, setTopSixMooringTides] = useState<TideDTO[]>([]);
  const [topSixDepartureTides, setTopSixDepartureTides] = useState<TideDTO[]>(
    []
  );
  const [showMooringTide, setShowMooringTide] = useState(false);
  const [showDepartureTide, setShowDepartureTide] = useState(false);
  const [showDepartureDate, setShowdepatureDate] = useState(false);
  const [channelDepth, setChannelDepth] = useState<number>(0);
  const [shipDraft, setShipDraft] = useState<number>(0);
  const [hvvWInfo, setHvvWInformation] = useState<HvvWVesselTime | undefined>();
  const [vesselName, setVesselName] = useState<string>("");
  const [isLoading, setisLoading] = useState(false);
  const [vessel, setVessel] = useState<VesselDTO | null>();
  const [isEditing, setIsEditing] = useState(false);

  const userPortId =
    sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");

  // Initialize form data with default values or existing booking data.
  const [formData, setFormData] = useState<BookingData>({
    id: 0,
    vesselOrientation: "",
    passengers: 0,
    crew: 0,
    mooringDateTime: "",
    departureDateTime: "",
    status: "",
    cancellationReason: "",
    specialNote: "",
    contactDateTime: "",
    agentId: 0,
    portId: 0,
    berthName: "",
    vesselNote: "",
    vesselId: 0,
    xCoordinateFactor: 1,
    yCoordinateFactor: 1,
    vesselName: "",
  });

  // Custom hook for booking resource operations.
  const {
    checkBookingResourceExists,
    fetchBerthNames,
    fetchVessels,
    fetchVesselsSpecialNote,
    deleteBookingResource,
    handleBookingSubmit,
    deleteBooking,
    fetchAgent,
  } = useBookingResourceOperations();

  // custom hook for fetching tide data
  const { fetchThreeDaysTides, fetchChannelDepth, loading } =
    useBookingRequestOperation();

  useEffect(() => {
    if (booking) {
      setIsEditing(true);
      setFormData({
        ...booking,
      });
      const vessel = vesselNames.find((v) => v.id === Number(booking.vesselId));
      const portID =
        sessionStorage.getItem("userPortId") ||
        localStorage.getItem("userPortId") ||
        "";

      if (!vessel) return;

      setVessel(vessel);
      const isStJohn = portID === "2";
      const isHwwv = vessel.hwwv;
      const vesselName = vessel.name || "";
      const shipDraft = vessel.maxDraft || 0;

      // Update state based on HWWV condition
      setIsHwwv(isHwwv ?? false);
      // setStJohn(isStJohn);
      setVesselName(vesselName);
      setShipDraft(shipDraft);

      // If `isHwwv` is true, split date and time
      if (isStJohn && booking.mooringDateTime && booking.departureDateTime) {
        const mooringDateTime = moment(booking.mooringDateTime).utc();
        const departureDateTime = moment(booking.departureDateTime).utc();

        setMooringDate(mooringDateTime.format("MM/DD/yyyy"));
        setMooringTime(mooringDateTime.format("hh:mm A"));

        setDepartureDate(departureDateTime.format("MM/DD/yyyy"));
        setDepartureTime(departureDateTime.format("hh:mm A"));
      }
    }
  }, [booking, checkBookingResourceExists, vesselNames]);

  // Fetch vessel and berth names on component mount.
  useEffect(() => {
    const fetchData = async () => {
      const vessels = await fetchVessels();
      const agents = await fetchAgent();
      const berths = await fetchBerthNames();
      const channelDepth = await fetchChannelDepth();
      if (channelDepth && channelDepth.length > 0) {
        setChannelDepth(channelDepth[0].lce);
      }
      if (vessels && berths) {
        const filteredVessels =
          userPortId === "3"
            ? vessels.filter((vessel) => [1, 17, 66].includes(vessel.id))
            : vessels;
        setVesselNames(filteredVessels);
        setBerthNames(berths);
      }
      if (agents) {
        setAgentNames(agents);
      }
    };
    fetchData();
  }, [fetchVessels, fetchBerthNames, fetchChannelDepth]);

  // Handle input changes in the form.
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // Handle berth selection changes.
  const handleForBerthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = e.target;
    const berth = berthNames.find((berth) => berth.name === value);
    const berthId = berth?.id || 0;
    const xCoordinateFactor = berth?.xCoordinateFactor || 1;
    const yCoordinateFactor = berth?.yCoordinateFactor || 1;
    setFormData((prevFormData) => ({
      ...prevFormData,
      berthId: berthId,
      [id]: value,
      xCoordinateFactor,
      yCoordinateFactor,
    }));
  };

  // Handle vessel name selection changes and fetch vessel notes.

  const handleNameChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = e.target;
    const vessel = vesselNames.find((v) => v.id === Number(value));
    const portID =
      sessionStorage.getItem("userPortId") ||
      localStorage.getItem("userPortId") ||
      "";

    if (!vessel) return;

    setVessel(vessel);
    const isStJohn = portID === "2";
    const isHwwv = vessel.hwwv;
    const vesselName = vessel.name || "";
    const shipDraft = vessel.maxDraft || 0;
    // Update state based on HWWV condition
    setIsHwwv(isHwwv ?? false);
    setStJohn(isStJohn);
    setVesselName(vesselName);
    setShipDraft(shipDraft);

    // Reset or update mooring and departure fields
    const resetFields = {
      mooringDate: "",
      mooringTime: "",
      departureDate: "",
      departureTime: "",
      mooringDateTime: "",
      departureDateTime: "",
    };

    if (isStJohn) {
      setShowdepatureDate(false);
    }

    setFormData((prev) => ({
      ...prev,
      ...resetFields,
      [id]: value.slice(0, 500),
      vesselName,
    }));

    // Fetch vessel special note
    if (value) {
      try {
        const response = await fetchVesselsSpecialNote(value);
        setFormData((prev) => ({
          ...prev,
          vesselNote: response?.vesselNote || "",
        }));
      } catch (error) {
        console.error("Failed to fetch vessel note:", error);
      }
    }
  };

  const handleAgentNameChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  // Delete booking resources associated with the current booking.
  const deleteBookingResources = async () => {
    if (booking) {
      const result = await deleteBookingResource(booking.id);
      if (result) {
        setHasBookingResource(false);
      }
    }
  };

  // Handle date changes and validate mooring and departure times.
  const handleDateChange = (date: string | moment.Moment, id: string) => {
    const localDate = moment.isMoment(date)
      ? date.utc().format()
      : moment.utc(date).format();

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, [id]: localDate };
      if (newFormData.mooringDateTime && newFormData.departureDateTime) {
        setBookingDateError(
          moment(newFormData.mooringDateTime).isSameOrAfter(
            moment(newFormData.departureDateTime)
          )
        );
      }
      return newFormData;
    });
  };

  const handleHvvwDateChange = (value: string, field: string) => {
    if (field === "mooringDate") {
      setMooringDate("");
      setMooringTime("");
      setDepartureDate("");
      setDepartureTime("");
      formData.mooringDateTime = "";
      formData.departureDateTime = "";
      fetchTideData(value, field);
    } else if (field === "departureDate") {
      // Validate if mooring date is after departure date
      const localMoment = moment(mooringDate).isAfter(moment(value));
      if (localMoment) {
        setBookingDateError(localMoment);
      } else {
        setDepartureDate("");
        setDepartureTime("");
        formData.departureDateTime = "";
        fetchTideData(value, field);
      }
    }
  };

  // Handle form submission and validation.
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validateForm = () => {
      const newErrors: BookingError = {};

      if (!formData.vesselId) {
        newErrors.vesselId = "Vessel Name is required";
      }
      if (!formData.mooringDateTime) {
        newErrors.mooringDateTime = "Mooring Date and Time is required";
      }
      if (!formData.departureDateTime) {
        newErrors.departureDateTime = "Departure Date and Time is required";
      }
      if (formData.status === "CANCELLED") {
      } else if (!formData.berthName) {
        newErrors.berthName = "Berth Name is required";
      }
      if (!formData.status) {
        newErrors.status = "Status is required";
      }
      if (!formData.contactDateTime) {
        newErrors.contactDateTime = "Contact Date and Time is required";
      }
      if (!formData.vesselOrientation) {
        newErrors.vesselOrientation = "Orientation is required";
      }

      if (bookingDateError) {
        newErrors.departureDateTime2 =
          "Departure Date and Time must be after Mooring Date and Time";
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    if (validateForm()) {
      // const { xCoordinateFactor, yCoordinateFactor } = getCoordinatesByBerth(
      //   formData.berthName || ""
      // );
      const userPortId =
        sessionStorage.getItem("userPortId") ||
        localStorage.getItem("userPortId") ||
        "";

      const submitForm: SubmitBookingData = {
        vesselId: formData.vesselId,
        vesselOrientation: formData.vesselOrientation,
        passengers: formData.passengers,
        crew:
          formData.crew === undefined || formData.crew === 0
            ? 0
            : +formData.crew,
        mooringDateTime: TimeUtils.convertToUTCString(
          formData.mooringDateTime || ""
        ),
        departureDateTime: TimeUtils.convertToUTCString(
          formData.departureDateTime || ""
        ),
        status: formData.status,
        cancellationReason: formData.cancellationReason,
        specialNote: formData.specialNote,
        berthId: formData.berthId || 0,
        contactDateTime: formData.contactDateTime,
        agentId: formData.agentId,
        portId: +userPortId,
        xCoordinateFactor: formData.xCoordinateFactor,
        yCoordinateFactor: formData.yCoordinateFactor,
        berthName: formData.berthName || "",
        vesselName:
          vesselNames.find((vessel) => vessel.id === formData.vesselId)?.name ||
          formData.vesselName,
        vesselNote: formData.vesselNote || "",
      };
      if (booking) {
        if (
          hasBookingResource &&
          (booking.vesselId !== formData.vesselId ||
            booking.berthName !== formData.berthName ||
            moment.utc(booking.mooringDateTime).format() !==
              moment.utc(formData.mooringDateTime).format() ||
            moment.utc(booking.departureDateTime).format() !==
              moment.utc(formData.departureDateTime).format())
        ) {
          const userConfirmed = window.confirm(
            "These changes will delete any booking resources associated with this booking. Do you want to proceed?"
          );
          if (!userConfirmed) {
            return;
          }
          deleteBookingResources();
        }
        const result = await handleBookingSubmit(booking.id, submitForm);
        if (result) {
          clearSelections();
          closeModal(submitForm.mooringDateTime);
        }
      } else {
        const result = await handleBookingSubmit(null, submitForm);
        if (result) {
          clearSelections();
          closeModal(submitForm.mooringDateTime);
        }
      }
    }
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleSetDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteBookingData = async () => {
    if (booking) {
      const result = await deleteBooking(booking.id);
      if (result) {
        clearSelections();
        closeModal(selectedDate);
      }
    }
  };

  const BookingButtons = ({
    booking,
    showDeleteModal,
    handleCloseModal,
  }: {
    booking: BookingData | null;
    showDeleteModal: () => void;
    handleCloseModal: () => void;
  }) => {
    if (booking) {
      return (
        <div className="addButtons">
          <button type="submit" className="btn addingBooking">
            Update Booking
          </button>
          <button
            type="button"
            className="btn deleteBooking"
            onClick={showDeleteModal}
          >
            Delete Booking
          </button>
          <button
            type="button"
            className="btn cancelBooking"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
        </div>
      );
    }

    return (
      <div className="addButtons">
        <button type="submit" className="btn addingBooking">
          Add New Booking
        </button>
        <button
          type="button"
          className="btn cancelBooking"
          onClick={handleCloseModal}
        >
          Cancel
        </button>
      </div>
    );
  };

  const fetchTideData = async (date: string, value: string) => {
    setisLoading(true);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const response = await fetchThreeDaysTides(formattedDate);
    if (response) {
      if (value === "mooringDate") {
        setMooringTideData(response);
        const result = getTopSixTides(response);
        setTopSixMooringTides(result);
        setShowMooringTide(true);
        setShowDepartureTide(false);
      } else if (value === "departureDate") {
        setDepartureTideData(response);
        const result = getTopSixTides(response);
        setTopSixDepartureTides(result);
        setShowDepartureTide(true);
        setShowMooringTide(false);
      }
    }
    setisLoading(false);
  };

  // Listen for changes in hvvwInformations
  useEffect(() => {
    if (hvvWInfo) {
      let updatedFormData = { ...formData };

      if (hvvWInfo.mooringDate && hvvWInfo.mooringTime) {
        const arriveDate = hvvWInfo.mooringDate ?? "";
        const arriveTime = hvvWInfo.mooringTime ?? "";
        const mooringDateTime = convertDateAndTimeFromLocal(
          arriveDate,
          arriveTime
        );
        updatedFormData.mooringDateTime = mooringDateTime || "";
        setShowdepatureDate(true);
      }

      if (hvvWInfo.departureDate && hvvWInfo.departureTime) {
        const departDate = hvvWInfo.departureDate ?? "";
        const departTime = hvvWInfo.departureTime ?? "";
        const departureDateTime = convertDateAndTimeFromLocal(
          departDate,
          departTime
        );
        updatedFormData.departureDateTime = departureDateTime || "";
        // Validate if mooring date is after departure date
        if (
          updatedFormData.mooringDateTime &&
          updatedFormData.departureDateTime
        ) {
          setBookingDateError(
            moment(updatedFormData.mooringDateTime).isSameOrAfter(
              moment(updatedFormData.departureDateTime)
            )
          );
        }
      }
      // Update form data state
      setFormData(updatedFormData);
    }
  }, [hvvWInfo]);

  return (
    <div className="modal-container">
      {/* <ToastContainer /> */}
      <form
        ref={modalRef}
        className="row g-3"
        id="bookingsUpdateModal"
        onSubmit={handleSubmit}
      >
        {bookingDateError && (
          <div className="col-md-12">
            <div className="alert alert-danger modalDisplay" role="alert">
              Mooring date and time must be before departure date and time.
            </div>
          </div>
        )}

        <div className="col-md-6">
          <label className="modalLables" htmlFor="agent">
            Agent <span className="required">*</span>
          </label>
          <select
            className={`form-control modalDisplay ${
              errors?.agentId ? "is-invalid" : ""
            }`}
            id="agentId"
            value={formData.agentId}
            onChange={handleAgentNameChange}
          >
            <option value="">Select Agent</option>
            {agentNames
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((agent, index) => (
                <option key={index} value={agent.id}>
                  {agent.name}
                </option>
              ))}
          </select>
          {errors?.agentId && (
            <div className="invalid-feedback">{errors?.agentId}</div>
          )}
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="contactDate">
            Contact Date <span className="required">*</span>
          </label>
          <DateTime
            className={`form-control modalDisplay ${
              errors?.contactDateTime ? "is-invalid" : ""
            }`}
            value={
              formData.contactDateTime ? moment(formData.contactDateTime) : ""
            }
            onChange={(date) => handleDateChange(date, "contactDateTime")}
          />
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="vesselId">
            Vessel Name <span className="required">*</span>
          </label>
          <select
            className={`form-control modalDisplay ${
              errors?.vesselId ? "is-invalid" : ""
            }`}
            id="vesselId"
            value={formData.vesselId}
            onChange={handleNameChange}
          >
            <option value="">Select Vessel</option>
            {vesselNames
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((vessel, index) => (
                <option key={index} value={vessel.id}>
                  {vessel.name}
                </option>
              ))}
          </select>
          {errors?.vesselId && (
            <div className="invalid-feedback">{errors?.vesselId}</div>
          )}
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="berthName">
            Berth Name <span className="required">*</span>
          </label>
          <select
            className={`form-control modalDisplay ${
              errors?.berthName ? "is-invalid" : ""
            }`}
            id="berthName"
            value={formData.berthName}
            onChange={handleForBerthChange}
            disabled={formData.status === "CANCELLED"}
          >
            <option value="">Select Berth</option>
            {berthNames.map((name, index) => (
              <option key={index} value={name.name}>
                {name.name}
              </option>
            ))}
          </select>
          {errors?.berthName && (
            <div className="invalid-feedback">{errors.berthName}</div>
          )}
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="vesselOrientation">
            Orientation
          </label>
          <select
            className={`form-control modalDisplay ${
              errors?.vesselOrientation ? "is-invalid" : ""
            }`}
            id="vesselOrientation"
            value={formData.vesselOrientation}
            onChange={handleChange}
          >
            <option value="">Select Orientation</option>
            <option value="PORT">PORT</option>
            <option value="STARBOARD">STARBOARD</option>
          </select>
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="status">
            Status
          </label>
          <select
            className={`form-control modalDisplay ${
              errors?.status ? "is-invalid" : ""
            }`}
            id="status"
            value={formData.status}
            onChange={handleChange}
          >
            <option value="">Select Status</option>
            <option>TENTATIVE</option>
            <option>CONFIRMED</option>
            <option>CANCELLED</option>
          </select>
        </div>

        {isStJohn ? (
          <>
            {/* Mooring Date and Time */}
            <div className="col-md-6">
              <label className="modalLables" htmlFor="mooringDate">
                Mooring Date <span className="required">*</span>
              </label>
              <ReactDatetimeClass
                timeFormat={false}
                className={`form-control modalDisplay ${
                  errors?.mooringDateTime ? "is-invalid" : ""
                }`}
                value={
                  hvvWInfo?.mooringDate
                    ? moment(hvvWInfo.mooringDate, "YYYY-MM-DD").toDate()
                    : ""
                }
                onChange={(date) => {
                  if (date === null) {
                    return;
                  }
                  const localDate = moment(date).format();
                  handleHvvwDateChange(localDate, "mooringDate");
                }}
              />
              {hvvWInfo && hvvWInfo.mooringDate && (
                <>
                  <label className="modalLables" htmlFor="mooringTime">
                    Mooring Time <span className="required">*</span>
                  </label>
                  <fieldset disabled>
                    <ReactDatetimeClass
                      dateFormat={false}
                      timeFormat="hh:mm A"
                      className={`form-control ${
                        errors?.mooringTime ? "is-invalid" : ""
                      }`}
                      value={hvvWInfo?.mooringTime}
                      onChange={(date) => {
                        if (date === null) {
                          return;
                        }
                        const localDate = moment(date).format("hh:mm A");
                        handleHvvwDateChange(localDate, "mooringTime");
                      }}
                    />
                  </fieldset>
                </>
              )}
            </div>

            {/* Departure Date and Time */}
            {hvvWInfo && hvvWInfo.mooringTime && (
              <div className="col-md-6">
                <label className="modalLables" htmlFor="departureDate">
                  Departure Date <span className="required">*</span>
                </label>
                <ReactDatetimeClass
                  timeFormat={false}
                  className={`form-control modalDisplay ${
                    errors?.departureDateTime ? "is-invalid" : ""
                  }`}
                  value={
                    hvvWInfo?.departureDate
                      ? moment(hvvWInfo.departureDate, "YYYY-MM-DD").toDate()
                      : ""
                  }
                  onChange={(date) => {
                    if (date === null) {
                      return;
                    }
                    const localDate = moment(date).format();
                    handleHvvwDateChange(localDate, "departureDate");
                  }}
                />
                {hvvWInfo && hvvWInfo.departureDate && (
                  <>
                    <label className="modalLables" htmlFor="departureTime">
                      Departure Time <span className="required">*</span>
                    </label>
                    <fieldset disabled>
                      <ReactDatetimeClass
                        dateFormat={false}
                        timeFormat="hh:mm A"
                        className={`form-control ${
                          errors?.departureTime ? "is-invalid" : ""
                        }`}
                        value={hvvWInfo?.departureTime}
                        onChange={(date) => {
                          if (date === null) {
                            return;
                          }
                          const localDate = moment(date).format("hh:mm A");
                          handleHvvwDateChange(localDate, "departureTime");
                        }}
                      />
                    </fieldset>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {/* When HWWV is false, show combined date and time */}
            <div className="col-md-6">
              <label className="modalLables" htmlFor="mooringDateTime">
                Mooring Date and Time <span className="required">*</span>
              </label>
              <DateTime
                className={`form-control modalDisplay ${
                  errors?.mooringDateTime ? "is-invalid" : ""
                }`}
                value={
                  formData.mooringDateTime
                    ? moment(formData.mooringDateTime)
                    : ""
                }
                onOpen={() => {
                  if (isEditing) {
                    const portID =
                      sessionStorage.getItem("userPortId") ||
                      localStorage.getItem("userPortId") ||
                      "";
                    const isStJohn = portID === "2";
                    setStJohn(isStJohn);
                  }
                }}
                onChange={(date) => {
                  handleDateChange(date, "mooringDateTime");
                }}
              />
            </div>

            <div className="col-md-6">
              <label className="modalLables" htmlFor="departureDateTime">
                Departure Date and Time <span className="required">*</span>
              </label>
              <DateTime
                className={`form-control modalDisplay ${
                  errors?.departureDateTime ? "is-invalid" : ""
                }`}
                value={
                  formData.departureDateTime
                    ? moment(formData.departureDateTime)
                    : ""
                }
                onOpen={() => {
                  if (isEditing) {
                    const portID =
                      sessionStorage.getItem("userPortId") ||
                      localStorage.getItem("userPortId") ||
                      "";
                    const isStJohn = portID === "2";
                    setStJohn(isStJohn);
                  }
                }}
                onChange={(date) => handleDateChange(date, "departureDateTime")}
              />
            </div>
          </>
        )}

        <div className="col-md-6">
          <label className="modalLables" htmlFor="agent">
            Passengers
          </label>
          <input
            type="number"
            className="form-control"
            id="passengers"
            placeholder="passengers"
            value={formData.passengers}
            onChange={handleChange}
          />
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="agent">
            Crew
          </label>
          <input
            type="number"
            className="form-control"
            id="crew"
            placeholder="crew"
            value={formData.crew}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6">
          <label className="modalLables" htmlFor="specialNote">
            Booking Note
          </label>
          <textarea
            className="form-control modalDisplay"
            id="specialNote"
            maxLength={500}
            value={formData.specialNote || ""}
            onChange={handleChange}
            placeholder="Booking Note"
          />
        </div>

        <div className="col-md-6">
          <label className="modalLables" htmlFor="vesselNote">
            Vessel Note
          </label>
          <textarea
            className="form-control modalDisplay"
            id="vesselNote"
            maxLength={500}
            value={formData.vesselNote || ""}
            onChange={handleChange}
            placeholder="Vessel Note"
          />
        </div>
        <div className="col-md-6">
          <label className="modalLables" htmlFor="pax">
            Esitimated Passengers
          </label>
          <input
            type="number"
            className="form-control"
            id="pax"
            value={vessel?.pax || ""}
            disabled={true}
          />
        </div>
        <div className="col-md-6">
          <label className="modalLables" htmlFor="cre">
            Esitimated Crew
          </label>
          <input
            type="number"
            className="form-control"
            id="cre"
            value={vessel?.crew || ""}
            disabled={true}
          />
        </div>
        <div className="col-md-6">
          <label className="modalLables" htmlFor="tonnage">
            Tonnage
          </label>
          <input
            type="number"
            className="form-control"
            id="tonnage"
            value={vessel?.tonnage || ""}
            disabled={true}
          />
        </div>
        <div className="col-md-6">
          <label className="modalLables" htmlFor="length">
            Lenght
          </label>
          <input
            type="number"
            className="form-control"
            id="length"
            value={vessel?.length || ""}
            disabled={true}
          />
        </div>
        {formData.status === "CANCELLED" && (
          <div className="col-md-6">
            <label className="modalLables" htmlFor="cancellationReason">
              Cancellation Reason
            </label>
            <input
              type="text"
              className="form-control modalDisplay"
              id="cancellationReason"
              value={formData.cancellationReason || ""}
              onChange={handleChange}
              placeholder="Cancellation Reason"
            />
          </div>
        )}
        <BookingButtons
          booking={booking}
          showDeleteModal={handleSetDeleteModal}
          handleCloseModal={handleCloseModal}
        />
      </form>

      <div
        className={`modal fade ${showDeleteModal ? "show" : ""}`}
        style={{ display: showDeleteModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="modalCancel"
                onClick={handleCloseDeleteModal}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to delete this booking? This will delete
                all booking resources associated with this booking.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn deleteBooking"
                onClick={deleteBookingData}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn cancelBooking"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {showMooringTide && !isHwwv && (
        <NonHvvwCustomTides
          TideCustomTooltip={TideCustomTooltipDate}
          tideSixHourData={topSixMooringTides}
          tideData={mooringTideData}
          setDate={setMooringDate}
          setTime={setMooringTime}
          setCloseGraphModal={setShowMooringTide}
          channelDepth={channelDepth}
          shipDraft={shipDraft}
          title="Mooring"
          vesselName={vesselName}
          hvvwInformation={hvvWInfo}
          setHvvWInformation={setHvvWInformation}
        />
      )}

      {showDepartureTide && !isHwwv && (
        <NonHvvwCustomTides
          TideCustomTooltip={TideCustomTooltipDate}
          tideSixHourData={topSixDepartureTides}
          tideData={departureTideData}
          setDate={setDepartureDate}
          setTime={setDepartureTime}
          setCloseGraphModal={setShowDepartureTide}
          channelDepth={channelDepth}
          shipDraft={shipDraft}
          title="Departure"
          vesselName={vesselName}
          hvvwInformation={hvvWInfo}
          setHvvWInformation={setHvvWInformation}
        />
      )}

      {showMooringTide && isHwwv && (
        <HvvvWCustomTides
          TideCustomTooltip={TideCustomTooltipDate}
          tideSixHourData={topSixMooringTides}
          tideData={mooringTideData}
          setDate={setMooringDate}
          setTime={setMooringTime}
          setCloseGraphModal={setShowMooringTide}
          channelDepth={channelDepth}
          shipDraft={shipDraft}
          title="Mooring"
          vesselName={vesselName}
          hvvwInformation={hvvWInfo}
          setHvvWInformation={setHvvWInformation}
        />
      )}

      {showDepartureTide && isHwwv && (
        <HvvvWCustomTides
          TideCustomTooltip={TideCustomTooltipDate}
          tideSixHourData={topSixDepartureTides}
          tideData={departureTideData}
          setDate={setDepartureDate}
          setTime={setDepartureTime}
          setCloseGraphModal={setShowDepartureTide}
          channelDepth={channelDepth}
          shipDraft={shipDraft}
          title="Departure"
          vesselName={vesselName}
          hvvwInformation={hvvWInfo}
          setHvvWInformation={setHvvWInformation}
        />
      )}

      {isLoading && <LoadingScreen />}
    </div>
  );
}

export default OperationsBookingsModal;

const CustomLegend = () => {
  // Define the legend items manually
  const legendItems = [
    // { color: "red", label: "UKC" },
    // { color: "blue", label: "POB (Arrival & Departure)" },
    { color: "#FFD700", label: "Windows (Navigation Opportunity)" }, // Gold
    { color: "#1E90FF", label: "Tide Height" }, // Sky Blue
    { color: "#66BB6A", label: "Safe Zone" }, // Sky Blue
    { color: "#EF5350", label: "Unsafe Zone" }, // Sky Blue
    // { color: "green", label: "Length of stay" }, // Sky Blue
    // { color: "#32CD32", label: "Vessel (Mooring & Departure)" }, // Sky Blue
  ];

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
      {legendItems.map((item, index) => (
        <div
          key={`legend-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: 15,
            fontSize: 14,
          }}
        >
          {/* Legend color indicator */}
          <div
            style={{
              width: 12,
              height: 4,
              backgroundColor: item.color,
              marginRight: 5,
              borderRadius: 2,
            }}
          />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { FaBookOpen, FaShip, FaMap, FaBars } from "react-icons/fa";
import { MdLogin, MdLogout } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import Hamburger from "hamburger-react";
import msalInstance from "Config/MsalConfig";

const OperationHeader = React.memo(() => {
  const navigate = useNavigate();

  const getToken = () =>
    localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
  const [authenticated, setAuthenticated] = useState(!!getToken());

  const getUserRole = () =>
    localStorage.getItem("userRole") || sessionStorage.getItem("userRole");

  const handleLogout = () => {
    // Clear custom session/local storage items first
    localStorage.removeItem("jwtToken");
    sessionStorage.removeItem("jwtToken");
    localStorage.removeItem("userRole");
    sessionStorage.removeItem("userRole");
    sessionStorage.removeItem("userPortId");

     const redirectUrl =
       process.env.NODE_ENV === "production"
         ? "https://www.portcon.ca"
         : "http://localhost:3000/login";

    // Perform logout with redirect
    msalInstance
      .logoutRedirect({
        postLogoutRedirectUri: redirectUrl,
      })
      .catch((error) => {
        console.error("Error logging out", error);
      });
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleNavClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the collapse from closing when clicking on a navigation button
    e.stopPropagation();
  };

  const [isOpen, setOpen] = useState(false); // Track whether the hamburger menu is open

  return (
    <nav className="navbar transparentHeader">
      {/* Hamburger button */}
      <Hamburger
        toggled={isOpen}
        toggle={setOpen}
        size={24}
      />

      {/* Menu items that appear when hamburger is clicked */}
      {isOpen && (
        <div >
          <ul className="navbar-nav">
            {authenticated ? (
              <>
                <li className="nav-item">
                  <button
                    data-tooltip-id="header2Icons"
                    data-tooltip-content="Bookings Page"
                    className="btn navBookings"
                    onClick={(e) => {
                      handleNavClick(e);
                      navigate("/bookings");
                    }}
                  >
                    <FaBookOpen className="bookingsIcon" />
                  </button>
                </li>
                {getUserRole() === "Admin" && (
                  <li className="nav-item">
                    <button
                      data-tooltip-id="header2Icons"
                      data-tooltip-content="Vessels Page"
                      className="btn navVessels"
                      onClick={(e) => {
                        handleNavClick(e);
                        navigate("/Vessels");
                      }}
                    >
                      <FaShip className="vesselsIcon" />
                    </button>
                  </li>
                )}
                <li className="nav-item">
                  <button
                    data-tooltip-id="header2Icons"
                    data-tooltip-content="Operations Map Page"
                    className="btn navOperationsMap"
                    onClick={(e) => {
                      handleNavClick(e);
                      navigate("/operations");
                    }}
                  >
                    <FaMap className="operationsMapIcon" />
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    data-tooltip-id="header2Icons"
                    data-tooltip-content="Logout"
                    className="btn logout"
                    type="button"
                    onClick={(e) => {
                      handleNavClick(e);
                      handleLogout();
                    }}
                  >
                    <MdLogout className="logoutIcon" />
                  </button>
                </li>
              </>
            ) : (
              <li className="nav-item">
                <button
                  className="btn login"
                  type="button"
                  onClick={(e) => {
                    handleNavClick(e);
                    handleLogin();
                  }}
                >
                  <MdLogin className="loginIcon" />
                </button>
              </li>
            )}
          </ul>
        </div>
      )}

      {/* Tooltip for icons */}
      <Tooltip id="header2Icons" place="top" className="header2Tooltip" />
    </nav>
  );
});

export default OperationHeader;

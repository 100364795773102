import { useState, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { FaSort } from "react-icons/fa";
import { FaRegSquarePlus } from "react-icons/fa6";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { FaFolder } from "react-icons/fa";
import moment from "moment";
import "moment-timezone";
import { Tooltip } from "react-tooltip";
import { BookingResource } from "../../../Models/Booking";
import OperationsBookingsModal from "./OperationsBookingModal";
import { Columns } from "../CustomViews/Columns";
import { EnrichedBooking } from "Models/VesselDoorDTO";
import { Refresh } from "Pages/Operation/Operation";


interface OperationsMapBookingTableProps {
  bookings: EnrichedBooking[];
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  showReportForDay: () => void;
  setRefresh: React.Dispatch<React.SetStateAction<Refresh>>;
}

/**
 * OperationsBookingTable component displays and manages booking operations.
 * It allows users to select a date, view bookings, add new bookings, and generate reports.
 *
 * @param {OperationsMapBookingTableProps} props - The component properties.
 * @returns {JSX.Element} The rendered OperationsBookingTable component.
 */
function OperationsBookingTable({
  bookings,
  selectedDate,
  setSelectedDate,
  showReportForDay,
  setRefresh,
}: OperationsMapBookingTableProps) {
  // State variables for managing bookings, modals, and date selection.
  const [selectedBooking, setSelectedBooking] =
    useState<EnrichedBooking | null>(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [componentDate, setComponentDate] = useState(
    moment(selectedDate).format("MM/DD/YYYY")
  );

  /**
   * Fetches bookings, tides, and resources for the given date.
   *
   * @param {string} date - The date to fetch data for.
   */
  const handleDateChange = async (date: string) => {
    setSelectedDate(date);
    setRefresh({ refresh: true, showLoading: true });
  };

  /**
   * Opens the update modal for the selected booking.
   *
   * @param {EnrichedBooking} booking - The booking to update.
   */
  const handleBookingSelect = (booking: EnrichedBooking) => {
    setSelectedBooking(booking);
    setShowUpdateModal(true);
  };

  /**
   * Opens the add booking modal.
   */
  const handleAddBooking = () => {
    setSelectedBooking(null);
    setShowAddModal(true);
  };

  /**
   * Clears the selected booking.
   */
  const clearSelection = () => {
    setSelectedBooking(null);
  };

  /**
   * Closes the modal and refreshes bookings if a new date is provided.
   *
   * @param {string} [newDate] - The new date to refresh bookings with.
   */
  const closeModal = (newDate?: string) => {
    if (newDate) {
      setComponentDate(moment(newDate).format("MM/DD/YYYY"));
      handleDateChange(newDate);
       setRefresh({ refresh: true, showLoading: true });
    }
    setShowUpdateModal(false);
    setShowAddModal(false);
  };

  // Memoized columns for react-table.
  const columns = useMemo(() => Columns, []);

  // React-table setup.
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      { columns, data: bookings },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

  return (
    <div className="operationsPictureBookingTopContainer">
      <div>
        <div className="operationsPictureBookingTopContainerInside">
          <button
            className="btn today"
            onClick={() => {
              const today = new Date();
              const localDate = moment(today).format();
              setSelectedDate(localDate);
              setComponentDate(moment(today).format("MM/DD/YYYY"));
              handleDateChange(localDate);
            }}
          >
            Today
          </button>
          <label className="bookingLabels" htmlFor="selectedDate">
            Select Date:
          </label>
          <DateTime
            className="selectedDate"
            value={componentDate}
            onChange={(date) => {
              if (date === null) {
                return;
              }
              const localDate = moment(date).format();
              setSelectedDate(localDate);
              setComponentDate(moment(date).format("MM/DD/YYYY"));
              handleDateChange(localDate);
            }}
            inputProps={{ placeholder: "Select Date", readOnly: true }}
            timeFormat={false}
            closeOnSelect={true}
          />
          <button
            data-tooltip-id="operationsMapPageIcons"
            data-tooltip-content="View Report"
            data-html2canvas-ignore
            className="btn addToReports"
            onClick={() => {
              showReportForDay();
            }}
          >
            <FaFolder className="addToReportsIcon" />
          </button>
          <button
            data-tooltip-id="operationsMapPageIcons"
            data-tooltip-content="Add New Booking"
            className="btn addBooking"
            onClick={() => handleAddBooking()}
          >
            <FaRegSquarePlus className="addBookingIcon" />
          </button>
        </div>
        <div className="operationsPictureBookingTopContainerInside">
          <div className="operationsPictureBookingTopContainerInterior"></div>

          <div className="operationsPictureBookingTopContainerInterior">
            <>
              {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/> */}
            </>
          </div>
        </div>
      </div>
      <Tooltip
        id="operationsMapPageIcons"
        place="left"
        data-html2canvas-ignore
        className="operationsMapTableTooltip"
      />
      <div className="operationsPictureTableContainer">
        <table className="table table-hover" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              const { key: headerGroupKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr {...headerGroupProps} key={headerGroupKey}>
                  {headerGroup.headers.map((column) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        className="operationsPictureBookingTh"
                        key={columnKey}
                        {...columnProps}
                      >
                        {column.render("Header")}
                        {headerGroup.headers[headerGroup.headers.length - 1] !==
                          column && (
                          <span style={{ marginLeft: "5px" }}>
                            {" "}
                            <FaSort />{" "}
                          </span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { key, ...rowProps } = row.getRowProps();
              return (
                <tr
                  key={row.id}
                  {...rowProps}
                  onClick={() => handleBookingSelect(row.original)}
                >
                  {row.cells.map((cell) => {
                    const { key, ...cellProps } = cell.getCellProps();
                    return (
                      <td
                        key={key}
                        className="operationsPictureBookingTd"
                        {...cellProps}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showUpdateModal && (
        <OperationsBookingsModal
          booking={selectedBooking}
          closeModal={closeModal}
          clearSelections={clearSelection}
          selectedDate={selectedDate}
        />
      )}
      {showAddModal && (
        <OperationsBookingsModal
          booking={null}
          closeModal={closeModal}
          clearSelections={clearSelection}
          selectedDate={selectedDate}
        />
      )}
    </div>
  );
}

export default OperationsBookingTable;

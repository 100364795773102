import React, { useEffect, useRef, useState } from "react";
import { Line, Transformer, Group, Rect } from "react-konva";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { PlatformDTO } from "Models/PlatformDTO";
import { GangwayDTO } from "Models/GangwayDTO";
import { BookingResource } from "Models/Booking";
import Konva from "konva";
import { Box } from "konva/lib/shapes/Transformer";
import PortResourcesPlatform from "./PortResourcesPlatform";
import {
  ResourceInformationComponentProps,
} from "../OperationMap/OperationsMapContainer";

interface PortResourcesProps {
  gangways: GangwayDTO[];
  platforms: PlatformDTO[];
  bookingResources: BookingResource[];
  selectedLineId?: number | null;
  setSelectedLineId?: (id: number | null) => void;
  transformerRef?: React.RefObject<Konva.Transformer> | null;
  imageWidth: number;
  imageHeight: number;
  setPortResourcesPositionAndRotation?: React.Dispatch<
    React.SetStateAction<Record<number, ResourceInformationComponentProps>>
  >;
  portResourcesPositionAndRotation?: Record<
    number,
    ResourceInformationComponentProps
  >;
}

export const PortResources = ({
  gangways,
  platforms,
  bookingResources,
  selectedLineId,
  setSelectedLineId,
  transformerRef,
  imageWidth,
  imageHeight,
  setPortResourcesPositionAndRotation,
  portResourcesPositionAndRotation,
}: PortResourcesProps) => {
  const shapeRefs = useRef<{ [key: number]: Konva.Node | null }>({});
  const groupRef = useRef(null);
  const [currentRotation, setCurrentRotation] = useState<{
    [key: number]: number;
  }>({});
  const [generatePlatform, setGeneratePlatform] = useState(false);

  // this is hardcoded for Charlottetown and needs to be replaced
  // useEffect(() => {
  //   if (bookingResources.find((res) => res.gangwayId === 5)) {
  //     setGeneratePlatform(true);
  //   }
  // }, [bookingResources, platforms]);

  // useEffect(() => {}, [generatePlatform]);

  useEffect(() => {
    if (bookingResources.length > 0) {
      bookingResources.forEach((resource, index) => {
        setCurrentRotation((prevState) => ({
          ...prevState,
          [resource.id]: resource.rotation ?? resource.defaultRotation ?? 0,
        }));
      });
    }
  }, [bookingResources, portResourcesPositionAndRotation]);

  const handleSelect = (gangwayId: number) => {
    if (selectedLineId === gangwayId) {
      setSelectedLineId && setSelectedLineId(null);
      if (transformerRef && transformerRef.current) {
        transformerRef.current.nodes([]);
        transformerRef.current.getLayer()?.batchDraw();
      }
    } else {
      setSelectedLineId && setSelectedLineId(gangwayId);
      setTimeout(() => {
        if (shapeRefs.current && shapeRefs.current[gangwayId]) {
          if (transformerRef && transformerRef.current) {
            transformerRef.current.nodes([shapeRefs.current[gangwayId]]);
            transformerRef.current?.getLayer()?.batchDraw();
          }
        }
      }, 0); // Delay to allow state update
    }
  };

  const boundBoxFunc = (oldBox: Box, newBox: Box) => {
    return {
      ...oldBox,
      rotation: newBox.rotation,
      width: oldBox.width,
      height: oldBox.height,
    };
  };

  const handleDragEnd = (
    gangwayId: number,
    bookingResourceId: number,
    bookingId: number,
    gangwayLengthFactor: number,
    gangwayColorDark: string,
    e: Konva.KonvaEventObject<DragEvent>
  ) => {
    const shape = e.target; // Get the dragged shape (now the Group)
    const absolutePos = shape.getAbsolutePosition(); // Now referencing the Group, not the Line

    setSelectedLineId && setSelectedLineId(null);
    const currentRot = currentRotation[bookingResourceId] || 0;
    //const newRotation = currentRot + shape.rotation();
    const newRotation = shape.rotation(); // Directly use Group's rotation

    const newPosition: ResourceInformationComponentProps = {
      bookingId: bookingId,
      x: absolutePos.x,
      y: absolutePos.y,
      rotation: newRotation,
      gangwayId: gangwayId,
      gangwayLengthFactor: gangwayLengthFactor,
      gangwayColorDark: gangwayColorDark,
    };

    setPortResourcesPositionAndRotation &&
      setPortResourcesPositionAndRotation((prevState) => ({
        ...prevState,
        [bookingResourceId]: newPosition,
      }));
  };

  const handleTransformEnd = (e: Konva.KonvaEventObject<Event>) => {
    setSelectedLineId && setSelectedLineId(null);

    const shape = e.target; // Now the transformed shape is the Group
    const absolutePos = shape.getAbsolutePosition(); // Correct absolute position

    // Find the corresponding booking resource
    const bookingResourceId = Object.keys(shapeRefs.current).find(
      (key) => shapeRefs.current[Number(key)] === shape
    );

    if (!bookingResourceId) return;

    const numericBookingResourceId = Number(bookingResourceId);
    const resource = bookingResources.find(
      (res) => res.id === numericBookingResourceId
    );

    if (resource) {
      const newRotation = shape.rotation(); // Directly use Group's rotation
      const newPosition: ResourceInformationComponentProps = {
        bookingId: resource.bookingId,
        x: absolutePos.x,
        y: absolutePos.y,
        rotation: newRotation,
        gangwayId: resource.gangwayId || 0,
        gangwayLengthFactor: resource.gangwayLengthFactor || 0,
        gangwayColorDark: resource.gangwayColorDark || "",
      };
      setPortResourcesPositionAndRotation &&
        setPortResourcesPositionAndRotation((prevState) => ({
          ...prevState,
          [bookingResourceId]: newPosition,
        }));
    }
  };

  return (
    <>
      {portResourcesPositionAndRotation &&
        Object.entries(portResourcesPositionAndRotation).map(([key, value]) => {
          const resource = bookingResources.find(res => res.id === Number(key));
          return (
            <Group
              key={key}
              x={value.x}
              y={value.y}
              rotation={value.rotation ?? 0}
              draggable
              ref={(el) => {
                shapeRefs.current[Number(key)] = el;
              }}
              onClick={() => handleSelect(Number(key))}
              onDragEnd={(e) =>
                handleDragEnd(
                  value.gangwayId ?? 0,
                  Number(key),
                  value.bookingId,
                  value.gangwayLengthFactor ?? 0,
                  value.gangwayColorDark ?? "NULL",
                  e
                )
              }
            >
              {resource?.platformId && (
                <PortResourcesPlatform
                  xPosition={-5.5}
                  yPosition={-8}
                  color={resource.platformColorDark || "blue"}
                  squareSize={resource.platformSquareSizeFactor || 11} />
              )}
              <Line
                points={[0, 0, 0, value.gangwayLengthFactor || 0]}
                stroke={value.gangwayColorDark || "blue"}
                strokeWidth={4}
              />
            </Group>
          );
        })}
      <Transformer
        ref={transformerRef}
        boundBoxFunc={boundBoxFunc}
        onTransformEnd={(e) => handleTransformEnd(e)}
        rotateEnabled={true}
        enabledAnchors={[]}
      />
    </>
  );
};

import { TideDTO } from "Models/TideDTO";
import moment from "moment";

export const getColorBasedOnValue = (
  value: number,
  positiveSlopeCaution: number,
  positiveSlopeAcceptable: number,
  negativeSlopeAcceptable: number,
  negativeSlopeCaution: number
) => {
  if (value >= positiveSlopeCaution) {
    return "#f4a6a6"; //red
  } else if (value >= positiveSlopeAcceptable && value < positiveSlopeCaution) {
    return "#ffeb99"; //yellow
  } else if (
    value > negativeSlopeAcceptable &&
    value < positiveSlopeAcceptable
  ) {
    return "#aad4aa"; //green
  } else if (value <= negativeSlopeAcceptable && value > negativeSlopeCaution) {
    return "#ffeb99"; //yellow
  } else {
    return "#f4a6a6"; //red
  }
};

export const getColorBasedOnValueForTide = (
  value: number,
  positiveSlopeCaution: number,
  positiveSlopeAcceptable: number,
  negativeSlopeAcceptable: number,
  negativeSlopeCaution: number
) => {
  if (value >= positiveSlopeCaution) {
    return "#f4a6a6"; //red
  } else if (value >= positiveSlopeAcceptable && value < positiveSlopeCaution) {
    return "#ffeb99"; //yellow
  } else if (
    value > negativeSlopeAcceptable &&
    value < positiveSlopeAcceptable
  ) {
    return "#aad4aa"; //green
  } else if (value <= negativeSlopeAcceptable && value > negativeSlopeCaution) {
    return "#ffeb99"; //yellow
  } else {
    return "#f4a6a6"; //red
  }
};

// Function to get the top tides (two lowest and two highest tides sorted by tideDateTime)
export const getTopTides = (tides: TideDTO[]) => {
  // Remove duplicates based on height and find local minima and maxima
  const { localMinima, localMaxima } = findLocalMinMax(tides);

  // Sort local minima (ascending) and local maxima (descending) by height
  const sortedLocalMinima = localMinima.sort((a, b) => a.height - b.height);
  const sortedLocalMaxima = localMaxima.sort((a, b) => b.height - a.height);

  // Get the two lowest and two highest points
  const lowestTwo = sortedLocalMinima.slice(0, 2);
  const highestTwo = sortedLocalMaxima.slice(0, 2);

  // Combine the highest and lowest tides and sort them by tideDateTime (ascending)
  return [...highestTwo, ...lowestTwo].sort((a, b) =>
    new Date(a.tideDateTime).getTime() - new Date(b.tideDateTime).getTime()
  );
};

// Function to find local minima and maxima
function findLocalMinMax(datas: TideDTO[]) {
  // Remove repeated data points based on height
  const uniqueData = datas.filter(
    (point, index, self) => index === 0 || point.height !== self[index - 1].height
  );

  const localMinima = [];
  const localMaxima = [];

  // Iterate over the unique data points to find local minima and maxima
  for (let i = 1; i < uniqueData.length - 1; i++) {
    const current = uniqueData[i];
    const previous = uniqueData[i - 1];
    const next = uniqueData[i + 1];

    // Local minimum condition
    if (current.height < previous.height && current.height < next.height) {
      localMinima.push(current);
    }

    // Local maximum condition
    if (current.height > previous.height && current.height > next.height) {
      localMaxima.push(current);
    }
  }

  return { localMinima, localMaxima };
}

// Function to get the top six highest tides sorted by tideDateTime
export const getTopSixTides = (tides: TideDTO[]) => {
  // Remove duplicates based on height and find local minima and maxima
  const { localMaxima } = findLocalMinMaxTopSixTides(tides);

  // Sort local maxima by height in descending order
  const sortedLocalMaxima = localMaxima.sort((a, b) => b.height - a.height);

  // Get the top six highest tides
  const highestSix = sortedLocalMaxima.slice(0, 6);

  // Sort the highest tides by tideDateTime (ascending)
  return highestSix.sort((a, b) =>
    new Date(a.tideDateTime).getTime() - new Date(b.tideDateTime).getTime()
  );
};

// Function to get the top six highest tides sorted by tideDateTime
export const getTopTwoTides = (tides: TideDTO[]) => {
  // Remove duplicates based on height and find local minima and maxima
  const { localMaxima } = findLocalMinMaxTopSixTides(tides);

  // Sort local maxima by height in descending order
  const sortedLocalMaxima = localMaxima.sort((a, b) => b.height - a.height);

  // Get the top six highest tides
  const highestSix = sortedLocalMaxima.slice(0, 2);

  // Sort the highest tides by tideDateTime (ascending)
  return highestSix.sort((a, b) =>
    new Date(a.tideDateTime).getTime() - new Date(b.tideDateTime).getTime()
  );
};

function findLocalMinMaxTopSixTides(datas: TideDTO[]) {
  const uniqueData = datas.filter(
    (point, index, self) =>
      index === 0 || point.height !== self[index - 1].height
  );

  const localMinima = [];
  const localMaxima = [];

  // Check first point
  if (uniqueData.length > 1 && uniqueData[0].height > uniqueData[1].height) {
    localMaxima.push(uniqueData[0]);
  }

  // Iterate over the unique data points to find local minima and maxima
  for (let i = 1; i < uniqueData.length - 1; i++) {
    const current = uniqueData[i];
    const previous = uniqueData[i - 1];
    const next = uniqueData[i + 1];

    // Local minimum condition
    if (current.height < previous.height && current.height < next.height) {
      localMinima.push(current);
    }

    // Local maximum condition
    if (current.height > previous.height && current.height > next.height) {
      localMaxima.push(current);
    }
  }

  // Check last point
  if (
    uniqueData.length > 1 &&
    uniqueData[uniqueData.length - 1].height >
      uniqueData[uniqueData.length - 2].height
  ) {
    localMaxima.push(uniqueData[uniqueData.length - 1]);
  }

  return { localMinima, localMaxima };
}

export const convertDateAndTimeFromLocal = (
      date: string,
      time: string,
      format: string = "YYYY-MM-DDTHH:mm:ssZ"
    ) => {
      if (!date || !time) {
        console.error("Invalid input: Date or time is missing");
        return null;
      }

      const localMoment = moment(`${date} ${time}`, "YYYY-MM-DD hh:mm A");

      if (!localMoment.isValid()) {
        console.error("Invalid moment object created:", localMoment);
        return null;
      }

      return localMoment.format(format);
    };
import React, { useState, useCallback, useEffect } from "react";
import { VesselDTO } from "../Models/VesselDTO";
import { useApi } from "../api/api";
import { BookingData } from "../Models/Booking";
import { VesselImageDTO } from "../Models/VesselImageDTO";
import { EnrichedBooking, VesselDoorDTO } from "../Models/VesselDoorDTO";
import { PlatformDTO } from "../Models/PlatformDTO";
import useImage from "use-image";
import { GangwayDTO } from "../Models/GangwayDTO";
import moment from "moment";
import { ChannelDepth, TideDTO } from "../Models/TideDTO";
import { MooringLineDTO } from "Models/MooringLine";

// Define the shape of the data returned from fetchBookingsWithDetails
export interface BookingsDetailsData {
  bookings: EnrichedBooking[];
  gangways: GangwayDTO[];
  platforms: PlatformDTO[];
}

export interface AllBookingData {
  bookings: BookingData[];
  gangways: GangwayDTO[];
  platforms: PlatformDTO[];
}

export interface MooringLineRequests {
  fetchMooringLines: () => Promise<MooringLineDTO[] | undefined>;
}

// Define the props that will be returned from this operation
interface UseBookingRequestOperationProps {
  backgroundImage: HTMLImageElement | undefined;
  fetchBookingsWithDetails: (date: string) => Promise<BookingsDetailsData>;
  fetchBookingsWithDetailsByYear: (
    year?: string
  ) => Promise<BookingsDetailsData>;
  fetchAllBookingsByDate: (date: string) => Promise<AllBookingData>;
  fetchAllBookingsByYear: (year?: string) => Promise<AllBookingData>;
  fetchTides: (date: string) => Promise<TideDTO[] | void>;
  fetchThreeDaysTides: (date: string) => Promise<TideDTO[] | void>;
  fetchChannelDepth: () => Promise<ChannelDepth[] | void>;
  loading: boolean;
}

const useBookingRequestOperation = (): UseBookingRequestOperationProps => {
  const userPortId =
    sessionStorage.getItem("userPortId") || localStorage.getItem("userPortId");
  const userRole =
    sessionStorage.getItem("userRole") || localStorage.getItem("userRole");

  // Manage the background image source using state.
  const [backgroundImageSrc, setBackgroundImageSrc] = useState<string>("");
  const [bookingDetailData, setBookingDetailData] =
    useState<BookingsDetailsData | null>(null);

  // Use useEffect to update the background image source when the userPortId changes.
  useEffect(() => {
    switch (userPortId) {
      case "1":
        setBackgroundImageSrc("/images/InUse/PortCharlottetown-fullhd.jpg");
        break;
      case "2":
        setBackgroundImageSrc("/images/InUse/PortSaintJohn-fullhd3.png");
        break;
      case "3":
        setBackgroundImageSrc("/images/InUse/PortCharlottetown-fullhd.jpg");
        break;
      case null:
        setBackgroundImageSrc(
          "/images/InUse/PortCharlottetown-fullhd-null.jpg"
        );
        break;
      case undefined:
        setBackgroundImageSrc(
          "/images/InUse/PortCharlottetown-fullhd-undefined.jpg"
        );
        break;
      default:
        setBackgroundImageSrc(
          "/images/InUse/PortCharlottetown-fullhd-default.jpg"
        );
        break;
    }
  }, [userPortId]);

  // Load the background image using the custom hook
  const [backgroundImage] = useImage(backgroundImageSrc);
  if (backgroundImage?.width !== undefined) {
    localStorage.setItem(
      "backgroundImageWidth",
      backgroundImage.width.toString()
    );
  }
  if (backgroundImage?.height !== undefined) {
    localStorage.setItem(
      "backgroundImageHeight",
      backgroundImage.height.toString()
    );
  }
  // Set up your API hooks
  const { request: bookingRequest } = useApi<BookingData[]>();
  const { request: vesselRequest } = useApi<VesselDTO>();
  const { request: imageRequest } = useApi<VesselImageDTO[]>();
  const { request: doorRequest } = useApi<VesselDoorDTO[]>();
  const { request: portResourceRequest } = useApi<GangwayDTO[]>();
  const { request: platFormRequest } = useApi<PlatformDTO[]>();
  const { request: tideRequest } = useApi<TideDTO[]>();
  const { loading, request: threeDaysTideRequest } = useApi<TideDTO[]>();
  const { request: channelDepthRequest } = useApi<ChannelDepth[]>();
  const { request: mooringLineRequest } = useApi<MooringLineDTO[]>();

  // Define fetchBookingsWithDetails to return data in the desired shape.
  const fetchBookingsWithDetails = useCallback(
    async (date: string): Promise<BookingsDetailsData> => {
      try {
        // Format the date to match the API requirements

        // 1. Fetch bookings, gangways, and platforms concurrently
        const [bookings, gangwayData, platformData] = await Promise.all([
          bookingRequest(`/Booking/date/${date}/portId/${userPortId}`, "GET"),
          portResourceRequest(`/Gangway/portId/${userPortId}`, "GET"),
          platFormRequest(`/Platform/portId/${userPortId}`, "GET"),
        ]);

        // 2. For each booking, fetch vessel details and its images
        const enrichedBookings = await Promise.all(
          bookings.map(async (booking) => {
            const { vesselId } = booking;

            const [vessel, images, doors] = await Promise.all([
              vesselRequest(`/Vessel/${vesselId}`, "GET"),
              imageRequest(`/VesselImage/vesselId/${vesselId}`, "GET"),
              doorRequest(`/VesselDoor/${vesselId}`, "GET"),
            ]);

            // Split doors into two categories based on DoorType
            const passengerDoors = doors.filter(
              (door) => door.doorType === "Passenger"
            );
            const shoreConnectionDoors = doors.filter(
              (door) => door.doorType === "Shore Connection"
            );

            return {
              ...booking,
              vessel: {
                ...vessel,
                images,
                doors,
                passengerDoors,
                shoreConnectionDoors,
              },
            } as EnrichedBooking;
          })
        );
        setBookingDetailData({
          bookings: enrichedBookings,
          gangways: gangwayData,
          platforms: platformData,
        });
        // Return the data in the defined shape
        return {
          bookings: enrichedBookings,
          gangways: gangwayData,
          platforms: platformData,
        };
      } catch (error) {
        console.error("Error fetching bookings with details:", error, date);
        // In case of error, return empty arrays
        return {
          bookings: [],
          gangways: [],
          platforms: [],
        };
      }
    },
    [
      bookingRequest,
      vesselRequest,
      imageRequest,
      doorRequest,
      portResourceRequest,
      platFormRequest,
      userPortId,
    ]
  );

  const fetchBookingsWithDetailsByYear = useCallback(
    async (year?: string): Promise<BookingsDetailsData> => {
      try {
        // Format the date to match the API requirements
        let url = "";
        if (year) {
          url =
            userRole === "Admin"
              ? `/Booking/year/${year}`
              : `/Booking/year/${year}/portId/${userPortId}`;
        } else {
          url = userRole === "Admin" ? `/Booking` : `/Booking/${userPortId}`;
        }

        // 1. Fetch bookings, gangways, and platforms concurrently
        const [bookings, gangwayData, platformData] = await Promise.all([
          bookingRequest(url, "GET"),
          portResourceRequest(`/Gangway/portId/${userPortId}`, "GET"),
          platFormRequest(`/Platform/portId/${userPortId}`, "GET"),
        ]);

        // 2. For each booking, fetch vessel details and its images
        const enrichedBookings = await Promise.all(
          bookings.map(async (booking) => {
            const { vesselId } = booking;

            const [vessel, images, doors] = await Promise.all([
              vesselRequest(`/Vessel/${vesselId}`, "GET"),
              imageRequest(`/VesselImage/vesselId/${vesselId}`, "GET"),
              doorRequest(`/VesselDoor/${vesselId}`, "GET"),
            ]);

            // Split doors into two categories based on DoorType
            const passengerDoors = doors.filter(
              (door) => door.doorType === "Passenger"
            );
            const shoreConnectionDoors = doors.filter(
              (door) => door.doorType === "Shore Connection"
            );
            return {
              ...booking,
              vessel: {
                ...vessel,
                images,
                doors,
                passengerDoors,
                shoreConnectionDoors,
              },
            } as EnrichedBooking;
          })
        );
        setBookingDetailData({
          bookings: enrichedBookings,
          gangways: gangwayData,
          platforms: platformData,

        });
        // Return the data in the defined shape
        return {
          bookings: enrichedBookings,
          gangways: gangwayData,
          platforms: platformData,

        };
      } catch (error) {
        console.error("Error fetching bookings with details:", error, year);
        // In case of error, return empty arrays
        return {
          bookings: [],
          gangways: [],
          platforms: [],
        };
      }
    },
    [
      bookingRequest,
      vesselRequest,
      imageRequest,
      doorRequest,
      portResourceRequest,
      platFormRequest,
      userPortId,
    ]
  );
  const fetchAllBookingsByYear = useCallback(
    async (year?: string): Promise<AllBookingData> => {
      try {
        // Format the date to match the API requirements
        let url = "";
        if (year) {
          url =
            userRole === "Admin"
              ? `/Booking/year/${year}`
              : `/Booking/year/${year}/portId/${userPortId}`;
        } else {
          url = userRole === "Admin" ? `/Booking` : `/Booking/${userPortId}`;
        }

        // 1. Fetch bookings, gangways, and platforms concurrently
        const [bookings, gangwayData, platformData, mooringLines] = await Promise.all([
          bookingRequest(url, "GET"),
          portResourceRequest(`/Gangway/portId/${userPortId}`, "GET"),
          platFormRequest(`/Platform/portId/${userPortId}`, "GET"),
          mooringLineRequest(`/MooringLine`, "GET")
        ]);
        const validMooringLines = Array.isArray(mooringLines) ? mooringLines : [];
        // Return the data in the defined shape
        return {
          bookings: bookings,
          gangways: gangwayData,
          platforms: platformData,
        };
      } catch (error) {
        console.error("Error fetching bookings with details:", error, year);
        // In case of error, return empty arrays
        return {
          bookings: [],
          gangways: [],
          platforms: [],
        };
      }
    },
    [
      bookingRequest,
      vesselRequest,
      imageRequest,
      doorRequest,
      portResourceRequest,
      platFormRequest,
      userPortId,
    ]
  );
  const fetchAllBookingsByDate = useCallback(
    async (date: string): Promise<AllBookingData> => {
      try {
        // Format the date to match the API requirements

        // 1. Fetch bookings, gangways, and platforms concurrently
        const [bookings, gangwayData, platformData, mooringLines] = await Promise.all([
          bookingRequest(`/Booking/date/${date}/portId/${userPortId}`, "GET"),
          portResourceRequest(`/Gangway/portId/${userPortId}`, "GET"),
          platFormRequest(`/Platform/portId/${userPortId}`, "GET"),
          mooringLineRequest(`/MooringLine`, "GET")
        ]);
        const validMooringLines = Array.isArray(mooringLines) ? mooringLines : [];
        // Return the data in the defined shape

        return {
          bookings: bookings,
          gangways: gangwayData,
          platforms: platformData,
        };
      } catch (error) {
        console.error("Error fetching bookings with details:", error, date);
        // In case of error, return empty arrays
        return {
          bookings: [],
          gangways: [],
          platforms: [],
        };
      }
    },
    [
      bookingRequest,
      vesselRequest,
      imageRequest,
      doorRequest,
      portResourceRequest,
      platFormRequest,
      userPortId,
    ]
  );
  const fetchTides = useCallback(
    async (date: string): Promise<TideDTO[] | void> => {
      try {
        const response = await tideRequest(
          `/Tide/date/${date}/portId/${userPortId}`,
          "GET"
        );
        return response;
      } catch (error) {
        console.error("Error fetching bookings with details:", error);
      }
    },
    [tideRequest]
  );

  const fetchThreeDaysTides = useCallback(
    async (date: string): Promise<TideDTO[] | void> => {
      try {
        const response = await threeDaysTideRequest(
          `/Tide/threeDays/date/${date}/portId/${userPortId}`,
          "GET"
        );
        return response;
      } catch (error) {
        console.error("Error fetching bookings with details:", error);
      }
    },
    [threeDaysTideRequest]
  );

  const fetchChannelDepth = useCallback(async (): Promise<
    ChannelDepth[] | void
  > => {
    try {
      const response = await channelDepthRequest(
        `/PortDetail/allPortDetailsForPort/${userPortId}`,
        "GET"
      );
      return response;
    } catch (error) {
      console.error("Error fetching bookings with details:", error);
    }
  }, [channelDepthRequest]);
  // Return the properties defined in UseBookingRequestOperationProps
  return {
    backgroundImage,
    fetchBookingsWithDetails,
    fetchBookingsWithDetailsByYear,
    fetchAllBookingsByDate,
    fetchAllBookingsByYear,
    fetchTides,
    fetchThreeDaysTides,
    fetchChannelDepth,
    loading,
  };

  // fetch all bookings
};

export default useBookingRequestOperation;

const Colors = {
  navigationWindow: "#FFD700",
  tideHeight: "#1E90FF",
  safeZone: "#66BB6A",
  unsafeZone: "#EF5350",
  redZone: "#EF5350",
  ukc: "red",
  pob: "blue",
  // safeZone: "#66BB6A",
};

export default Colors;
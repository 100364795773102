import { TideDTO } from "Models/TideDTO";
import moment, { Moment } from "moment";
import React, { useRef, ComponentType, useState, useEffect } from "react";
import Draggable from "react-draggable";
import { Circle } from "react-konva";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
  ReferenceLine,
  ReferenceDot,
  Legend,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/types";
import { HvvWVesselTime } from "../Bookings/OperationsBookingModal";
import { convertDateAndTimeFromLocal } from "../OperationUtils/OperationUtility";
import CustomLegend from "./CustomLegendHvvw";
import Colors from "Theme";

interface MyChartProps {
  /** The main tide data to be displayed as a line chart. */
  tideData: TideDTO[];
  /** The six-hour tide data used to calculate reference areas and lines. */
  tideSixHourData: TideDTO[];
  /** A custom tooltip component for the chart. */
  TideCustomTooltip: ComponentType<any>;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  setTime: React.Dispatch<React.SetStateAction<string>>;
  setCloseGraphModal: React.Dispatch<React.SetStateAction<boolean>>;
  channelDepth: number;
  shipDraft: number;
  title: string;
  vesselName: string;
  hvvwInformation: HvvWVesselTime | undefined;
  setHvvWInformation: React.Dispatch<
    React.SetStateAction<HvvWVesselTime | undefined>
  >;
}

interface ReferenceAreaItem {
  /** The start x-coordinate of the reference area. */
  x1: string;
  /** The end x-coordinate of the reference area. */
  x2: string;
  /** The fill color of the reference area. */
  fill: string;
}

interface DepartureDetails {
  popArrivalDate?: string;
  popDepartureDate?: string;
  newPopArrivalDate?: string;
  newPopDepartureDate?: string;
  vesselArrivalDate?: string;
  vesselDepartureDate?: string;
}

interface ArrivalDetails {
  popArrivalDate?: string;
  popDepartureDate?: string;
  vesselArrivalDate?: string;
  vesselDepartureDate?: string;
}

/**
 * CustomTides component displays a tide chart with alternating red and green
 * background areas and vertical reference lines.
 */
function NonHvvwCustomTides({
  tideData,
  tideSixHourData,
  TideCustomTooltip,
  setDate,
  setTime,
  setCloseGraphModal,
  channelDepth,
  shipDraft,
  title,
  vesselName,
  hvvwInformation,
  setHvvWInformation,
}: MyChartProps) {
  const ref = useRef<HTMLDivElement>(null);
  const referenceAreas: ReferenceAreaItem[] = [];
  let normalizedMooringTideData: { tideDateTime: string; height: number }[] =
    [];
  const [clickedX, setClickedX] = useState<string | null>(null);
  const [animationProgress, setAnimationProgress] = useState(0);
  const [lengthOfStay, setLengthOfStay] = useState<string>("");
  const [mooringDetails, setMooringDetails] = useState<ArrivalDetails | null>();
  const [departureDetails, setDepartureDetails] =
    useState<DepartureDetails | null>();
  const [height, setHeight] = useState<number>(0);
  const chartRef = useRef<any>(null); // Use ref to access the ComposedChart instance
  const [showNext, setShowNext] = useState(false); // State to control the visibility of the next line

  useEffect(() => {
    if (hvvwInformation) {
      const arriveDate = hvvwInformation.mooringDate ?? "";
      const arriveTime = hvvwInformation.mooringTime ?? "";
      const pobHvvWArrivalDate = hvvwInformation.pobArrivalDate ?? "";
      const pobHvvWArrivalTime = hvvwInformation.pobArrivalTime ?? "";
      const pobHvvWDepartureDate = hvvwInformation.pobDepartureDate ?? "";
      const pobHvvWDepartureTime = hvvwInformation.pobDepartureTime ?? "";

      // vessel Arrival time
      const vesselArrivalTime = convertDateAndTimeFromLocal(
        arriveDate,
        arriveTime
      );

      // pob arrival time
      const pobArrivalTime = convertDateAndTimeFromLocal(
        pobHvvWArrivalDate,
        pobHvvWArrivalTime
      );

      // pob departure time
      const pobDepartureTime = convertDateAndTimeFromLocal(
        pobHvvWDepartureDate,
        pobHvvWDepartureTime
      );

      const departureDetails = {
        popArrivalDate: pobArrivalTime ?? "",
        popDepartureDate: pobDepartureTime ?? "",
        vesselArrivalDate: vesselArrivalTime ?? "",
      };
      setDepartureDetails({ ...departureDetails });
      setHeight(5);
    }
  }, []);

  // Check if both data arrays are populated
  if (tideData.length > 0 && tideSixHourData.length > 0) {
    const lengthOfStayHoursAndMinutes = (
      vesselDepartureTime: string,
      vesselArrivalTime: string
    ) => {
      const diffMilliseconds = moment(vesselDepartureTime).diff(
        moment(vesselArrivalTime)
      );
      const duration = moment.duration(diffMilliseconds);

      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      if (minutes === 0) {
        return `${hours} hours`;
      } else {
        return `${hours} hours and ${minutes} minutes`;
      }
    };

    const calculateMinTideHt = (percentageUKC: boolean = false): number => {
      let result = 0;

      if (percentageUKC) {
        // 10% UKC calculation
        result = shipDraft * 0.1 - channelDepth;
      } else {
        // 2m UKC calculation
        result = shipDraft + 2 - channelDepth;
      }
      // Round the result to 2 decimal places
      result = Math.round(result * 100) / 100;

      return result;
    };

    // Normalize mooring tide data timestamps to formatted strings
    normalizedMooringTideData = tideData.map((item) => ({
      ...item,
      tideDateTime: moment(item.tideDateTime).format(),
    }));

    const expandedTideData = () => {
      const intersections: {
        height: number;
        tideDateTime: string;
      }[] = [];
      const referenceYValue = calculateMinTideHt(); // 2m UKC line

      for (let i = 0; i < normalizedMooringTideData.length - 1; i++) {
        const currentPoint = normalizedMooringTideData[i];
        const nextPoint = normalizedMooringTideData[i + 1];

        if (
          (currentPoint.height <= referenceYValue &&
            nextPoint.height >= referenceYValue) ||
          (currentPoint.height >= referenceYValue &&
            nextPoint.height <= referenceYValue)
        ) {
          intersections.push({
            height: currentPoint.height,
            tideDateTime: currentPoint.tideDateTime,
          }); // Store the x value of the first point of the intersection.
        }
      }
      return intersections;
    };

    // Calculate reference areas for alternating red and green backgrounds
    if (expandedTideData().length > 0) {
      referenceAreas.push({
        x1: normalizedMooringTideData[0].tideDateTime,
        x2: expandedTideData()[0].tideDateTime,
        fill: Colors.safeZone, // red
      });
      for (let i = 0; i < expandedTideData().length; i += 2) {
        if (i + 1 < expandedTideData().length) {
          referenceAreas.push({
            x1: expandedTideData()[i].tideDateTime,
            x2: expandedTideData()[i + 1].tideDateTime,
            fill: Colors.redZone, //red
          });

          if (i + 2 < expandedTideData().length) {
            referenceAreas.push({
              x1: expandedTideData()[i + 1].tideDateTime,
              x2: expandedTideData()[i + 2].tideDateTime,
              fill: Colors.safeZone, // red
            });
          } else {
            referenceAreas.push({
              x1: expandedTideData()[i + 1].tideDateTime,
              x2: normalizedMooringTideData[
                normalizedMooringTideData.length - 1
              ].tideDateTime,
              fill: Colors.safeZone, // red
            });
          }
        }
      }
    } else {
      referenceAreas.push({
        x1: normalizedMooringTideData[0].tideDateTime,
        x2: normalizedMooringTideData[normalizedMooringTideData.length - 1]
          .tideDateTime,
        fill: Colors.safeZone, // red
      });
    }

    // get the hightst height
    const highestHeight = Math.max(
      ...normalizedMooringTideData.map((item) => item.height)
    );

    const handleNewChartClick = (event: CategoricalChartState) => {
      if (!event || event.activeLabel === undefined) return;

      let clickedSpot = event.activeLabel;
      let vesselDepartureDetails: DepartureDetails | null = null;
      let vesselMooringDetail: ArrivalDetails | null = null;
      if (title === "Mooring") {
        const departTime =
          normalizedMooringTideData[normalizedMooringTideData.length - 1]
            ?.tideDateTime;
        const vesselArrivalTime = moment(clickedSpot)
          .add(1, "hours")
          .add(15, "minutes")
          .format();
        const pobArrivalTime = clickedSpot;
        const vesselDepartureTime = departTime;

        vesselMooringDetail = {
          popArrivalDate: pobArrivalTime,
          popDepartureDate: vesselArrivalTime,
          vesselArrivalDate: vesselArrivalTime,
          vesselDepartureDate: vesselDepartureTime,
        };
        setMooringDetails(vesselMooringDetail);
        setShowNext(true);
      } else {
        if (hvvwInformation) {
          const arriveDate = hvvwInformation.mooringDate ?? "";
          const arriveTime = hvvwInformation.mooringTime ?? "";
          const pobHvvWArrivalDate = hvvwInformation.pobArrivalDate ?? "";
          const pobHvvWArrivalTime = hvvwInformation.pobArrivalTime ?? "";
          const pobHvvWDepartureDate = hvvwInformation.pobDepartureDate ?? "";
          const pobHvvWDepartureTime = hvvwInformation.pobDepartureTime ?? "";

          // vessel Arrival time
          const vesselArrivalTime = convertDateAndTimeFromLocal(
            arriveDate,
            arriveTime
          );

          // pob arrival time
          const pobArrivalTime = convertDateAndTimeFromLocal(
            pobHvvWArrivalDate,
            pobHvvWArrivalTime
          );

          // pob departure time
          const pobDepartureTime = convertDateAndTimeFromLocal(
            pobHvvWDepartureDate,
            pobHvvWDepartureTime
          );

          // new pop arrival time
          const newPobArrivalTime = clickedSpot;

          // new pop departure time
          const newPobDepartureTime = moment(newPobArrivalTime)
            //.add(1, "hours")
            .add(15, "minutes")
            .format();

          // vessel departure time
          const vesselDepartureTime = moment(clickedSpot)
            //.add(1, "hours")
            .add(15, "minutes")
            .format();

          vesselDepartureDetails = {
            popArrivalDate: pobArrivalTime ?? "",
            popDepartureDate: pobDepartureTime ?? "",
            newPopArrivalDate: newPobArrivalTime,
            newPopDepartureDate: newPobDepartureTime,
            vesselArrivalDate: vesselArrivalTime ?? "",
            vesselDepartureDate: vesselDepartureTime,
          };
          setDepartureDetails({ ...vesselDepartureDetails });

          // length of the vessel stay in the port in hours
          const lengthOfStay = moment(vesselDepartureTime).diff(
            moment(vesselArrivalTime),
            "hours"
          );
          setLengthOfStay(`${lengthOfStay} hours (Length of stay)`);
          setShowNext(true);
        } else {
          console.error("Invalid HVVW information");
          return;
        }
      }
      // Store formatted times
      // vessel arrival
      const formattedVesselMooringDate = moment(
        vesselMooringDetail?.vesselArrivalDate
      ).format("YYYY-MM-DD");
      const formattedVesselMooringTime = moment(
        vesselMooringDetail?.vesselArrivalDate
      ).format("hh:mm A");

      // vessel departure
      const formattedDepartureDate = moment(
        vesselDepartureDetails?.vesselDepartureDate
      ).format("YYYY-MM-DD");

      const formattedDepartureTime = moment(
        vesselDepartureDetails?.vesselDepartureDate
      ).format("hh:mm A");

      // Define hvvwInformations object once
      const hvvwInformations: HvvWVesselTime = {
        mooringDate:
          title === "Mooring"
            ? formattedVesselMooringDate
            : hvvwInformation?.mooringDate ?? undefined,
        mooringTime:
          title === "Mooring"
            ? formattedVesselMooringTime
            : hvvwInformation?.mooringTime ?? undefined,
        departureDate:
          title === "Departure"
            ? formattedDepartureDate
            : hvvwInformation?.departureDate ?? undefined,
        departureTime:
          title === "Departure"
            ? formattedDepartureTime
            : hvvwInformation?.departureTime ?? undefined,

        pobArrivalDate: vesselMooringDetail?.popArrivalDate
          ? moment(vesselMooringDetail.popArrivalDate).format("YYYY-MM-DD")
          : hvvwInformation?.pobArrivalDate ?? undefined,
        pobArrivalTime: vesselMooringDetail?.popArrivalDate
          ? moment(vesselMooringDetail.popArrivalDate).format("hh:mm A")
          : hvvwInformation?.pobArrivalTime ?? undefined,
        pobDepartureDate: vesselMooringDetail?.vesselArrivalDate
          ? moment(vesselMooringDetail.vesselArrivalDate).format("YYYY-MM-DD")
          : hvvwInformation?.pobDepartureDate ?? undefined,

        pobDepartureTime: vesselMooringDetail?.vesselArrivalDate
          ? moment(vesselMooringDetail.vesselArrivalDate).format("hh:mm A")
          : hvvwInformation?.pobDepartureTime ?? undefined,

        newPobArrivalDate: vesselDepartureDetails?.newPopArrivalDate
          ? moment(vesselDepartureDetails.newPopArrivalDate).format(
              "YYYY-MM-DD"
            )
          : undefined,
        newPobArrivalTime: vesselDepartureDetails?.newPopArrivalDate
          ? moment(vesselDepartureDetails.newPopArrivalDate).format("hh:mm A")
          : undefined,
        newPobDepartureDate: vesselDepartureDetails?.newPopDepartureDate
          ? moment(vesselDepartureDetails.newPopDepartureDate).format(
              "YYYY-MM-DD"
            )
          : undefined,
        newPobDepartureTime: vesselDepartureDetails?.newPopDepartureDate
          ? moment(vesselDepartureDetails.newPopDepartureDate).format("hh:mm A")
          : undefined,
      };

      // Update state
      setHvvWInformation(hvvwInformations);
      setHeight(5);
    };

    return (
      <Draggable>
        <div className="bookingTideTopContainer">
          <div className="bookingTideTitle">
            VESSEL {title.toUpperCase()} GRAPH
          </div>
          <>
            {title === "Mooring" ? (
              <div className="arrival-section">
                <div className="arrival-box">-24 Hrs ARRIVAL DATE</div>
                <div className="arrival-box arrival-center">
                  ARRIVAL DATE
                  <span className="arrival-ship-name">
                    {vesselName.toUpperCase()}
                  </span>
                </div>
                <div className="arrival-box">+24 Hrs ARRIVAL DATE</div>
              </div>
            ) : (
              <div className="arrival-section">
                <div className="arrival-box">-24 Hrs DEPARTURE DATE</div>
                <div className="arrival-box arrival-center">
                  DEPARTURE DATE
                  <span className="arrival-ship-name">
                    {vesselName.toUpperCase()}
                  </span>
                </div>
                <div className="arrival-box">+24 Hrs DEPARTURE DATE</div>
              </div>
            )}
          </>
          <button
            className="graphModalCancel"
            onClick={() => {
              if (title === "Mooring") {
                setMooringDetails(null);
              }
              if (title === "Departure") {
                setDepartureDetails(null);
              }
              setHvvWInformation(undefined);
              setLengthOfStay("");
              setCloseGraphModal(false);
            }}
          >
            <span>&times;</span>
          </button>
          <div className="bookingTideGraphModal">
            <ResponsiveContainer>
              <ComposedChart
                ref={chartRef}
                width={1200}
                height={300}
                data={normalizedMooringTideData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                onClick={(event) => handleNewChartClick(event)}
              >
                {/* <CartesianGrid strokeDasharray="1 1" /> */}
                <CartesianGrid
                  strokeDasharray="3 3"
                  // vertical={true}
                  // horizontal={false}
                />
                <XAxis
                  dataKey="tideDateTime"
                  tickFormatter={(tick) =>
                    moment(tick).format("YYYY-MM-DD HH:mm")
                  }
                  ticks={(() => {
                    if (
                      !normalizedMooringTideData ||
                      normalizedMooringTideData.length === 0
                    ) {
                      return [];
                    }
                    const startTime = moment(
                      normalizedMooringTideData[0].tideDateTime
                    );
                    const endTime = moment(
                      normalizedMooringTideData[
                        normalizedMooringTideData.length - 1
                      ].tideDateTime
                    );
                    const midnightTimestamps = [];
                    let currentTime = startTime.clone().startOf("day"); // Start at midnight
                    while (currentTime.isSameOrBefore(endTime, "day")) {
                      midnightTimestamps.push(currentTime.format());
                      currentTime.add(1, "day");
                    }
                    return midnightTimestamps;
                  })()}
                  tick={{ stroke: "grey", strokeWidth: 1, fontSize: 12 }}
                  tickLine={false} // Remove X-axis tick lines
                  axisLine={false} // Remove X-axis line
                  label={{
                    value: "Time (Date & Hours)",
                    position: "insideBottom",
                  }}
                />

                <YAxis
                  domain={[0, (dataMax: number) => Math.ceil(dataMax)]} // Round max value up and add 1
                  dataKey="height"
                  tickLine={false} // Remove Y-axis tick lines
                  axisLine={true} // Remove Y-axis line
                  tickCount={
                    Math.ceil(
                      Math.max(
                        ...normalizedMooringTideData.map((d) => d.height)
                      )
                    ) + 2
                  } // Ensures proper tick count
                  interval={0} // Ensures every tick is displayed
                  allowDecimals={false} // Ensures only whole numbers
                  label={{
                    value: "Tides (m)",
                    angle: -90,
                  }}
                />

                <Tooltip cursor={false} content={<TideCustomTooltip />} />
                <Legend content={<CustomLegend />} />
                {normalizedMooringTideData.map((entry, index) => {
                  const value = Math.floor(
                    normalizedMooringTideData.length / 3
                  );

                  // Draw 3 refrence line for each data point
                  if (index % value === 0) {
                    return (
                      <ReferenceLine
                        key={`line-${index}`}
                        x={entry.tideDateTime}
                        stroke={index === 0 ? "none" : "black"} // Set color based on index
                      />
                    );
                  }
                })}

                {/* Render reference areas for background segments */}
                {referenceAreas.map((area, index) => (
                  <ReferenceArea
                    key={`area-${index}`}
                    x1={area.x1}
                    x2={area.x2}
                    fill={area.fill}
                    y1={0}
                  />
                ))}

                {/* Render vertical reference lines */}
                {expandedTideData() &&
                  expandedTideData().length > 1 &&
                  expandedTideData().map((item, index) => {
                    const randomColor = `#${Math.floor(
                      Math.random() * 16777215
                    ).toString(16)}`; // Generate random color
                    return (
                      <React.Fragment key={`line-${index}`}>
                        <ReferenceLine
                          // key={`line-${index}`}
                          // x={item.tideDateTime}
                          //stroke={Colors.navigationWindow}
                          strokeWidth={2}
                          ifOverflow="visible"
                          segment={[
                            { x: item.tideDateTime, y: 0 },
                            { x: item.tideDateTime, y: highestHeight },
                          ]}
                          label={
                            index % 2 === 0 // Render label every other point
                              ? {
                                  value: `${moment(item.tideDateTime).format(
                                    "HH:mm"
                                  )}`,
                                  position: "top",
                                  fill: "black",
                                  fontSize: 12,
                                  dx: -10,
                                }
                              : {
                                  value: `${moment(item.tideDateTime).format(
                                    "HH:mm"
                                  )}`,
                                  position: "top",
                                  fill: "black",
                                  fontSize: 12,
                                  dx: 10,
                                }
                          }
                        />
                        <ReferenceDot
                          x={item.tideDateTime}
                          y={highestHeight}
                          r={5}
                          fill={randomColor}
                          ifOverflow="visible"
                        />
                      </React.Fragment>
                    );
                  })}

                {/* Render the 2m UKC line and label */}
                {/* <ReferenceLine
                  y={calculateMinTideHt()}
                  stroke="red"
                  strokeWidth={3}
                  label={{
                    value: "2m UKC",
                    position: "top",
                    fill: "black",
                    fontSize: 12,
                    dy: -1,
                  }}
                /> */}
                {title === "Mooring" && mooringDetails && (
                  <React.Fragment>
                    {/* For the POB ARRIVAL */}

                    <ReferenceLine
                      stroke="blue"
                      strokeWidth={3}
                      segment={[
                        { x: mooringDetails.popArrivalDate, y: height },
                        { x: mooringDetails.popDepartureDate, y: height },
                      ]}
                    />

                    <ReferenceLine
                      stroke="blue"
                      strokeWidth={3}
                      segment={[
                        { x: mooringDetails.popArrivalDate, y: height },
                        { x: mooringDetails.popArrivalDate, y: height + 1.0 },
                      ]}
                      label={{
                        value: `POB: ${moment(
                          mooringDetails.popArrivalDate
                        ).format("HH:mm")}`,
                        position: "top",
                        fill: "black",
                        fontSize: 12,
                        dy: -10, // Shift the label upward
                        dx: -15, // Shift the label to the right
                      }}
                    />
                    <ReferenceDot
                      x={mooringDetails.popArrivalDate}
                      y={height}
                      r={3}
                      fill={"yellow"}
                      ifOverflow="visible"
                    />

                    {/* For the Vessel ARRIVAL // Vessel Line */}
                    <ReferenceLine
                      stroke="green"
                      strokeWidth={3}
                      segment={[
                        { x: mooringDetails.vesselArrivalDate, y: height },
                        {
                          x: mooringDetails.vesselArrivalDate,
                          y: height + 1.0,
                        },
                      ]}
                      label={{
                        value: `${moment(
                          mooringDetails.vesselArrivalDate
                        ).format("HH:mm")}`,
                        position: "top",
                        fill: "black",
                        fontSize: 12,
                      }}
                    />
                    <ReferenceLine
                      stroke="green"
                      strokeWidth={3}
                      segment={[
                        { x: mooringDetails.vesselArrivalDate, y: height },
                        { x: mooringDetails.vesselDepartureDate, y: height },
                      ]}
                    />
                    <ReferenceDot
                      x={mooringDetails.vesselArrivalDate}
                      y={height}
                      r={3}
                      fill={"yellow"}
                      ifOverflow="visible"
                    />
                  </React.Fragment>
                )}

                {title === "Departure" && departureDetails && (
                  <React.Fragment>
                    {/* The 4 yellow dots for  */}
                    <>
                      <ReferenceLine
                        stroke="green"
                        strokeWidth={3}
                        segment={[
                          { x: departureDetails.vesselArrivalDate, y: height },
                          {
                            x: departureDetails.newPopArrivalDate,
                            y: height,
                          },
                        ]}
                        label={{
                          value: lengthOfStay,
                          position: "top",
                          fill: "black",
                          fontSize: 12,
                        }}
                      />

                      <ReferenceLine
                        stroke="blue"
                        strokeWidth={3}
                        segment={[
                          { x: departureDetails.popArrivalDate, y: height },
                          { x: departureDetails.popDepartureDate, y: height },
                        ]}
                      />

                      <ReferenceLine
                        stroke="blue"
                        strokeWidth={3}
                        segment={[
                          { x: departureDetails.newPopArrivalDate, y: height },
                          {
                            x: departureDetails.newPopDepartureDate,
                            y: height,
                          },
                        ]}
                      />
                    </>
                    {/* 4 vertical Lines (4) */}
                    <>
                      {/* The first vertical line for POB for when the vessel just arrivied*/}
                      {departureDetails.popArrivalDate && (
                        <>
                          <ReferenceLine
                            stroke="blue"
                            strokeWidth={3}
                            segment={[
                              { x: departureDetails.popArrivalDate, y: height },
                              {
                                x: departureDetails.popArrivalDate,
                                y: height + 1.0,
                              },
                            ]}
                            label={{
                              value: `POB: ${moment(
                                departureDetails.popArrivalDate
                              ).format("HH:mm")}`,
                              position: "top",
                              fill: "black",
                              fontSize: 12,
                              dy: -10,
                              dx: -15,
                            }}
                          />
                          <ReferenceDot
                            x={departureDetails.popArrivalDate}
                            y={height}
                            r={3}
                            fill={"yellow"}
                            ifOverflow="visible"
                          />
                        </>
                      )}

                      {/* The third vertical line for POB for when the vessel is about to leave*/}
                      {departureDetails.newPopArrivalDate && (
                        <>
                          <ReferenceLine
                            stroke="blue"
                            strokeWidth={3}
                            segment={[
                              {
                                x: departureDetails.newPopArrivalDate,
                                y: height,
                              },
                              {
                                x: departureDetails.newPopArrivalDate,
                                y: height + 1.0,
                              },
                            ]}
                            label={{
                              value: `POB: ${moment(
                                departureDetails.newPopArrivalDate
                              ).format("HH:mm")}`,
                              position: "top",
                              fill: "black",
                              fontSize: 12,
                              dy: -5,
                              dx: -35,
                            }}
                          />
                          <ReferenceDot
                            x={departureDetails.newPopArrivalDate}
                            y={height}
                            r={3}
                            fill={"yellow"}
                            ifOverflow="visible"
                          />
                        </>
                      )}

                      {/* The second vertical line for vessel for when the vessel arrives*/}
                      {departureDetails.vesselArrivalDate && (
                        <>
                          <ReferenceLine
                            stroke="green"
                            strokeWidth={3}
                            segment={[
                              {
                                x: departureDetails.vesselArrivalDate,
                                y: height,
                              },
                              {
                                x: departureDetails.vesselArrivalDate,
                                y: height + 1.0,
                              },
                            ]}
                            label={{
                              value: `VA: ${moment(
                                departureDetails.vesselArrivalDate
                              ).format("HH:mm")}`,
                              position: "top",
                              fill: "black",
                              fontSize: 12,
                              dy: 10,
                              dx: 35,
                            }}
                          />
                          <ReferenceDot
                            x={departureDetails.vesselArrivalDate}
                            y={height}
                            r={3}
                            fill={"yellow"}
                            ifOverflow="visible"
                          />
                        </>
                      )}

                      {/* The fourth vertical line for vessel for when the vessel departs*/}
                      {departureDetails.vesselDepartureDate && (
                        <>
                          <ReferenceLine
                            stroke="green"
                            strokeWidth={3}
                            segment={[
                              {
                                x: departureDetails.vesselDepartureDate,
                                y: height,
                              },
                              {
                                x: departureDetails.vesselDepartureDate,
                                y: height + 1.0,
                              },
                            ]}
                            label={{
                              value: `VD: ${moment(
                                departureDetails.vesselDepartureDate
                              ).format("HH:mm")}`,
                              position: "top",
                              fill: "black",
                              fontSize: 12,
                              dy: 10,
                              dx: 35,
                            }}
                          />
                          <ReferenceDot
                            x={departureDetails.vesselDepartureDate}
                            y={height}
                            r={3}
                            fill={"yellow"}
                            ifOverflow="visible"
                          />
                        </>
                      )}
                    </>
                  </React.Fragment>
                )}
                {/* <ReferenceArea y2={calculateMinTideHt()} fill={"red"} /> */}
                <Line
                  type="monotone"
                  dataKey="height"
                  stroke={Colors.tideHeight}
                  dot={false}
                  strokeWidth={1}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>

          {/* Show a next button */}
          {showNext && (
            <div className="next-button">
              <button
                type="button"
                className="btn cancelBooking"
                onClick={() => {
                  setCloseGraphModal(false);
                }}
              >
                CONFIRM
                {/* {title === "Mooring" ? "Set Departure Date" : "CONFIRM"} */}
              </button>
            </div>
          )}
        </div>
      </Draggable>
    );
  } else {
    // Render a placeholder or loading state if data is not available
    return <div>Loading tide data...</div>;
  }
}

export default NonHvvwCustomTides;

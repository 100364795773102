import React, { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "react-toastify/dist/ReactToastify.css";
import { BookingResource } from "../../../Models/Booking";
import { BookingsDetailsData } from "../../../Services/BookingRequests";
import { ApexOptions } from "apexcharts";
import { ReservationFormData } from "../../../Services/BookingResourcesRequest";
import OperationShowGangwayReservationModal from "./OperationsShowGangwayReservationModal";
import { Refresh } from "Pages/Operation/Operation";

export interface BookingResourceChartData extends BookingResource {
  x: string; // Gangway Name
  y: [number, number]; // Start & End timestamps (Epoch)
  fillColor?: string; // Color from getColor()
}

interface OperationsMapGangwayReservationsProps {
  bookingResources: BookingResource[];
  bookingDetails: BookingsDetailsData;
  updateGangwayReservation: (
    reservation: ReservationFormData,
    closeModal: () => void
  ) => Promise<void>;
  fetchBookingResources: (bookingId: number) => void;
  handleRemoveBookingResource: (
    bookingId: number,
    vesselDoorId: number
  ) => Promise<boolean | void>;
  setRefresh: React.Dispatch<React.SetStateAction<Refresh>>;
}

const OperationsGangwayReservations: React.FC<
  OperationsMapGangwayReservationsProps
> = ({
  bookingResources,
  bookingDetails,
  updateGangwayReservation,
  fetchBookingResources,
  handleRemoveBookingResource,
  setRefresh,
}) => {
    const filteredBookingResources = useMemo(() =>
      bookingResources.filter(
        (reservation) =>
          reservation.gangwayId !== null && reservation.gangwayId !== null
      ), [bookingResources]);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedReservation, setSelectedReservation] =
      useState<BookingResourceChartData | null>(null);
    const [bookingId, setBookingId] = useState<number>(0);

    const getColor = (gangwayId: number): string => {
      if (!bookingDetails || bookingDetails.gangways.length === 0) return "blue";
      const matchingResource = bookingDetails.gangways.find(
        (resource) => resource.gangwayId === gangwayId
      );
      return matchingResource?.colorDark || "blue";
    };

    const series = useMemo(
      () => [
        {
          name: "Assemblies",
          data: filteredBookingResources.map((reservation) => ({
            x: reservation.platformName
              ? `${reservation.gangwayName} (${reservation.platformName})`
              : reservation.gangwayName,
            y: [
              new Date(reservation.startDateTime).getTime(),
              new Date(reservation.endDateTime).getTime(),
            ],
            fillColor: reservation.gangwayId
              ? getColor(reservation.gangwayId)
              : "blue",
            ...reservation,
          })),
        },
      ],
      [filteredBookingResources, bookingDetails]
    );

    const options2: ApexOptions = {
      chart: {
        toolbar: { autoSelected: "pan" },
        events: {
          dataPointSelection: (
            _event: any,
            _chartContext: any,
            { seriesIndex, dataPointIndex, w }: any
          ) => {
            try {
              const selected = w.globals.initialSeries[seriesIndex]?.data[
                dataPointIndex
              ] as BookingResourceChartData | undefined;

              setTimeout(() => {
                if (selected) {
                  setSelectedReservation(selected);
                  setBookingId(selected.bookingId);
                  setIsModalOpen(true);
                } else {
                  console.error(
                    "Error selecting data point: ",
                    seriesIndex,
                    dataPointIndex,
                    w
                  );
                }
              }, 0);
            } catch (error) {
              console.error("Error selecting data point: ", error);
            }
          },
        },
      },
      title: {
        text: "Gangway Reservations",
        align: "center",
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          fontFamily: "Montserrat",
          color: "#22418a",
        },
      },
      tooltip: {
        custom: ({ seriesIndex, dataPointIndex, w }: any) => {
          const dataPoint = w.globals.initialSeries[seriesIndex]?.data[
            dataPointIndex
          ] as BookingResourceChartData | undefined;
          if (!dataPoint) return "No data";
          return `<div class="arrow_box">
          <span style="font-family: Montserrat, sans-serif;">${dataPoint.vesselName || "No vessel"
            }</span><br/>
          <span>Start: ${new Date(
              dataPoint.y[0]
            ).toLocaleTimeString()}</span><br/>
          <span>End: ${new Date(dataPoint.y[1]).toLocaleTimeString()}</span>
        </div>`;
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
          dataLabels: { total: { enabled: true } },
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: (val: string) => new Date(val).toLocaleTimeString(),
        },
      },
    };

    const options: ApexOptions = {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: true,
            reset: true,
          },
          autoSelected: "pan",
        },
        events: {
          dataPointSelection: (
            event,
            chartContext,
            { seriesIndex, dataPointIndex, w }
          ) => {
            try {
              const selectedReservation =
                w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
              setTimeout(() => {
                setSelectedReservation(selectedReservation);
                setIsModalOpen(true);
              }, 0);
            } catch (error) {
              console.error("Error selecting data point: ", error);
            }
          },
        },
      },
      title: {
        text: "Resource Reservations",
        align: "center",
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          fontFamily: "Montserrat",
          color: "#22418a",
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const dataPoint =
            w.globals.initialSeries[seriesIndex]?.data[dataPointIndex];
          const startTime = dataPoint
            ? new Date(dataPoint.y[0]).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
            : "No data";
          const endTime = dataPoint
            ? new Date(dataPoint.y[1]).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
            : "No data";
          const vesselName = dataPoint?.vesselName || "No vessel";

          return (
            '<div class="arrow_box">' +
            '<span style="font-family: Montserrat, sans-serif;">' +
            vesselName +
            "<span><br/>" +
            // '<span>' + (dataPoint?.x || "No data") + '</span><br/>' +
            "<span>Start: " +
            startTime +
            "</span><br/>" +
            "<span>End: " +
            endTime +
            "</span>" +
            "</div>"
          );
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
          // dataLabels: { total: { enabled: true } },
          dataLabels: {
            position: "center",
            total: {
              enabled: true,
              formatter: function (val, opts) {
                return (
                  opts.w.globals.seriesNames[opts.seriesIndex] +
                  ": " +
                  (opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
                    ?.x || "No data")
                );
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            fontWeight: 400,
            colors: ["#22418a"],
          },
          formatter: function (val) {
            let date = new Date(val);
            date.setMinutes(0, 0, 0);
            return date.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
          },
        },
        tickAmount: 3,
        min:
          filteredBookingResources.length > 0
            ? Math.floor(
              new Date(
                Math.min(
                  ...filteredBookingResources.map((res) =>
                    new Date(res.startDateTime).getTime()
                  )
                )
              ).getTime() / 3600000
            ) * 3600000
            : undefined,
        max:
          filteredBookingResources.length > 0
            ? Math.ceil(
              new Date(
                Math.max(
                  ...filteredBookingResources.map((res) =>
                    new Date(res.endDateTime).getTime()
                  )
                )
              ).getTime() / 3600000
            ) * 3600000
            : undefined,
        axisBorder: {
          color: "#22418a",
          // height: 1,
        },
        axisTicks: {
          show: filteredBookingResources.length > 0,
          color: "#22418a",
        },
        // show: bookingResources.length > 0,
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontFamily: "Montserrat",
            fontWeight: 600,
            colors: ["#22418a"],
          },
        },
        axisBorder: {
          show: filteredBookingResources.length > 0,
          color: "#22418a",
          width: 1,
        },
        axisTicks: {
          show: filteredBookingResources.length > 0,
          color: "#22418a",
          //width: 5,
        },
        show: filteredBookingResources.length > 0,
      },
      grid: {
        show: true,
        borderColor: "#22418a",
        strokeDashArray: 1,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: filteredBookingResources.length > 0,
          },
        },
      },
    };
    return (
      <div className="operationsMapGangwayReservationsContainer">
        {filteredBookingResources.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "14px",
              color: "#22418a",
              padding: "10px",
            }}
          >
            No gangway reservations for this day
          </div>
        ) : (
          <ReactApexChart
            options={options}
            series={series}
            type="rangeBar"
            height={150}
            width={330}
          />
        )}
        {isModalOpen && selectedReservation && (
          <OperationShowGangwayReservationModal
            bookingId={bookingId}
            selectedReservation={selectedReservation}
            closeModal={() => setIsModalOpen(false)}
            updateGangwayReservation={updateGangwayReservation}
            fetchBookingResources={fetchBookingResources}
            handleRemoveBookingResource={handleRemoveBookingResource}
            setRefresh={setRefresh}
          />
        )}
      </div>
    );
  };

export default OperationsGangwayReservations;

import { Group, Image } from "react-konva";
import React from "react";
import useImage from "use-image";
import axios from "axios";
import { toast } from "react-toastify";
import { VesselImageDTO } from "../Models/VesselImageDTO";
import { VesselDoorDTO } from "../Models/VesselDoorDTO";
import { VesselFactors } from "../Components/Operations/OperationUtils/VesselFactors";
import { useApi } from "../api/api2";

// --- Interfaces ---

// Represents vessel data maintained in state.
export interface VesselData {
  vesselId: number;
  orientation: "PORT" | "STARBOARD";
  url?: string;
  factor?: number;
  doors?: VesselDoorDTO[]; // Doors with doorType === "Passenger"
  powerDoors?: VesselDoorDTO[]; // Doors with doorType === "Shore Connection"
}

// Represents the vessel image orientation state.
export interface VesselImageOrientation {
  vesselId: number;
  vesselOrientation: "PORT" | "STARBOARD";
}

// Object passed for fetching vessel image data.
export interface VesselBooking {
  vesselId: number;
  vesselOrientation: "PORT" | "STARBOARD";
}

// Data returned from fetchVesselImages.
export interface VesselImageData extends VesselBooking {
  url: string;
  factor: number;
  doors: VesselDoorDTO[];
  powerDoors: VesselDoorDTO[];
  orientation: "PORT" | "STARBOARD";
}

// Fetch vessel image and door data based on vessel booking information.
export const useFetchVessel = async (
  booking: VesselBooking,
  setVesselImageOrientation: React.Dispatch<
    React.SetStateAction<VesselImageOrientation>
  >,
  setVesselData: React.Dispatch<React.SetStateAction<VesselData[]>>
): Promise<void> => {
  const { request: vesselImageRequest } = useApi<VesselImageDTO[]>();
  const { request: vesselDoorRequest } = useApi<VesselDoorDTO[]>();
  const { vesselId, vesselOrientation } = booking;

  try {
    const [vesselImages, vesselDoors] = await Promise.all([
      vesselImageRequest(
        `/VesselImage/vesselId/${vesselId}/vesselOrientation/${vesselOrientation}`,
        "GET"
      ),
      vesselDoorRequest(`/VesselDoor/${vesselId}`, "GET"),
    ]);
    const imageURL: string =
      vesselImages && vesselImages.length > 0 ? vesselImages[0].imagePath : "";
    const factor = VesselFactors[vesselId];

    // Categorize the doors based on doorType.
    const passengerDoors = vesselDoors.filter(
      (door) => door.doorType === "Passenger"
    );
    const shoreConnectionDoors = vesselDoors.filter(
      (door) => door.doorType === "Shore Connection"
    );

    setVesselImageOrientation({
      vesselId,
      vesselOrientation,
    });
    setVesselData((prevVesselData) => {
      const vesselData = prevVesselData.find(
        (vessel) => vessel.vesselId === vesselId
      );
      if (vesselData) {
        return prevVesselData.map((vessel) =>
          vessel.vesselId === vesselId
            ? {
                ...vessel,
                url: imageURL,
                factor,
                doors: passengerDoors,
                powerDoors: shoreConnectionDoors,
                orientation: vesselOrientation,
              }
            : vessel
        );
      } else {
        return [
          ...prevVesselData,
          {
            vesselId,
            url: imageURL,
            factor,
            doors: passengerDoors,
            powerDoors: shoreConnectionDoors,
            orientation: vesselOrientation,
          },
        ];
      }
    });

    // return {
    //   vesselId,
    //   vesselOrientation,
    //   url: imageURL,
    //   factor,
    //   doors: passengerDoors,
    //   powerDoors: shoreConnectionDoors,
    //   orientation: vesselOrientation,
    // };
  } catch (error) {
    console.error("Error fetching vessel data:", error);
    toast.error("There was an error fetching the vessel images.");
    // return null;
  }
};

// Update the vessel position based on the x and y coordinates, bookingId, image width and height, and vessel orientation.
export const useSaveVesselPosition = () => {
  const { request } = useApi<void>(); // ✅ Call `useApi` here at the top level

  return async (
    xCoor: number,
    yCoor: number,
    bookingId: number,
    imageWidth: number,
    imageHeight: number,
    vesselOrientation: "PORT" | "STARBOARD"
  ): Promise<void> => {
    const xCoordinateFactor = imageWidth / xCoor;
    const yCoordinateFactor = imageHeight / yCoor;

    try {
      await request(
        `/Booking/updateFactors/${bookingId}/${xCoordinateFactor}/${yCoordinateFactor}/${vesselOrientation}`,
        "PUT"
      );
      toast.success("Booking updated successfully", {
        position: "top-center",
        hideProgressBar: true,
      });
    } catch (error) {
      toast.error(`Error updating booking: ${error}`, {
        position: "top-center",
        hideProgressBar: true,
      });
      console.error("Error updating booking:", error);
    }
  };
};

// Rotate the vessel image by toggling its orientation, fetching updated image data, and updating state.
export const RotateVessel = (
  vesselId: number,
  currentOrientation: "PORT" | "STARBOARD",
  setVesselData: React.Dispatch<React.SetStateAction<VesselData[]>>,
  setVesselImageOrientation: React.Dispatch<
    React.SetStateAction<VesselImageOrientation>
  >
): void => {
  const newOrientation: "PORT" | "STARBOARD" =
    currentOrientation === "PORT" ? "STARBOARD" : "PORT";

  const bookingObject: VesselBooking = {
    vesselId,
    vesselOrientation: newOrientation,
  };

  useFetchVessel(bookingObject, setVesselImageOrientation, setVesselData);
};

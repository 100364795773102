import { useCallback } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Constants from "../Constants";
// Base URL for API requests
const API_BASE_URL = `${Constants.BACK_END}/api`;

// Create an Axios instance with default configuration
export const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Helper function to retrieve the JWT token from sessionStorage or localStorage
const getAuthToken = () =>
  sessionStorage.getItem("jwtToken") || localStorage.getItem("jwtToken");

// Axios request interceptor to attach the JWT token to every request
apiClient.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Axios response interceptor to handle 401 Unauthorized responses globally
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.error("Unauthorized! Redirecting to login...");
      localStorage.removeItem("jwtToken"); // Remove token from storage
      sessionStorage.removeItem("jwtToken");
      window.location.href = "/login"; // Redirect user to login page
    }
    return Promise.reject(error);
  }
);

/**
 * Custom React hook for making API requests without using local state.
 *
 * @template T - The expected response data type.
 * @returns A function that can be used to make API requests.
 */
export function useApi<T>() {
  /**
   * Makes an API request using Axios with the given parameters.
   *
   * @param {string} url - The API endpoint (relative to the base URL).
   * @param {"GET" | "POST" | "PUT" | "DELETE"} method - HTTP method.
   * @param {any} [payload] - Optional data to send in POST/PUT requests.
   * @returns {Promise<T>} The response data of type T.
   */

  // dont use callback here
  const request = async (
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    payload?: any
  ): Promise<T> => {
    // Construct the Axios request config
    const config: AxiosRequestConfig = {
      url,
      method,
      data: payload,
    };

    try {
      // Execute the API request
      //  console.log(
      //    "API Request2:",
      //    (config.baseURL || API_BASE_URL) + config.url,
      //    config.method,
      //    config.data
      //  );
      const response: AxiosResponse<T> = await apiClient(config);
      return response.data;
    } catch (err: any) {
      console.error("API Error:", err);

      // Extract a user-friendly error message
      const errorMessage =
        err.response?.data?.message || err.message || "Something went wrong";

      return Promise.reject(errorMessage);
    }
  };

  return { request };
}

import React, { useState, useEffect } from "react";
import useBookingResourceOperations, {
  ReservationFormData,
} from "../../../Services/BookingResourcesRequest";
import { AssemblyResourceData } from "../../../Models/Assembly";
import ReactDatetimeClass from "react-datetime";
import moment from "moment";
import { FaSave } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { Refresh } from "Pages/Operation/Operation";

export interface NewErrors {
  startDateTime: string;
  endDateTime: string;
  gangwayId: string;
  platformId: string;
  vesselName: string;
}

interface AddGangwayReservationFormProps {
  closeModal: () => void;
  bookingResourceId: number;
  bookingId: number;
  gangwayId: number;
  platformId?: number;
  modalAssemblyId: number;
  vesselDoorId: number;
  mooringDateTime: string;
  departureDateTime: string;
  setRefresh: React.Dispatch<React.SetStateAction<Refresh>>;
}

/**
 * AddGangwayReservationForm component for adding or updating gangway reservations.
 *
 * @param {AddGangwayReservationFormProps} props - The component properties.
 * @returns {JSX.Element} The rendered AddGangwayReservationForm component.
 */
const AddGangwayReservationForm = ({
  closeModal,
  bookingResourceId,
  bookingId,
  gangwayId,
  platformId,
  modalAssemblyId,
  vesselDoorId,
  mooringDateTime,
  departureDateTime,
  setRefresh,
}: AddGangwayReservationFormProps) => {
  const [formData, setFormData] = useState<ReservationFormData>({
    bookingResourceId: bookingResourceId,
    bookingId: bookingId,
    gangwayId: gangwayId,
    platformId: platformId,
    vesselDoorId: vesselDoorId,
    startDateTime: "",
    endDateTime: "",
    assemblyId: modalAssemblyId,
    resourceType: "Assembly",
  });

  const [errors, setErrors] = useState<NewErrors | null>(null);
  const [availableGangways, setAvailableGangways] = useState<
    AssemblyResourceData[]
  >([]);
  const [availablePlatforms, setAvailablePlatforms] = useState<
    AssemblyResourceData[]
  >([]);
  const [gangwayRequired, setGangwayRequired] = useState<boolean>(false);
  const [platformRequired, setPlatformRequired] = useState<boolean>(false);

  const { addGangwayReservation, getAvailableResourcesPerGroup } =
    useBookingResourceOperations();

  useEffect(() => {
    const start = moment(mooringDateTime).format();
    const end = moment(departureDateTime).format();
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDateTime: start,
      endDateTime: end,
      assemblyId: modalAssemblyId,
    }));

    if (modalAssemblyId && start && end) {
      const getAvaliableResources = async () => {
        const result = await getAvailableResourcesPerGroup(
          modalAssemblyId,
          mooringDateTime,
          departureDateTime
        );
        if (!result) {
          return;
        }
        setAvailableGangways(result.gangways);
        setAvailablePlatforms(result.platforms);
        setGangwayRequired(result.isGangwayRequired);
        setPlatformRequired(result.isPlatformRequired);
      };
      getAvaliableResources();
    }
  }, [
    mooringDateTime,
    departureDateTime,
    modalAssemblyId,
    getAvailableResourcesPerGroup,
  ]);

  const handleStartDateChange = (date: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDateTime: date,
    }));

    // Validate start date
    if (
      moment(date).isBefore(mooringDateTime) ||
      moment(date).isAfter(departureDateTime)
    ) {
      const newErrors: NewErrors = {
        startDateTime: "Start date must be between mooring and departure date",
        endDateTime: "",
        gangwayId: "",
        platformId: "",
        vesselName: "",
      };
      setErrors(newErrors);
    } else if (moment(date).isSame(moment(formData.endDateTime))) {
      const newErrors: NewErrors = {
        startDateTime: "Start date must be before end date",
        endDateTime: "",
        gangwayId: "",
        platformId: "",
        vesselName: "",
      };
      setErrors(newErrors);
    } else {
      setErrors(null); // Clear errors if valid
      if (
        formData.endDateTime &&
        moment(date).isBefore(moment(formData.endDateTime))
      ) {
        getAvailableResourcesPerGroup(
          modalAssemblyId,
          date,
          formData.endDateTime
        );
      }
    }
  };

  const handleEndDateChange = (date: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      endDateTime: date,
    }));

    // Validate end date
    if (
      moment(date).isBefore(mooringDateTime) ||
      moment(date).isAfter(departureDateTime)
    ) {
      const newErrors: NewErrors = {
        startDateTime: "",
        endDateTime: "End date must be between mooring and departure date",
        gangwayId: "",
        platformId: "",
        vesselName: "",
      };
      setErrors(newErrors);
    } else if (moment(date).isSame(moment(formData.startDateTime))) {
      const newErrors: NewErrors = {
        startDateTime: "",
        endDateTime: "End date must be before start date",
        gangwayId: "",
        platformId: "",
        vesselName: "",
      };
      setErrors(newErrors);
    } else {
      setErrors(null); // Clear errors if valid
      if (
        formData.startDateTime &&
        moment(date).isAfter(moment(formData.startDateTime))
      ) {
        getAvailableResourcesPerGroup(
          modalAssemblyId,
          formData.startDateTime,
          date
        );
      }
    }
  };

  const handleGangWayChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value.slice(0, 500),
    }));
    if (id === "gangwayId") {
      if (value) {
        setErrors(null);
      } else {
        const newErrors: NewErrors = {
          startDateTime: "",
          endDateTime: "",
          gangwayId: "Gangway is required",
          platformId: "",
          vesselName: "",
        };
        setErrors(newErrors);
      }
    } else {
      const newErrors: NewErrors = {
        startDateTime: "",
        endDateTime: "",
        gangwayId: "Gangway is required",
        platformId: "",
        vesselName: "",
      };
      setErrors(newErrors);
    }
  };

  const handlePlatFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value.slice(0, 500),
    }));
    // check that the value is valid
    if (id === "platformId") {
      if (value) {
        setErrors(null);
      } else {
        const newErrors: NewErrors = {
          startDateTime: "",
          endDateTime: "",
          gangwayId: "",
          platformId: "Platform is required",
          vesselName: "",
        };
        setErrors(newErrors);
      }
    } else {
      const newErrors: NewErrors = {
        startDateTime: "",
        endDateTime: "",
        gangwayId: "",
        platformId: "Platform is required",
        vesselName: "",
      };
      setErrors(newErrors);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (!["PortAdmin"].includes(userRole)) {
    //   toast.error(
    //     "You do not have the necessary permissions to add this reservation.",
    //     {
    //       position: "top-center",
    //       hideProgressBar: true,
    //     }
    //   );
    //   return;
    // }
    if (validateForm()) {
      try {
        const result = await addGangwayReservation(formData, closeModal);
        if (result) {
          setRefresh({
            refresh: true,
            showLoading: true,
          });
        }
      } catch (error) {
        console.error("Error updating gangway reservation:", error);
      }
    }
  };

  const validateForm = () => {
    const newErrors: NewErrors = {
      startDateTime: "",
      endDateTime: "",
      gangwayId: "",
      platformId: "",
      vesselName: "",
    };

    // Start Date Validation
    if (!formData.startDateTime) {
      newErrors.startDateTime = "Start Time is required";
    }

    // End Date Validation
    if (!formData.endDateTime) {
      newErrors.endDateTime = "End Time is required";
    } else if (
      moment(formData.startDateTime).isSameOrAfter(moment(formData.endDateTime))
    ) {
      newErrors.endDateTime = "End Time must be after Start Time";
    }

    // Gangway Validation (if required)
    if (gangwayRequired && !formData.gangwayId) {
      newErrors.gangwayId = "Choose a gangway";
    }

    // Platform Validation (if required)
    if (platformRequired && !formData.platformId) {
      newErrors.platformId = "Choose a platform";
    }

    // Remove empty errors (optional)
    Object.keys(newErrors).forEach((key) => {
      if (!newErrors[key as keyof NewErrors])
        delete newErrors[key as keyof NewErrors];
    });

    setErrors(newErrors);

    // Return whether there are no errors (form is valid)
    return Object.keys(newErrors).length === 0;
  };

  const isValidStartTime = (current: moment.Moment) => {
    return current.isSameOrAfter(mooringDateTime);
  };

  return (
    <div
      style={{
        padding: "10px",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <form onSubmit={handleSubmit}>
        <div className="row align-items-center justify-content-center">
          <div className={`col-md-${platformRequired ? "2" : "3"}`}>
            <label className="modalLables" htmlFor="startDateTime">
              Start Time <span className="required">*</span>
            </label>
            <ReactDatetimeClass
              dateFormat={false}
              isValidDate={isValidStartTime}
              className={`form-control ${
                errors?.startDateTime ? "is-invalid" : ""
              }`}
              value={
                formData.startDateTime ? moment(formData.startDateTime) : ""
              }
              onChange={(date) => {
                if (date === null) {
                  return;
                }
                const localDate = moment(date).format();
                handleStartDateChange(localDate);
              }}
            />
            {errors?.startDateTime && (
              <div className="invalid-feedback">{errors.startDateTime}</div>
            )}
          </div>
          <div className={`col-md-${platformRequired ? "2" : "3"}`}>
            <label className="modalLables" htmlFor="endTime">
              End Time <span className="required">*</span>
            </label>
            <ReactDatetimeClass
              dateFormat={false}
              className={`form-control ${
                errors?.endDateTime ? "is-invalid" : ""
              }`}
              value={formData.endDateTime ? moment(formData.endDateTime) : ""}
              onChange={(date) => {
                if (date === null) {
                  return;
                }
                const localDate = moment(date).format();
                handleEndDateChange(localDate);
              }}
            />
            {errors?.endDateTime && (
              <div className="invalid-feedback">{errors.endDateTime}</div>
            )}
          </div>

          <div className="col-md-3">
            <label className="modalLables" htmlFor="gangwayId">
              Gangway Name<span className="required">*</span>
            </label>
            <select
              className={`form-control h-12 ${
                errors?.gangwayId ? "is-invalid" : ""
              }`}
              id="gangwayId"
              value={formData.gangwayId}
              onChange={handleGangWayChange}
            >
              <option value="">Select Gangway</option>
              {availableGangways.length === 0 && (
                <option value="" disabled>
                  No gangways available
                </option>
              )}
              {Array.isArray(availableGangways) &&
                availableGangways.length > 0 &&
                availableGangways.map((gangway, index) => (
                  <option key={index} value={gangway.id}>
                    {gangway.name}
                  </option>
                ))}
            </select>
            {errors?.gangwayId && (
              <div className="invalid-feedback">{errors.gangwayId}</div>
            )}
          </div>

          {platformRequired && (
            <div className="col-md-3">
              <label className="modalLables" htmlFor="platformId">
                Platform Name<span className="required">*</span>
              </label>
              <select
                className={`form-control h-12${
                  errors?.platformId ? "is-invalid" : ""
                }`}
                id="platformId"
                value={formData.platformId}
                onChange={handlePlatFormChange}
              >
                <option value="">Select Platform</option>
                {availablePlatforms.length === 0 && (
                  <option value="" disabled>
                    No platforms available
                  </option>
                )}
                {Array.isArray(availablePlatforms) &&
                  availablePlatforms.length > 0 &&
                  availablePlatforms.map((platform, index) => (
                    <option key={index} value={platform.id}>
                      {platform.name}
                    </option>
                  ))}
              </select>
              {errors?.platformId && (
                <div className="invalid-feedback">{errors?.platformId}</div>
              )}
            </div>
          )}

          <div className="col-md-2 d-flex align-items-center justify-content-center">
            <button
              data-tooltip-id="operationsMapAddGangwayReservationFormIcons"
              data-tooltip-content="Save Reservation"
              type="submit"
              className="btn gangReserve"
            >
              <FaSave />
            </button>
            {/* <button type="button" className="btn gangNoReserve" onClick={closeModal}><FaCalendarTimes /></button>
            {errors?.startDateTime && (
              <div className="alert alert-danger">{errors.startDateTime}</div>
            )} */}
          </div>
          <Tooltip
            id="operationsMapAddGangwayReservationFormIcons"
            place="right"
            className="operationsMapAddGangwayReservationFormTooltip"
          />
        </div>
      </form>
    </div>
  );
};

export default AddGangwayReservationForm;

import { useState, useEffect, useRef, useCallback } from "react";
import { Stage, Layer, Image, Group } from "react-konva";
import useImage from "use-image";
import "moment-timezone";
import "bootstrap/dist/css/bootstrap.css";
import "react-datetime/css/react-datetime.css";
import { KonvaEventObject } from "konva/lib/Node";
import VesselImage from "../CustomViews/VesselImage";
import { BookingsDetailsData, } from "../../../Services/BookingRequests";
import { useSaveVesselPosition } from "../../../Services/VesselRequest";
import { VesselFactors } from "../OperationUtils/VesselFactors";
import Konva from "konva";
import useBookingRequestOperation from "../../../Services/BookingRequests";
import {
  EnrichedBooking,
  EnrichedVessel,
  VesselDoorDTO,
} from "../../../Models/VesselDoorDTO";
import { BookingResource } from "../../../Models/Booking";
import { CalculationsDTO } from "../../../Models/CalculationsDTO";
import { PortResources } from "../PortResource/PortResource";
import { Ruler } from "../Ruler/Ruler";
import RenderDoors from "../DoorRendring/AllDoorRendring";
import useBookingResourceOperations from "../../../Services/BookingResourcesRequest";
import BackgroundComponent from "Components/BackgroundComponent";
import MooringLines from "../MooringLines/MooringLines";
import { MooringLineDTO, MooringLineBookingResourceDTO } from "../../../Models/MooringLine";
import { useMooringLineBookingResourceRequests } from "../../../Services/MooringLineBookingResourceRequests";
import { Refresh } from "Pages/Operation/Operation";

interface VesselPositions {
  x: number;
  y: number;
}

export interface ResourceInformationComponentProps {
  bookingId: number;
  x: number;
  y: number;
  rotation?: number | null;
  gangwayId?: number | null;
  gangwayLengthFactor?: number | null;
  gangwayColorDark?: string | null;
  mooringLineId?: number;
  resourceType?: string;
}

export interface MooringLineResourceInformationComponentProps {
  id: number;
  mooringLineId: number;
  bookingId: number;
  xCoordinateFactor: number;
  yCoordinateFactor: number;
}

interface OperationsMapContainerProps {
  bookingResources: BookingResource[];
  bookingsDetails?: BookingsDetailsData | null;
  setSelectedVesselId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedDoor?: VesselDoorDTO | null;
  setSelectedDoor: React.Dispatch<React.SetStateAction<VesselDoorDTO | null>>;
  vesselOrientations: Record<number, "PORT" | "STARBOARD">;
  setVesselOrientations: React.Dispatch<
    React.SetStateAction<Record<number, "PORT" | "STARBOARD">>
  >;
  imageUrls: Record<number, string>;
  setImageUrls: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  setShowGraphsFlag: React.Dispatch<React.SetStateAction<boolean>>;
  filteredBookings: EnrichedBooking[];
  setDoorSlopeDataAssemblies: React.Dispatch<React.SetStateAction<CalculationsDTO[]>>;
  mooringLines: MooringLineDTO[];
  mooringLineBookingResources: Record<number, MooringLineBookingResourceDTO[]>;
  setMooringLineBookingResources: React.Dispatch<React.SetStateAction<Record<number, MooringLineBookingResourceDTO[]>>>;

  deletedMLBR: Record<number, MooringLineBookingResourceDTO[]>;
  setDeletedMLBR: React.Dispatch<React.SetStateAction<Record<number, MooringLineBookingResourceDTO[]>>>
  // setSelectedEllipseIndex: React.Dispatch<React.SetStateAction<SelectedEllipseIndex>>;
  setRefresh: React.Dispatch<React.SetStateAction<Refresh>>;
  refresh: Refresh;
}

function OperationsMapContainer({
  bookingResources,
  bookingsDetails,
  setSelectedVesselId,
  selectedDoor,
  setSelectedDoor,
  vesselOrientations,
  setVesselOrientations,
  imageUrls,
  setImageUrls,
  setShowGraphsFlag,
  filteredBookings,
  setDoorSlopeDataAssemblies,
  mooringLines,
  mooringLineBookingResources,
  setMooringLineBookingResources,
  deletedMLBR,
  setDeletedMLBR,
  setRefresh,
  refresh,
}: OperationsMapContainerProps) {
  // Hooks to fetch background image and booking details

  const { fetchMooringLineBookingResource } =
    useMooringLineBookingResourceRequests();

  const { backgroundImage } = useBookingRequestOperation();
  const [currentVesselPositions, setCurrentVesselPositions] = useState<
    Record<number, VesselPositions>
  >({});

  // set where mooring lines should be placed, based on where vessel is placed or dragged to.
  const [mooringLinePositions, setMooringLinePositions] = useState<Record<number, VesselPositions>>({});

  // UI state
  const [selectedLineId, setSelectedLineId] = useState<number | null>(null);

  // Refs for Konva components
  const stageRef = useRef<Konva.Stage>(null);
  const transformerRef = useRef<Konva.Transformer>(
    null
  ) as React.RefObject<Konva.Transformer>;

  // Images
  const [rotationImage] = useImage("/images/Rotate3.png");
  const [saveImage] = useImage("/images/saveDisk.png");

  // Default background image dimensions
  const imgWidthString = localStorage.getItem("backgroundImageWidth") || "";
  const imgHeightString = localStorage.getItem("backgroundImageHeight") || "";
  const imageWidth = Number(imgWidthString) || 1000;
  const imageHeight = Number(imgHeightString) || 1000;

  // Memoize vessel factor lookup
  const fetchVesselFactor = useCallback((vesselId: number): number => {
    return VesselFactors[vesselId] || 1;
  }, []);

  const saveVesselPosition = useSaveVesselPosition();
  const { updateGangwayResourcePosition } = useBookingResourceOperations();
  const { setMooringLineResource, deleteMooringLineResource } = useMooringLineBookingResourceRequests();



  const [
    portResourcesPositionAndRotation,
    setPortResourcesPositionAndRotation,
  ] = useState<Record<number, ResourceInformationComponentProps>>({});

  // Handle vessel dragging
  const handleDragEnd = useCallback(
    (event: KonvaEventObject<DragEvent>, vesselBookingId: number) => {
      const { x, y } = event.target.position();
      const position: VesselPositions = { x, y };
      setCurrentVesselPositions((prev) => ({
        ...prev,
        [vesselBookingId]: position,
      }));
      setMooringLinePositions((prev) => ({
        ...prev,
        [vesselBookingId]: position,
      }));
    },
    []
  );

  // handle mooring line draggin while the vessel is being dragged
  const handleDragMove = useCallback(
    (event: KonvaEventObject<DragEvent>, vesselBookingId: number) => {
      const { x, y } = event.target.position();
      // Update vessel position
      setCurrentVesselPositions((prev) => ({
        ...prev,
        [vesselBookingId]: { x, y },
      }));
      // Update mooring line positions 
      setMooringLinePositions((prev) => ({
        ...prev,
        [vesselBookingId]: { x, y },
      }));
    },
    []
  );

  useEffect(() => {
    console.log("hello from container, refresh.")
    setMooringLinePositions([])
  }, [refresh])

  // Exact position of the resource
  useEffect(() => {
    setPortResourcesPositionAndRotation({});
    bookingResources.forEach((bookingResource) => {
      const xFactor =
        bookingResource.xCoordinateFactor ??
        bookingResource.defaultXCoordinateFactor ??
        1;

      const yFactor =
        bookingResource.yCoordinateFactor ??
        bookingResource.defaultYCoordinateFactor ??
        1;
      const rotation =
        bookingResource.rotation ?? bookingResource.defaultRotation ?? 0;

      const xAxis = imageWidth / (xFactor || 1);
      const yAxis = imageHeight / (yFactor || 1);

      const resourceProps: ResourceInformationComponentProps = {
        bookingId: bookingResource.bookingId,
        x: xAxis,
        y: yAxis,
        rotation: rotation,
        gangwayId: bookingResource.gangwayId || 0,
        gangwayLengthFactor: bookingResource.gangwayLengthFactor || 0,
        gangwayColorDark: bookingResource.gangwayColorDark || "blue",
        mooringLineId: bookingResource.mooringLineId || 0,
        resourceType: bookingResource.resourceType || "Gangway",
      };

      setPortResourcesPositionAndRotation((prev) => {
        return {
          ...prev,
          [bookingResource.id]: resourceProps,
        };
      });
    });
  }, [bookingResources, filteredBookings]);

  // Exact position of the vessel
  useEffect(() => {
    filteredBookings.forEach((booking) => {
      const xFactor = booking.xCoordinateFactor || 1;
      const yFactor = booking.yCoordinateFactor || 1;
      const xAxis = imageWidth / (xFactor || 1);
      const yAxis = imageHeight / (yFactor || 1);
      const position: VesselPositions = {
        x: xAxis,
        y: yAxis,
      };
      setCurrentVesselPositions((prev) => ({
        ...prev,
        [booking.id]: position,
      }));
    });
  }, [filteredBookings]);

  // Reset selection when clicking on stage
  const handleStageClick = useCallback(() => {
    if (!transformerRef.current) return;
    setSelectedLineId(null);
    transformerRef.current.nodes([]);
    transformerRef.current.getLayer()?.batchDraw();
  }, []);

  // Save vessel position
  const handleSaveVesselAndResourcePosition = useCallback(
    async (
      vesselBookingId: number,
      vesselOrientation: "PORT" | "STARBOARD"
    ) => {
      const position = currentVesselPositions[vesselBookingId]
      await saveVesselPosition(
        position.x,
        position.y,
        vesselBookingId,
        imageWidth,
        imageHeight,
        vesselOrientation
      );


    },
    [currentVesselPositions, imageWidth, imageHeight]
  );

  const handleSaveBookingResourcePosition = useCallback(
    async (
      bookingResourceId: number,
      xcordinate: number,
      ycordinate: number,
      rotation: number
    ) => {
      const xCoordinateFactor = imageWidth / xcordinate;
      const yCoordinateFactor = imageHeight / ycordinate;

      await updateGangwayResourcePosition(
        bookingResourceId,
        xCoordinateFactor,
        yCoordinateFactor,
        rotation
      );
    },
    []
  );

  // Handle vessel rotation
  const handleRotate = (bookingId: number, vessel: EnrichedVessel) => {
    setVesselOrientations((prev) => {
      const newOrientation = prev[bookingId] === "PORT" ? "STARBOARD" : "PORT";
      setImageUrls((prevImages) => ({
        ...prevImages,
        [bookingId]:
          vessel.images.find((img) => img.orientation === newOrientation)
            ?.imagePath || "",
      }));
      return { ...prev, [bookingId]: newOrientation };
    });
  };

  useEffect(() => {
    console.log("Deleted mlbr from container: ", deletedMLBR)
  }, [deletedMLBR])

  return (
    <div
    // className="stage-container overflow-auto" // Add overflow for scrolling
    // style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <Stage
        width={imageWidth}
        height={imageHeight}
        ref={stageRef}
        onClick={handleStageClick}
      >
        <Layer>
          <Image
            image={BackgroundComponent()}
            x={0}
            y={0}
            width={imageWidth}
            height={imageHeight}
            onClick={() => {
              setShowGraphsFlag(false);
              setSelectedDoor(null);
            }}
          />
        </Layer>

        <Layer>
          <PortResources
            gangways={bookingsDetails?.gangways ?? []}
            platforms={bookingsDetails?.platforms ?? []}
            bookingResources={bookingResources}
            selectedLineId={selectedLineId}
            setSelectedLineId={setSelectedLineId}
            transformerRef={transformerRef}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            setPortResourcesPositionAndRotation={setPortResourcesPositionAndRotation}
            portResourcesPositionAndRotation={portResourcesPositionAndRotation}
          />
          <Ruler />

          {filteredBookings.map((booking, index) => (
            <Group
              key={index}
              x={imageWidth / booking.xCoordinateFactor}
              y={imageHeight / booking.yCoordinateFactor}
              draggable
              onDragMove={(e) => handleDragMove(e, booking.id)}
              onDragEnd={(e) => handleDragEnd(e, booking.id)}
              rotation={
                booking.berthName === "EAST"
                  ? 270
                  : 0 || booking.berthName === "DIAMOND JUBILEE"
                    ? -14
                    : 0
              }
            >
              <VesselImage
                url={imageUrls[booking.id] || ""}
                x={0}
                y={0}
                scaleX={fetchVesselFactor(booking.vesselId)}
                scaleY={fetchVesselFactor(booking.vesselId)}
                rotation={vesselOrientations === "PORT" ? 360 : 0}
              />
              {booking.vessel.passengerDoors ? (
                <RenderDoors
                  booking={booking}
                  doors={booking.vessel.passengerDoors}
                  scale={fetchVesselFactor(booking.vesselId)}
                  vesselOrientation={
                    vesselOrientations[booking.id] as "PORT" | "STARBOARD"
                  }
                  bookingResources={bookingResources}
                  portResources={bookingsDetails?.gangways ?? []}
                  selectedDoor={selectedDoor}
                  setSelectedDoor={setSelectedDoor}
                  setSelectedVesselId={setSelectedVesselId}
                  setShowGraphsFlag={setShowGraphsFlag}
                  setDoorSlopeDataDataAssemblies={setDoorSlopeDataAssemblies}
                />
              ) : null}
              {booking.vessel.shoreConnectionDoors ? (
                <RenderDoors
                  booking={booking}
                  doors={booking.vessel.shoreConnectionDoors}
                  scale={fetchVesselFactor(booking.vesselId)}
                  vesselOrientation={
                    vesselOrientations[booking.id] as "PORT" | "STARBOARD"
                  }
                  bookingResources={bookingResources}
                  portResources={bookingsDetails?.gangways ?? []}
                  selectedDoor={selectedDoor}
                  setSelectedDoor={setSelectedDoor}
                  setSelectedVesselId={setSelectedVesselId}
                  setShowGraphsFlag={setShowGraphsFlag}
                  setDoorSlopeDataDataAssemblies={setDoorSlopeDataAssemblies}
                />
              ) : null}
              <Image
                image={rotationImage}
                width={40}
                height={40}
                x={-15}
                y={20}
                offsetX={20}
                offsetY={20}
                onClick={() => handleRotate(booking.id, booking.vessel)}
              />
              <Image
                data-html2canvas-ignore
                image={saveImage}
                width={25}
                height={30}
                x={-5}
                y={70}
                offsetX={20}
                offsetY={20}
                onClick={async () => {
                  const savePromises: Promise<any>[] = [];
                  // Save mooring line positions
                  Object.values(mooringLineBookingResources).forEach((mooringLineResourceArray) => {
                    mooringLineResourceArray.forEach((mooringLineResource) => {
                      if (mooringLineResource.bookingId === booking.id) {
                        const mooringLinePromise = setMooringLineResource(
                          mooringLineResource.bookingId,
                          mooringLineResource.mooringLineId,
                          mooringLineResource.xCoordinateFactor,
                          mooringLineResource.yCoordinateFactor
                        );
                        savePromises.push(mooringLinePromise);
                      }
                    });
                  });


                  const deletedResources = deletedMLBR[booking.id] || [];
                  deletedResources.forEach((mooringLineResource) => {
                    const mooringLinePromise = deleteMooringLineResource(
                      mooringLineResource.bookingId,
                      mooringLineResource.mooringLineId
                    );
                    savePromises.push(mooringLinePromise);
                  });

                  bookingResources.forEach((bookingResource) => {
                    if (bookingResource.bookingId === booking.id) {
                      // Store the promise for concurrent execution
                      const xcordinate =
                        portResourcesPositionAndRotation[bookingResource.id]
                          ?.x || 1;
                      const ycordinate =
                        portResourcesPositionAndRotation[bookingResource.id]
                          ?.y || 1;
                      const rotation =
                        portResourcesPositionAndRotation[bookingResource.id]
                          ?.rotation || 0;
                      savePromises.push(
                        handleSaveBookingResourcePosition(
                          bookingResource.id,
                          xcordinate,
                          ycordinate,
                          rotation
                        )
                      );
                    }
                  });

                  // Add vessel save operation to the list
                  savePromises.push(
                    handleSaveVesselAndResourcePosition(
                      booking.id,
                      vesselOrientations[booking.id] as "PORT" | "STARBOARD"
                    )
                  );

                  try {
                    await Promise.all(savePromises);
                    setDeletedMLBR((prevDeleted) => ({
                      ...prevDeleted,
                      [booking.id]: [], // Clear deleted mooring lines for the current booking
                    }));
                    const updatedResources = await fetchMooringLineBookingResource(booking.id);
                    if (updatedResources) {
                      setMooringLineBookingResources((prevResources) => ({
                        ...prevResources,
                        [booking.id]: updatedResources, // Store the array under the booking ID
                      }));
                    }

                  } catch (error) {
                    console.error("Error saving positions:", error);
                  }
                  // finally {
                  //   setRefresh({
                  //     refresh: true,
                  //     showLoading: true,
                  //   })
                  // }
                }}
              />
            </Group>
          ))}

          {filteredBookings.map((booking, index) => (
            <MooringLines
              key={`mooring-${index}`}
              url={imageUrls[booking.id] || ""}
              // x={mooringLinePositions[booking.id]?.x || imageWidth / booking.xCoordinateFactor}
              // y={mooringLinePositions[booking.id]?.y || imageHeight / booking.yCoordinateFactor}
              x={mooringLinePositions[booking.id]?.x || imageWidth / booking.xCoordinateFactor}
              y={mooringLinePositions[booking.id]?.y || imageHeight / booking.yCoordinateFactor}
              scaleX={fetchVesselFactor(booking.vesselId)}
              scaleY={fetchVesselFactor(booking.vesselId)}
              orientation={vesselOrientations[booking.id] as "PORT" | "STARBOARD"}
              booking={booking}
              backgroundImageWidth={imageWidth}
              backgroundImageHeight={imageHeight}
              mooringLines={mooringLines}
              mooringLineBookingResources={mooringLineBookingResources}
              setMooringLineBookingResources={setMooringLineBookingResources}
              setDeletedMLBR={setDeletedMLBR}
              refresh={refresh}
            />
          ))}

        </Layer>

      </Stage>
    </div>
  );
}

export default OperationsMapContainer;

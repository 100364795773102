import { useState, useEffect, useRef } from "react";
import { Group, Circle, Line } from "react-konva";
import useImage from "use-image";
import { MooringLineDTO, MooringLineBookingResourceDTO } from "../../../Models/MooringLine";
import { EnrichedBooking } from "Models/VesselDoorDTO";
import { Refresh } from "Pages/Operation/Operation";

interface Target {
    id: number;
    lineName: string;
    orderNumber: number;
    x: number;
    y: number;
    radius: number;
    fill: string;
    bookingId?: number;
    anchorId?: number;
}

interface MooringLinesProps {
    url: string;
    x: number;
    y: number;
    scaleX: number;
    scaleY: number;
    orientation: string;
    booking: EnrichedBooking;
    backgroundImageWidth: number;
    backgroundImageHeight: number;
    mooringLines: MooringLineDTO[];
    mooringLineBookingResources: Record<number, MooringLineBookingResourceDTO[]>;
    setMooringLineBookingResources?: React.Dispatch<
        React.SetStateAction<Record<number, MooringLineBookingResourceDTO[]>>
    >;
    setDeletedMLBR?: React.Dispatch<React.SetStateAction<Record<number, MooringLineBookingResourceDTO[]>>>
    refresh?: Refresh;
}

const MooringLines: React.FC<MooringLinesProps> = ({
    url, x, y, scaleX, scaleY, orientation,
    booking, backgroundImageWidth, backgroundImageHeight, mooringLines,
    mooringLineBookingResources, setMooringLineBookingResources, setDeletedMLBR, refresh
}) => {
    const [vesselImage] = useImage(url);
    const vesselImageWidth = vesselImage ? vesselImage.width : 0;
    const vesselImageHeight = vesselImage ? vesselImage.height : 0;
    const [targets, setTargets] = useState<Target[]>([]);
    const [filteredMooringLines, setFilteredMooringLines] = useState<MooringLineDTO[]>([]);
    const [draggedTargets, setDraggedTargets] = useState<Record<number, { x: number; y: number }>>({});
    const originalX = useRef(x);
    const originalY = useRef(y);

    // Filter mooring lines based on the orientation of the vessel
    useEffect(() => {
        setFilteredMooringLines(mooringLines.filter(line => line.orientation === orientation));
    }, [mooringLines, orientation]);

    // Set targets based on mooring lines and booking resources
    useEffect(() => {

        const newTargets: Target[] = [];

        filteredMooringLines.forEach((mooringLine) => {
            let xPos = x + vesselImageWidth * scaleX * mooringLine.xPositionFactor;
            let yPos = y + vesselImageHeight * scaleY * mooringLine.yPositionFactor;

            const bookingResources = mooringLineBookingResources[booking.id] || [];
            const matchingResource = bookingResources.find(resource => resource.mooringLineId === mooringLine.mooringLineId);

            if (matchingResource) {
                xPos = backgroundImageWidth / matchingResource.xCoordinateFactor;
                yPos = backgroundImageHeight / matchingResource.yCoordinateFactor;
            }

            if (draggedTargets[mooringLine.mooringLineId]) {
                xPos = draggedTargets[mooringLine.mooringLineId].x;
                yPos = draggedTargets[mooringLine.mooringLineId].y;
            }

            newTargets.push({
                id: mooringLine.mooringLineId,
                lineName: mooringLine.lineName,
                orderNumber: mooringLine.orderNumber,
                x: xPos,
                y: yPos,
                radius: mooringLine.radius,
                fill: mooringLine.color,
                bookingId: booking.id,
                anchorId: mooringLine.anchorId,
            });
        });

        setTargets(newTargets);
    }, [filteredMooringLines, x, y, scaleX, scaleY, vesselImageWidth, vesselImageHeight, draggedTargets, mooringLineBookingResources]);

    useEffect(() => {
        setDraggedTargets({});
    }, [orientation]);

    useEffect(() => {
        console.log("hello from refresh: ", refresh, " x ", x, " y ", y)
        //setDraggedTargets({});
        //console.log("targets: ", targets)
    }, [refresh])

    // useEffect(() => {
    //     console.log("targets: ", targets)
    // }, [targets])

    // useEffect(() => {
    //     console.log("dragged targets from mooring lines: ", draggedTargets)
    // }, [draggedTargets])

    const handleDragMove = (e: any) => {
        const id = Number(e.target.id());
        const newX = e.target.x();
        const newY = e.target.y();

        setDraggedTargets(prev => ({
            ...prev,
            [id]: { x: newX, y: newY },
        }));

        if (setMooringLineBookingResources) {
            setMooringLineBookingResources(prevResources => {
                const bookingResources = prevResources[booking.id] || [];
                const existingResourceIndex = bookingResources.findIndex(resource => resource.mooringLineId === id);
                let updatedResources = [...bookingResources];

                if (existingResourceIndex !== -1) {
                    updatedResources[existingResourceIndex] = {
                        ...updatedResources[existingResourceIndex],
                        xCoordinateFactor: backgroundImageWidth / newX,
                        yCoordinateFactor: backgroundImageHeight / newY,
                    };
                } else {
                    updatedResources.push({
                        mooringLineBookingResourceId: 0,
                        mooringLineId: id,
                        bookingId: booking.id,
                        xCoordinateFactor: backgroundImageWidth / newX,
                        yCoordinateFactor: backgroundImageHeight / newY,
                    });
                }

                return {
                    ...prevResources,
                    [booking.id]: updatedResources,
                };
            });
        }
    };

    const handleDoubleClick = (id: number) => {
        const draggedTarget = targets.find(t => t.id === id && t.bookingId === booking.id);
        if (!draggedTarget) return;

        const mooringLine = filteredMooringLines.find(ml => ml.mooringLineId === id);
        if (!mooringLine) return;

        const resetX = x + vesselImageWidth * scaleX * mooringLine.xPositionFactor;
        const resetY = y + vesselImageHeight * scaleY * mooringLine.yPositionFactor;

        setTargets(prevTargets =>
            prevTargets.map(target => target.id === id ? { ...target, x: resetX, y: resetY } : target)
        );

        setDraggedTargets(prev => {
            const updatedDraggedTargets = { ...prev };
            delete updatedDraggedTargets[id];
            return updatedDraggedTargets;
        });

        if (setMooringLineBookingResources) {
            setMooringLineBookingResources(prevResources => {
                const updatedResources = (prevResources[booking.id] || []).filter(resource => resource.mooringLineId !== id);

                if (setDeletedMLBR) {
                    const removedResources = (prevResources[booking.id] || []).filter(resource => resource.mooringLineId === id);
                    setDeletedMLBR(prevDeleted => ({
                        ...prevDeleted,
                        [booking.id]: [...(prevDeleted[booking.id] || []), ...removedResources] // ✅ Correctly updating only for the current booking
                    }));
                }


                return {
                    ...prevResources,
                    [booking.id]: updatedResources,
                };
            });
        }
    };

    return (
        <Group>
            {targets.map(target => (
                <Circle
                    key={target.id}
                    name={target.lineName}
                    id={target.id.toString()}
                    x={target.x}
                    y={target.y}
                    radius={target.radius}
                    fill={target.fill}
                    draggable={target.orderNumber !== 0}
                    onDragMove={handleDragMove}
                    onDblClick={() => handleDoubleClick(target.id)}
                />
            ))}
            {filteredMooringLines.map(line => {
                const fromTarget = targets.find(target => target.lineName === `${line.placement}-${line.orderNumber}`);
                const toTarget = targets.find(target => target.lineName === `${line.placement}-${line.orderNumber + 1}`);
                if (fromTarget && toTarget) {
                    return <Line key={line.mooringLineId} points={[fromTarget.x, fromTarget.y, toTarget.x, toTarget.y]} stroke="black" />;
                }
                return null;
            })}
        </Group>
    );
};

export default MooringLines;

import "bootstrap/dist/css/bootstrap.css";
import {
  FaRegCalendarAlt,
  FaRegListAlt,
  FaEye,
  FaEyeSlash,
  FaHome,
} from "react-icons/fa";
import { FaRegSquarePlus } from "react-icons/fa6";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { use, useEffect, useState } from "react";
import Switch from "./Switch";
import BookingSearch from "./BookingSearch";

interface BookingHeaderProps {
  fetchBookingsByDate: (date: string) => void;
  fetchBookingByYear: (year: string) => void;
  setHideCancelled: React.Dispatch<React.SetStateAction<boolean>>;
  hideCancelled: boolean;
  yearsForDropdown: number[];
  setSelectedYear: React.Dispatch<React.SetStateAction<string>>;
  selectedYear: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  selectedDate: string;
  showCalendarView: () => void;
  showListView: () => void;
  setShowAddNewBookingModal: React.Dispatch<React.SetStateAction<boolean>>;
  navigateToHome: () => void; // Add this prop for navigation
  hideFilter: boolean;
  setShowBerth: React.Dispatch<React.SetStateAction<boolean>>;
  setGlobalFilter: (filterValue: string) => void;
  state: any;
  searchCalender: string;
  setSearchCalender: React.Dispatch<React.SetStateAction<string>>;
}

function BookingHeader({
  fetchBookingsByDate,
  fetchBookingByYear,
  setHideCancelled,
  hideCancelled,
  yearsForDropdown,
  setSelectedYear,
  selectedYear,
  setSelectedDate,
  selectedDate,
  showCalendarView,
  showListView,
  setShowAddNewBookingModal,
  navigateToHome,
  hideFilter, // Get the function to navigate to home
  setShowBerth,
  setGlobalFilter,
  state,
  searchCalender,
  setSearchCalender,
}: BookingHeaderProps) {
  const portConIcon =
    "/images/IconsAndLogos/PortconIconOutlinedFullColor300dpi.png";
  const [showList, setShowList] = useState(true);
  const [date, setDate] = useState<string>("");

  useEffect(() => {
    if (selectedDate !== "") {
      selectedDate.length === 0 && setDate("");
      // format to MM/DD/YYYY
      const formattedDate = moment(selectedDate).format("MM/DD/YYYY");
      setDate(formattedDate);
    }
  }, [selectedDate]);

  // Define color mapping for days of the week
  const dayColors = {
    Sunday: "bg-red-300 ",
    Monday: "bg-blue-300",
    Tuesday: "bg-green-300",
    Wednesday: "bg-yellow-300",
    Thursday: "bg-purple-300",
    Friday: "bg-pink-300",
    Saturday: "bg-gray-300",
  };

  const daysOfWeek: (keyof typeof dayColors)[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleToggle = (isChecked: boolean) => {
    setShowBerth(isChecked);
  };

    const { globalFilter } = state;

  return (
    <div className="flex p-2">
      {/* Main content area (header and other content) */}
      <div className="flex-1">
        <div className="bg-white top-0 left-0 w-full z-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x">
              {/* Home Button */}
              <button onClick={navigateToHome}>
                {/* <FaHome className="homeIcon" /> */}
                <img
                  src={portConIcon}
                  style={{ width: "75px", height: "auto", marginLeft: "10px" }}
                  alt="PortCon Logo"
                />
              </button>
              <h1 className="text-2xl font-bold bookingHeader">Bookings</h1>
            </div>
            <div className="flex space-x-4">
              {showList && (
                <button
                  data-tooltip-id="bookingPageIcons"
                  data-tooltip-content="Calendar View"
                  className="btn addBooking"
                  onClick={() => {
                    showCalendarView();
                    setShowList(false);
                  }}
                >
                  <FaRegCalendarAlt className="calendarViewIcon" />
                </button>
              )}
              {!showList && (
                <button
                  data-tooltip-id="bookingPageIcons"
                  data-tooltip-content="Table View"
                  className="btn addBooking"
                  onClick={() => {
                    showListView();
                    setShowList(true);
                  }}
                >
                  <FaRegListAlt className="listViewIcon" />
                </button>
              )}
              <button
                data-tooltip-id="operationsMapPageIcons"
                data-tooltip-content="Add New Booking"
                className="btn addBooking"
                onClick={() => setShowAddNewBookingModal(true)}
              >
                <FaRegSquarePlus className="addBookingIcon" />
              </button>
              <button
                className="btn toggleCancelled"
                onClick={() => setHideCancelled((prev) => !prev)}
              >
                {hideCancelled ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </button>
            </div>
          </div>

          {/* Filters */}
          {showList && (
            <div className="flex flex-wrap items-center gap-4 pl-4">
              {/* Year Selection */}
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => {
                    setSelectedYear("");
                    fetchBookingByYear("");
                  }}
                  className="px-3 py-1 border rounded hover:bg-gray-200"
                >
                  All Years
                </button>
                <button
                  onClick={() => {
                    setSelectedYear(new Date().getFullYear().toString());
                    fetchBookingByYear(new Date().getFullYear().toString());
                  }}
                  className="px-3 py-1 border rounded hover:bg-gray-200"
                >
                  This Year
                </button>
                <label className="text-gray-700">Select Year:</label>
                <select
                  className="border px-2 py-1 rounded"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {yearsForDropdown.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>

              {/* Date Selection */}
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => {
                    const date = new Date();
                    const localDate = moment(date).format();
                    const formattedDate = moment(date).format("MM/DD/YYYY");
                    fetchBookingsByDate(localDate);
                    setSelectedDate(formattedDate);
                  }}
                  className="px-3 py-1 border rounded hover:bg-gray-200"
                >
                  Today
                </button>
                <label className="text-gray-700">Select Date:</label>
                <DateTime
                  className="border px-2 py-1 rounded"
                  value={date}
                  onChange={(date) => {
                    if (date) {
                      const localDate = moment(date).format();
                      const formattedDate = moment(date).format("MM/DD/YYYY");
                      fetchBookingsByDate(localDate);
                      setSelectedDate(formattedDate);
                    }
                  }}
                  inputProps={{ placeholder: "Select Date", readOnly: true }}
                  timeFormat={false}
                />
              </div>

              {/* Search */}
              <BookingSearch
                filter={globalFilter}
                setFilter={setGlobalFilter}
              />
            </div>
          )}
        </div>
        {/* Day and Color Indicators (Stacked Vertically) */}
        {showList ? (
          <div className="mt-4 flex flex-wrap items-center gap-4 pl-4">
            {daysOfWeek.map((day) => (
              <div key={day} className="flex items-center space-x-2">
                <span className="font-semibold text-gray-700">{day}:</span>
                <span
                  className={`px-2 py-1 rounded-full text-white ${dayColors[day]}`}
                >
                  {/* This will show the color for each day */}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-between items-center gap-4 pl-4">
            {/* Search */}
            <BookingSearch
              filter={searchCalender}
              setFilter={setSearchCalender}
            />
            <Switch label="switch" onChange={handleToggle} />
          </div>
        )}
      </div>
    </div>
  );
}

export default BookingHeader;

import { TideDTO } from "Models/TideDTO";
import moment from "moment";
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { getTopTwoTides } from "../../Operations/OperationUtils/OperationUtility";
import { EnrichedBooking } from "Models/VesselDoorDTO";
import useBookingRequestOperation from "Services/BookingRequests";
import HwwVesselArrivalTideGraph from "./HwwVesselArrivalTideGraph";
import HwwVesselDepatureTideGraph from "./HwwVesselDepatureTideGraph";
import html2canvas from "html2canvas";
import NonHwwVesselArrivalTideGraph from "./NonHwwVesselArrivalTideGraph";
import NonHwwVesselDepatureTideGraph from "./NonHwwVesselDepatureTideGraph";

interface MyChartProps {
  bookings: EnrichedBooking;
  handleCapturedGraph: (imgData: string[]) => void;
  isHvvw?: boolean;
}

export interface ReferenceAreaItem {
  x1: string;
  x2: string;
  fill: string;
}

export interface DepartureDetails {
  popArrivalDate?: string;
  popDepartureDate?: string;
  vesselArrivalDate?: string;
  vesselDepartureDate?: string;
}

export interface ArrivalDetails {
  popArrivalDate?: string;
  popDepartureDate?: string;
  vesselArrivalDate?: string;
  vesselDepartureDate?: string;
}

interface HwwVesselReportGraphRef {
  captureGraphs: () => Promise<void>;
}

const HwwVesselReportGraph = forwardRef<HwwVesselReportGraphRef, MyChartProps>(
  ({ bookings, handleCapturedGraph, isHvvw }: MyChartProps, ref) => {
    const { fetchTides, fetchChannelDepth, loading } =
      useBookingRequestOperation();
    const [capturedImgData, setCapturedImgData] = useState<string | null>(null);
    const [mooringTideData, setMooringTideData] = useState<TideDTO[]>([]);
    const [topTwoMooringTides, setTopTwoMooringTides] = useState<TideDTO[]>([]);
    const [departureTideData, setDepartureTideData] = useState<TideDTO[]>([]);
    const [topTwoDepartureTides, setTopTwoDepartureTides] = useState<TideDTO[]>(
      []
    );
    const [channelDepth, setChannelDepth] = useState<number>(0);
    const referenceAreas: ReferenceAreaItem[] = [];
    const [mooringDetails, setMooringDetails] =
      useState<ArrivalDetails | null>();
    const [departureDetails, setDepartureDetails] =
      useState<DepartureDetails | null>();
    const [height, setHeight] = useState<number>(0);
    const graphArrivalRef = useRef<HTMLDivElement>(null);
    const graphDepartureRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (bookings?.mooringDateTime) {
        fetchTideData(bookings.mooringDateTime, "mooringDate").catch(
          (error) => {
            console.error("Error fetching mooring tide data:", error);
          }
        );
      }
      if (bookings?.departureDateTime) {
        fetchTideData(bookings.departureDateTime, "departureDate").catch(
          (error) => {
            console.error("Error fetching departure tide data:", error);
          }
        );
      }
    }, [bookings]);

    const fetchTideData = async (date: string, value: string) => {
      try {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        const response = await fetchTides(formattedDate);
        const channelDepthData = await fetchChannelDepth();

        if (Array.isArray(channelDepthData) && channelDepthData.length > 0) {
          setChannelDepth(channelDepthData[0].lce);
        }

        if (response) {
          if (value === "mooringDate") {
            setMooringTideData(response);
            setTopTwoMooringTides(getTopTwoTides(response));
            populateVesselArrival(response);
          } else if (value === "departureDate") {
            setDepartureTideData(response);
            setTopTwoDepartureTides(getTopTwoTides(response));
            populateDepartureDetails(response);
          }
        }
      } catch (error) {
        console.error("Error in fetchTideData:", error);
      }
    };

    const populateVesselArrival = (tideData: TideDTO[]) => {
      if (!tideData?.length) return;
      const normalizedData = tideData.map((item) => ({
        ...item,
        tideDateTime: moment(item.tideDateTime),
      }));
      const lastTide = normalizedData[normalizedData.length - 1]?.tideDateTime;
      const mooringDate = bookings?.mooringDateTime
        ? moment(bookings.mooringDateTime)
        : null;
      if (!mooringDate) return;
      const popArrivalDate = mooringDate
        .clone()
        .subtract(1, "hours")
        .subtract(15, "minutes");
      setMooringDetails({
        popArrivalDate: popArrivalDate.format(),
        popDepartureDate: mooringDate.format(),
        vesselArrivalDate: mooringDate.format(),
        vesselDepartureDate: lastTide?.format() || "",
      });
      setHeight(5);
    };

    const populateDepartureDetails = (tideData: TideDTO[]) => {
      if (!tideData?.length) return;
      const normalizedData = tideData.map((item) => ({
        ...item,
        tideDateTime: moment(item.tideDateTime),
      }));
      const firstTide = normalizedData[0]?.tideDateTime;
      const departureDate = bookings?.departureDateTime
        ? moment(bookings.departureDateTime)
        : null;
      if (!departureDate) return;
      const popArrivalDate = departureDate
        .clone()
        //.subtract(1, "hours")
        .subtract(15, "minutes");
      setDepartureDetails({
        popArrivalDate: popArrivalDate.format(),
        popDepartureDate: departureDate.format(),
        vesselArrivalDate: firstTide?.format() || "",
        vesselDepartureDate: departureDate.format(),
      });
      setHeight(5);
    };

    const captureArrivalGraph = () => {
      if (graphArrivalRef.current) {
        html2canvas(graphArrivalRef.current).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          handleCapturedGraph([imgData]);
          setCapturedImgData(imgData);
        });
      }
    };

    const captureDepatureGraph = () => {
      if (graphDepartureRef.current) {
        html2canvas(graphDepartureRef.current).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          handleCapturedGraph([imgData]);
          setCapturedImgData(imgData);
        });
      }
    };
    useEffect(() => {
      const timer = setTimeout(() => {
        captureArrivalGraph();
        captureDepatureGraph();
      }, 3000);
      return () => clearTimeout(timer);
    }, []);
    return (
      <div>
        {isHvvw ? (
          <>
            {mooringDetails && (
              <HwwVesselArrivalTideGraph
                mooringTideData={mooringTideData}
                topTwoMooringTides={topTwoMooringTides}
                mooringDetails={mooringDetails}
                channelDepth={channelDepth}
                height={height}
                bookings={bookings}
                graphRef={graphArrivalRef}
                // Add any props specific to isHvvw = true here
              />
            )}
            {departureDetails && (
              <HwwVesselDepatureTideGraph
                departureTideData={departureTideData}
                topTwoDepartureTides={topTwoDepartureTides}
                departureDetails={departureDetails}
                channelDepth={channelDepth}
                height={height}
                bookings={bookings}
                graphRef={graphDepartureRef}
                // Add any props specific to isHvvw = true here
              />
            )}
          </>
        ) : (
          <>
            {mooringDetails && (
              <NonHwwVesselArrivalTideGraph
                mooringTideData={mooringTideData}
                topTwoMooringTides={topTwoMooringTides}
                mooringDetails={mooringDetails}
                channelDepth={channelDepth}
                height={height}
                bookings={bookings}
                graphRef={graphArrivalRef}
                // Add any props specific to isHvvw = false here
              />
            )}
            {departureDetails && (
              <NonHwwVesselDepatureTideGraph
                departureTideData={departureTideData}
                topTwoDepartureTides={topTwoDepartureTides}
                departureDetails={departureDetails}
                channelDepth={channelDepth}
                height={height}
                bookings={bookings}
                graphRef={graphDepartureRef}
                // Add any props specific to isHvvw = false here
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default HwwVesselReportGraph;

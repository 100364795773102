import { DateTime } from "luxon";

export class TimeUtils {
  // Get current time in the user's local time zone or from the stored time zone
  static getTimeNow(): DateTime {
    const timeZoneName =
      localStorage.getItem("timeZoneName") ||
      sessionStorage.getItem("timeZoneName");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return DateTime.now().setZone(timeZoneName || timeZone);
  }

  // Get current time in UTC format
  static getTimeNowUTC(): DateTime {
    const timeZoneName =
      localStorage.getItem("timeZoneName") ||
      sessionStorage.getItem("timeZoneName");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return DateTime.now()
      .setZone(timeZoneName || timeZone)
      .toUTC();
  }

  // Static method to format DateTime with the given format string
  static formatDateTime(time: DateTime, format: string): string {
    const timeZoneName =
      localStorage.getItem("timeZoneName") ||
      sessionStorage.getItem("timeZoneName");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Make sure the time zone is correctly set
    const dt = time.setZone(timeZoneName || timeZone);

    // Format using the correct format string
    return dt.toFormat(format);
  }

  // Convert given DateTime to UTC format
  static convertToUTC(myTime: DateTime): DateTime {
    const timeZoneName =
      localStorage.getItem("timeZoneName") ||
      sessionStorage.getItem("timeZoneName");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return myTime.setZone(timeZoneName || timeZone).toUTC();
  }

  // Convert UTC time to the user's local time
  static convertToMyTimeFromUTC(myTime: DateTime): DateTime {
    const timeZoneName =
      localStorage.getItem("timeZoneName") ||
      sessionStorage.getItem("timeZoneName");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return myTime.setZone(timeZoneName || timeZone);
  }

  // Convert UTC time to user's local time with custom format
  static convertToMyTimeFromUTCWithFormat(
    myTime: DateTime,
    format: string
  ): string {
    const timeZoneName =
      localStorage.getItem("timeZoneName") ||
      sessionStorage.getItem("timeZoneName");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return myTime.setZone(timeZoneName || timeZone).toFormat(format);
  }

  // Convert a DateTime object to UTC string for saving to DB
  static convertToUTCString(myTime: string): string {
    const timeZoneName =
      localStorage.getItem("timeZoneName") ||
      sessionStorage.getItem("timeZoneName");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return DateTime.fromISO(myTime)
      .setZone(timeZoneName || timeZone)
      .toUTC()
      .toString();
  }

  // Convert UTC string to user's local time format with a custom format
  static convertFromUtcToMyTimeString(utcTime: string, format: string): string {
    const timeZoneName =
      localStorage.getItem("timeZoneName") ||
      sessionStorage.getItem("timeZoneName");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return DateTime.fromISO(utcTime)
      .setZone(timeZoneName || timeZone)
      .toFormat(format);
  }

  // Convert a given DateTime object to an ISO string for storage or display
  // Returns an empty string if the input is null or undefined
  static convertToISOString(myTime: DateTime): string {
    return myTime.toISO() || "";
  }
}

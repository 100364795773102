import { Ellipse } from "react-konva";
import { VesselDoorDTO } from "../../../Models/VesselDoorDTO";
import { BookingData, BookingResource } from "../../../Models/Booking";
import useCalculateRequest from "../../../Services/CalculationRequest";
import { GangwayDTO } from "Models/GangwayDTO";
import { CalculationsDTO } from "Models/CalculationsDTO";
import { useEffect, useState } from "react";

/**
 * DoorRendringProps interface defines the props for the RenderDoors component.
 */
interface DoorRendringProps {
  /** The booking data for the vessel. */
  booking: BookingData;
  /** An array of vessel doors. */
  doors: VesselDoorDTO[];
  /** The scaling factor for the doors. */
  scale: number;
  /** The orientation of the vessel ("PORT" or "STARBOARD"). */
  vesselOrientation: "PORT" | "STARBOARD";
  /** An array of booking resources. */
  bookingResources: BookingResource[];
  /** An array of port resources (gangways). */
  portResources: GangwayDTO[];
  /** The currently selected door (optional). */
  selectedDoor?: VesselDoorDTO | null;
  /** Function to set the selected door. */
  setSelectedDoor?: React.Dispatch<React.SetStateAction<VesselDoorDTO | null>>;
  /** Function to set the selected vessel ID. */
  setSelectedVesselId?: React.Dispatch<React.SetStateAction<number | null>>;
  /** Function to set the flag for showing graphs. */
  setShowGraphsFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  /** Function to set the door slope data assemblies. */
  setDoorSlopeDataDataAssemblies?: React.Dispatch<
    React.SetStateAction<CalculationsDTO[]>
  >;
}

export enum DoorType {
  SHORE_CONNECTION = "shore connection",
  PASSENGER = "passenger",
}
/**
 * RenderDoors component renders vessel doors as ellipses on a Konva canvas.
 *
 * @param {DoorRendringProps} props - The component properties.
 * @returns {JSX.Element} The rendered RenderDoors component.
 */
const RenderDoors: React.FC<DoorRendringProps> = ({
  booking,
  doors,
  scale,
  vesselOrientation,
  bookingResources,
  portResources,
  setSelectedVesselId,
  setShowGraphsFlag,
  setDoorSlopeDataDataAssemblies,
  selectedDoor,
  setSelectedDoor,
}) => {
  const { fetchSlopData, fetchDifference } = useCalculateRequest();

  /**
   * Helper function to get the color based on gangway ID.
   *
   * @param {number} doorId - The ID of the vessel door.
   * @param {GangwayDTO[]} portResources - An array of port resources (gangways).
   * @returns {string} The color of the gangway or "transparent" if not found.
   */
  const getColorByGangwayId = (
    doorId: number,
    portResources: GangwayDTO[]
  ): string => {
    const resource = bookingResources.find(
      (resource) => resource.vesselDoorId === doorId
    );
    if (!resource) return "transparent";
    const matchingResource = portResources.find(
      (resource2) => resource2.gangwayId === resource.gangwayId
    );
    return matchingResource?.colorLight || "transparent";
  };

  /**
   * Handles the door click event and fetches slope data.
   *
   * @param {string} mooringDateTime - The mooring date and time.
   * @param {string} departureDateTime - The departure date and time.
   * @param {number} doorId - The ID of the clicked door.
   * @param {number} berthId - The ID of the berth.
   * @param {React.Dispatch<React.SetStateAction<CalculationsDTO[]>>} [setDoorSlopeDataAssemblies] - Function to set the door slope data assemblies.
   * @param {React.Dispatch<React.SetStateAction<boolean>>} [setShowGraphsFlag] - Function to set the flag for showing graphs.
   */
  const handleDoorClick = async (
    mooringDateTime: string,
    departureDateTime: string,
    doorId: number,
    berthId: number,
    doorType: string,
    setDoorSlopeDataAssemblies?: React.Dispatch<
      React.SetStateAction<CalculationsDTO[]>
    >,
    setShowGraphsFlag?: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      /**
       * Converts a date string to UTC Z format.
       *
       * @param {string} dateString - The date string to convert.
       * @returns {string} The date string in UTC Z format.
       */
      const toUtcZFormat = (dateString: string): string =>
        new Date(dateString).toISOString();

      const adjustedMooringDateTime = toUtcZFormat(mooringDateTime);
      const adjustedDepartureDateTime = toUtcZFormat(departureDateTime);
      if (doorType.toLowerCase() === DoorType.SHORE_CONNECTION) {
        const slopeCalculation: CalculationsDTO[] | void =
          await fetchDifference(
            adjustedMooringDateTime,
            adjustedDepartureDateTime,
            doorId,
            berthId
          );

        if (slopeCalculation && slopeCalculation.length > 0) {
          if (setDoorSlopeDataAssemblies) {
            setDoorSlopeDataAssemblies(slopeCalculation);
          }

          if (setShowGraphsFlag) {
            setShowGraphsFlag(true);
          }
        }
      } else if (doorType.toLowerCase() === DoorType.PASSENGER) {
        const slopeCalculation: CalculationsDTO[] | void = await fetchSlopData(
          adjustedMooringDateTime,
          adjustedDepartureDateTime,
          doorId,
          berthId
        );

        if (slopeCalculation && slopeCalculation.length > 0) {
          if (setDoorSlopeDataAssemblies) {
            setDoorSlopeDataAssemblies(slopeCalculation);
          }

          if (setShowGraphsFlag) {
            setShowGraphsFlag(true);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching slope data:", error);
    }
  };

  // Rendering the doors
  return (
    <>
      {doors.map((door, index) => {
        const x =
          (vesselOrientation === "PORT"
            ? door.portOrientX ?? 0
            : door.starOrientX ?? 0) * scale;
        const y =
          (vesselOrientation === "PORT"
            ? door.portOrientY ?? 0
            : door.starOrientY ?? 0) * scale;
        const radiusX = ((door.ellipseWidth ?? 0) * scale) / 2;
        const radiusY = ((door.ellipseHeight ?? 0) * scale) / 2;

        return (
          <Ellipse
            key={door.id || index} // Use door id as key if available
            x={x}
            y={y}
            radiusX={radiusX}
            radiusY={radiusY}
            fill={
              selectedDoor && selectedDoor.id === door.id
                ? "rgba(255, 255, 0, 0.5)"
                : getColorByGangwayId(door.id, portResources)
            }
            onClick={async () => {
              const clickedDoor = door;
              setSelectedDoor?.(clickedDoor);
              setSelectedVesselId?.(booking.vesselId);

              // Trigger fetching slope data
              await handleDoorClick(
                booking.mooringDateTime,
                booking.departureDateTime,
                clickedDoor.id,
                booking.berthId || 0,
                clickedDoor.doorType,
                setDoorSlopeDataDataAssemblies,
                setShowGraphsFlag
              );
            }}
          />
        );
      })}
    </>
  );
};

export default RenderDoors;

import React from "react";

const CustomLoader = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="w-16 h-16 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin mb-4"></div>
      <span className="text-lg text-gray-500">Loading...</span>
    </div>
  );
};


export default CustomLoader;

import { useState, useEffect, useRef, useCallback, use } from "react";
import { Stage, Layer, Image as KonvaImage, Group, Line } from "react-konva";
import "moment-timezone";
import "bootstrap/dist/css/bootstrap.css";
import "react-datetime/css/react-datetime.css";
import ReportsGraphs from "./ReportsGraphs";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import moment from "moment";
import ReportsTide from "./ReportsTide";
import useBookingResourceOperations from "../../Services/BookingResourcesRequest";
import useBookingRequestOperation, {
  BookingsDetailsData,
} from "../../Services/BookingRequests";
import { TideDTO } from "Models/TideDTO";
import { BookingResource } from "Models/Booking";
import { EnrichedBooking, VesselDoorDTO } from "Models/VesselDoorDTO";
import { CalculationsDTO } from "Models/CalculationsDTO";
import Konva from "konva";
import ReportsDayReportPDF from "./ReportsDayReportPDF";
import { saveAs } from "file-saver";
import VesselImage from "../Operations/CustomViews/VesselImage";
import { VesselFactors } from "../Operations/OperationUtils/VesselFactors";
import { GangwayDTO } from "Models/GangwayDTO";
import { PortResources } from "../Operations/PortResource/PortResource";
import { PlatformDTO } from "Models/PlatformDTO";
import RenderDoors from "../Operations/DoorRendring/AllDoorRendring";
import useCalculateRequest from "../../Services/CalculationRequest";
import { get } from "http";
import TideTable from "./TideTable";
import { getTopTides } from "../Operations/OperationUtils/OperationUtility";
import { ResourceInformationComponentProps } from "Components/Operations/OperationMap/OperationsMapContainer";
import HwwVesselReportGraph from "./HwwvTideGraphs/HwwvesselTideReportGraph";
import { isSameDayInUserTimezone } from "Components/Operations/OperationUtils/timeUtils";
import { MooringLineBookingResourceDTO, MooringLineDTO } from "Models/MooringLine";
import MooringLines from "Components/Operations/MooringLines/MooringLines";

interface CombinedData {
  bookingResourceId: number;
  bookingResource: BookingResource;
  bookings?: EnrichedBooking;
}
interface CalculationsWithBookingResource {
  bookingResourceId: number;
  slopeData: CalculationsDTO[];
}
interface ReportProps {
  selectedDate: string;
  closeModal: () => void;
  imageWidth: number;
  imageHeight: number;
  bookingDetails: BookingsDetailsData;
  bookingResources: BookingResource[];
  tideData: TideDTO[];
  bookings: EnrichedBooking[];
  gangway: GangwayDTO[];
  platforms: PlatformDTO[];
  vesselOrientations: Record<number, string>;
  imageUrls: Record<number, string>;
  mooringLines: MooringLineDTO[];
  mooringLineBookingResources: Record<number, MooringLineBookingResourceDTO[]>;

}

function NewReportPage({
  selectedDate,
  closeModal,
  imageHeight,
  imageWidth,
  bookingDetails,
  bookingResources,
  tideData,
  bookings,
  gangway,
  platforms,
  vesselOrientations,
  imageUrls,
  mooringLines,
  mooringLineBookingResources,
}: ReportProps) {
  // useRefreshToken();

  const { backgroundImage } = useBookingRequestOperation();

  const { fetchSlopData } = useCalculateRequest();
  const [tideTabelData, setTideTableData] = useState<TideDTO[]>([]);
  const [hasBookingData, setHasBookingData] = useState<boolean>();
  const [tideReady, setTideReady] = useState<boolean>();
  const [tideTableReady, setTideTableReady] = useState<boolean>();
  const [hasTide, setHasTide] = useState<boolean>();
  const [hasBookingResource, setHasBookingResource] = useState<boolean>();
  const [graphsReady, setGraphsReady] = useState<boolean>();
  const [hvvwGraphsReady, setHvvwGraphsReady] = useState<boolean>();
  const [reportInformation, setReportInformation] = useState<CombinedData[]>(
    []
  );
  const [slopeData, setSlopeData] = useState<CalculationsWithBookingResource[]>(
    []
  );
  const [graphImages, setGraphImages] = useState<string[]>([]);
  const [hvvwGraphImages, setHvvwGraphImages] = useState<string[]>([]);
  const [tideImage, setTideImage] = useState<string[]>([]);
  const [tideTableImage, setTideTableImage] = useState<string[]>([]);
  const [imageCaptureFlag, setImageCaptureFlag] = useState<boolean>(false);
  const [canvasImage, setCanvasImage] = useState<string>("");
  const [pdfBlob, setPdfBlob] = useState<Blob | undefined>(undefined);

  // Local state to track if data has been fetched
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [
    portResourcesPositionAndRotation,
    setPortResourcesPositionAndRotation,
  ] = useState<Record<number, ResourceInformationComponentProps>>({});

  const stageRef = useRef<Konva.Stage>(null);
  const modalRef = useRef(null);
  const transformFactor = 1;
  const portID =
    sessionStorage.getItem("userPortId") ||
    localStorage.getItem("userPortId") ||
    "";

  useEffect(() => {
    const makeRequest = async () => {
      try {
        // Prevent fetching data again if already fetched
        if (hasFetchedData) return; // flag to ensure we fetch only once

        // Set flags based on data availability
        const hasBookings = bookings.length > 0;
        const hasTides = tideData && tideData.length > 0;
        const hasBookingResource = bookingResources.length > 0;

        setHasBookingData(hasBookings);
        setHasTide(hasTides || false);
        setHasBookingResource(hasBookingResource);

        // Handle no data cases
        if (!hasBookings) {
          closeModal();
          alert("No vessel data found. Cannot generate the report.");
        }
        if (!hasTides) {
          alert("No tide data found. Will not show tide graph.");
        }
        if (!hasBookingResource) {
          alert("No graph data found. Will not show graphs.");
          setGraphsReady(true);
        }

        if (tideData) {
          setTideTableData(getTopTides(tideData));
        }

        // Combine booking and resource data
        const combinedData: CombinedData[] = bookingResources.map(
          (resource) => {
            const matchingBooking = bookingDetails.bookings.find(
              (booking) => booking.id === resource.bookingId
            );
            return {
              bookingResourceId: resource.id,
              bookingResource: resource,
              bookings: matchingBooking,
            };
          }
        );

        setReportInformation(combinedData);

        // Fetch slope data
        const slopDataPromises = combinedData.map(async (resource) => {
          if (resource) {
            const slopeCalculation = await fetchSlopData(
              moment(resource.bookings?.mooringDateTime).utc().format() || "",
              moment(resource.bookings?.departureDateTime).utc().format() || "",
              resource.bookingResource.vesselDoorId || 0,
              resource.bookings?.berthId || 0,
              resource.bookingResource.assemblyId || 0
            );
            if (slopeCalculation) {
              setSlopeData((prevState) => [
                ...prevState,
                {
                  bookingResourceId: resource.bookingResource.id,
                  slopeData: slopeCalculation || [],
                },
              ]);
            } else {
              closeModal();
              alert("No slope data found for booking");
              console.warn(
                `No slope data found for bookingId: ${resource.bookingResource.id}`
              );
            }
          }
        });

        await Promise.all(slopDataPromises);

        // Set the flag to true after the data has been fetched
        setHasFetchedData(true); // Flag set to true to prevent future calls
      } catch (error) {
        console.error("Error fetching booking data: ", error);
        alert("There was an error fetching booking data.");
      }
    };

    makeRequest();
  }, []);

  // Exact position of the resource
  useEffect(() => {
    setPortResourcesPositionAndRotation({});
    bookingResources.forEach((bookingResource) => {
      const xFactor =
        bookingResource.xCoordinateFactor ??
        bookingResource.defaultXCoordinateFactor ??
        1;

      const yFactor =
        bookingResource.yCoordinateFactor ??
        bookingResource.defaultYCoordinateFactor ??
        1;
      const rotation =
        bookingResource.rotation ?? bookingResource.defaultRotation ?? 0;

      const xAxis = imageWidth / (xFactor || 1);
      const yAxis = imageHeight / (yFactor || 1);

      const resourceProps: ResourceInformationComponentProps = {
        bookingId: bookingResource.bookingId,
        x: xAxis,
        y: yAxis,
        rotation: rotation,
        gangwayId: bookingResource.gangwayId || 0,
        gangwayLengthFactor: bookingResource.gangwayLengthFactor || 0,
        gangwayColorDark: bookingResource.gangwayColorDark || "blue",
      };

      setPortResourcesPositionAndRotation((prev) => {
        return {
          ...prev,
          [bookingResource.id]: resourceProps,
        };
      });
    });
  }, [bookingResources]);

  // Memoize vessel factor lookup
  const fetchVesselFactor = useCallback((vesselId: number): number => {
    return VesselFactors[vesselId] || 1;
  }, []);

  // Memoize capture functions
  const handleCapturedGraph = useCallback((imgData: string) => {
    setGraphImages((prevImages) => [...prevImages, imgData]);
    setGraphsReady(true);
  }, []);

  const handleCapturedTideGraph = useCallback((imgData: string) => {
    setTideImage((prevImages) => [...prevImages, imgData]);
    setTideReady(true);
  }, []);

  const handleCapturedTideTable = useCallback((imgData: string) => {
    setTideTableImage((prevImages) => [...prevImages, imgData]);
    setTideTableReady(true);
  }, []);

  const handleHVVCapturedGraph = useCallback((imgData: string[]) => {
    imgData.forEach((imgData) => {
      setHvvwGraphImages((prevImages) => [...prevImages, imgData]);
    });
    setHvvwGraphsReady(true);
  }, []);

  //call the generate report from here
  useEffect(() => {
    if (hasBookingData && tideReady && graphsReady && tideTableReady) {
      handleGeneratePDF();
    }
  }, [hasBookingData, tideReady, graphsReady, tideTableReady, hvvwGraphsReady]);
  //format the date
  const formattedDate = moment(selectedDate).format("MMMM DD, YYYY");

  //handle the download
  const handleDownload = (blob: Blob) => {
    // download with this formart title April 21, 2025 – Created March 3, 2025
    const date = moment(selectedDate).format("MMMM DD, YYYY");
    const createdDate = moment().format("MMMM DD, YYYY");
    const fileName = `${date} – Created ${createdDate}.pdf`; //`Report_${moment().format("YYYY-MM-DD")}.pdf`;

    saveAs(blob, fileName);
  };

  const handleGeneratePDF = useCallback(async () => {
    if (stageRef.current) {
      try {
        const canvas = await html2canvas(
          stageRef.current.getStage().container()
        );
        const imgData = canvas.toDataURL("image/png");
        setCanvasImage(imgData);
        setImageCaptureFlag(true);

        if (hasBookingResource === false) {
          console.warn("No graphs available for the report.");
          alert(
            "No graphs were generated. The report will not include graphs."
          );
        }

        if (hasTide === false) {
          console.warn("No tide data available for the report.");
          alert(
            "No tide data available. The report will be generated without it."
          );
        }

        const blob = await pdf(
          <ReportsDayReportPDF
            picture={imgData}
            graphs={hasBookingResource ? graphImages : []}
            selectedDate={formattedDate}
            tide={hasTide ? tideImage : []}
            tideTable={hasTide ? tideTableImage : []}
            hvvwGraphs={hvvwGraphImages}
          />
        ).toBlob();
        setPdfBlob(blob);
      } catch (error) {
        console.error("Error capturing canvas:", error);
      }
    } else {
      console.error("Stage reference is not available.");
    }
  }, [hasBookingResource, hasTide, graphImages, tideImage, formattedDate]);

  return (
    <div className="modalContainer-DayReport">
      <div className="pdfContainer-DayReport">
        {!pdfBlob && (
          <>
            <div className="pdfButtons">
              <div className="spinner"></div>
              <h5>Your report is generating</h5>
              <button
                type="button"
                className="btn cancelPDFDownload"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </>
        )}

        {pdfBlob && (
          <div className="pdfReportAndButtons">
            <div className="pdfButtonsContainer">
              <button
                type="button"
                className="btn downloadPDF"
                onClick={() => handleDownload(pdfBlob)}
              >
                Download PDF Report
              </button>
              <button
                type="button"
                className="btn closePDFDownload"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
            <PDFViewer width="100%" height="100%" showToolbar={false}>
              <ReportsDayReportPDF
                picture={canvasImage}
                graphs={graphImages}
                selectedDate={formattedDate}
                tide={tideImage}
                tideTable={tideTableImage}
                //reportInformation={reportInformation}
                hvvwGraphs={hvvwGraphImages}
              />
            </PDFViewer>
          </div>
        )}
      </div>

      <div ref={modalRef} className="reportContainer-DayReport">
        {slopeData.length > 0 ? (
          slopeData
            .sort((a, b) => {
              const vesselNameA =
                reportInformation.find(
                  (resource) =>
                    resource.bookingResource.id === a.bookingResourceId
                )?.bookingResource.vesselName || "";
              const vesselNameB =
                reportInformation.find(
                  (resource) =>
                    resource.bookingResource.id === b.bookingResourceId
                )?.bookingResource.vesselName || "";
              return vesselNameA.localeCompare(vesselNameB);
            })
            .map((data, index) => {
              const matchingBookingResource = reportInformation.find(
                (resource) =>
                  resource.bookingResource.id === data.bookingResourceId
              );
              const positiveSlopeCaution =
                data.slopeData[0].positiveSlopeCaution;
              const positiveSlopeAcceptable =
                data.slopeData[0].positiveSlopeAcceptable;
              const negativeSlopeAcceptable =
                data.slopeData[0].negativeSlopeAcceptable;
              const negativeSlopeCaution =
                data.slopeData[0].negativeSlopeCaution;
              return (
                <ReportsGraphs
                  key={index}
                  slopeData={data.slopeData[0]?.slopeCalculations}
                  mooringDateTime={
                    matchingBookingResource?.bookings?.mooringDateTime || ""
                  }
                  departureDateTime={
                    matchingBookingResource?.bookings?.departureDateTime || ""
                  }
                  vesselName={
                    matchingBookingResource?.bookings?.vesselName || ""
                  }
                  berth={
                    matchingBookingResource?.bookingResource?.berthName || ""
                  }
                  doorName={
                    matchingBookingResource?.bookingResource?.vesselDoorName ||
                    ""
                  }
                  vesselOrientation={
                    matchingBookingResource?.bookings?.vesselOrientation || ""
                  }
                  gangWay={
                    matchingBookingResource?.bookingResource?.gangwayName || ""
                  }
                  handleCapturedGraph={handleCapturedGraph}
                  positiveSlopeCaution={positiveSlopeCaution}
                  positiveSlopeAcceptable={positiveSlopeAcceptable}
                  negativeSlopeAcceptable={negativeSlopeAcceptable}
                  negativeSlopeCaution={negativeSlopeCaution}
                />
              );
            })
        ) : (
          <p>Loading graphs...</p>
        )}
        <TideTable
          handleCapturedTideTable={handleCapturedTideTable}
          tide={tideTabelData}
        />
        <ReportsTide
          handleCapturedTideGraph={handleCapturedTideGraph}
          tide={tideData}
        />
        {hasBookingData && portID === "2" && (
          <div>
            {bookings?.map((booking, index) => (
              <HwwVesselReportGraph
                key={index}
                bookings={booking}
                handleCapturedGraph={handleHVVCapturedGraph}
                isHvvw={booking.vessel.hwwv}
              />
            ))}
          </div>
        )}
        {hasFetchedData && (
          <div className="secondDiv-DayReport">
            <div className="stageContainer">
              <Stage
                width={imageWidth * transformFactor}
                height={imageHeight * transformFactor}
                ref={stageRef}
              >
                <Layer>
                  <KonvaImage
                    image={backgroundImage}
                    x={0}
                    y={0}
                    width={imageWidth * transformFactor}
                    height={imageHeight * transformFactor}
                  />
                </Layer>

                <Layer>
                  {/* <PortResources
                    gangways={gangway}
                    platforms={platforms}
                    bookingResources={bookingResources}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                  /> */}
                  <PortResources
                    gangways={gangway}
                    platforms={platforms}
                    bookingResources={bookingResources}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    portResourcesPositionAndRotation={
                      portResourcesPositionAndRotation
                    }
                  />
                  {bookings.map((booking, index) => (
                    <Group
                      key={index}
                      x={imageWidth / booking.xCoordinateFactor}
                      y={imageHeight / booking.yCoordinateFactor}
                      draggable
                      //onDragEnd={(e) => handleDragEnd(e, booking.id)}
                      rotation={
                        booking.berthName === "EAST"
                          ? 270
                          : 0 || booking.berthName === "DIAMOND JUBILEE"
                            ? -14
                            : 0
                      }
                    >
                      <VesselImage
                        url={imageUrls[booking.id] || ""}
                        x={0}
                        y={0}
                        scaleX={fetchVesselFactor(booking.vesselId)}
                        scaleY={fetchVesselFactor(booking.vesselId)}
                        rotation={vesselOrientations === "PORT" ? 360 : 0}
                      />
                      {booking.vessel.passengerDoors ? (
                        <RenderDoors
                          booking={booking}
                          doors={booking.vessel.passengerDoors}
                          scale={fetchVesselFactor(booking.vesselId)}
                          vesselOrientation={
                            vesselOrientations[booking.id] as
                            | "PORT"
                            | "STARBOARD"
                          }
                          bookingResources={bookingResources}
                          portResources={gangway}
                        />
                      ) : null}
                      {booking.vessel.shoreConnectionDoors ? (
                        <RenderDoors
                          booking={booking}
                          doors={booking.vessel.shoreConnectionDoors}
                          scale={fetchVesselFactor(booking.vesselId)}
                          vesselOrientation={
                            vesselOrientations[booking.id] as
                            | "PORT"
                            | "STARBOARD"
                          }
                          bookingResources={bookingResources}
                          portResources={gangway}
                        />
                      ) : null}
                    </Group>
                  ))}
                  {bookings.map((booking, index) => (
                    <MooringLines
                      key={`mooring-${index}`}
                      url={imageUrls[booking.id] || ""}
                      x={imageWidth / booking.xCoordinateFactor
                      }
                      y={imageHeight / booking.yCoordinateFactor
                      }
                      scaleX={fetchVesselFactor(booking.vesselId)}
                      scaleY={fetchVesselFactor(booking.vesselId)}
                      orientation={
                        vesselOrientations[booking.id] as "PORT" | "STARBOARD"
                      }
                      booking={booking}
                      backgroundImageWidth={imageWidth}
                      backgroundImageHeight={imageHeight}
                      mooringLines={mooringLines}
                      mooringLineBookingResources={mooringLineBookingResources}
                    />
                  ))}
                </Layer>
              </Stage>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default NewReportPage;

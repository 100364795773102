import { Column } from "react-table";
import { BookingData } from "../../../Models/Booking";
import { EnrichedBooking } from "Models/VesselDoorDTO";

const formattedTime = (value?: string) => {
  if (!value) return "--"; // Fallback for empty values
  const date = new Date(value);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

export const Columns: Column<EnrichedBooking>[] = [
  {
    Header: "Vessel",
    accessor: "vesselName",
  },
  {
    Header: "Mooring Time",
    accessor: "mooringDateTime",
    Cell: ({ cell: { value } }: { cell: { value?: string } }) =>
      formattedTime(value),
  },
  {
    Header: "Departure Time",
    accessor: "departureDateTime",
    Cell: ({ cell: { value } }: { cell: { value?: string } }) =>
      formattedTime(value),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ cell: { value } }: { cell: { value?: string } }) => (
      <span
        className={`badge badge-pill badge-${
          value === "CONFIRMED"
            ? "success"
            : value === "TENTATIVE"
            ? "warning"
            : "danger"
        }`}
      >
        {value ?? "UNKNOWN"}
      </span>
    ),
  },
  {
    Header: "Berth",
    accessor: "berthName",
  },
];

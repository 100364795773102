import { TooltipProps } from 'recharts';

import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';


export const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const formattedDate = new Date(label).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
     const slope = payload[0]?.payload?.slope;
    const formattedSlope = typeof slope === 'number' ? slope.toFixed(2) : "N/A";

    return (
      <div className="customTooltip">
        {`Time: ${formattedDate}    `}
        {`Slope: ${formattedSlope}`} 
      </div>
    );
  }

  return null;
};

export const CustomTooltipPower = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const formattedDate = new Date(label).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    const differenceFromDock = payload[0]?.value;
    const formattedDifferenceFromDock = typeof differenceFromDock === 'number' ? `${differenceFromDock.toFixed(2)} meters` : 'N/A';

    return (
      <div className="customTooltip">
        <div>{`Time: ${formattedDate}`}</div>
        <div>{`Dif. Fr. Dock: ${formattedDifferenceFromDock}`}</div>
      </div>
    );
  }

  return null;
};


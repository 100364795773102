import ReportPage from "Components/Reports/Report";
import ReportHeader from "Components/Reports/ReportHeader";
import React, { useEffect, useState } from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import useBookingRequestOperation from "Services/BookingRequests";

function Reports() {
  const { backgroundImage } = useBookingRequestOperation();
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [showReportModal, setShowReportModal] = useState(false);

  // Default background image dimensions
  const imageWidth = backgroundImage?.width || 1000;
  const imageHeight = backgroundImage?.height || 1000;

  // Close the modal
  const closeModal = () => setShowReportModal(false);

  return (
    <div className="flex flex-col h-screen">
      <ToastContainer />
      <ReportHeader setSelectedDate={setSelectedDate} />

      {/* Render report page if a report is selected, else show the empty state */}
      {selectedDate && showReportModal ? (
        <ReportPage
          selectedDate={selectedDate}
          closeModal={closeModal}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
        />
      ) : (
        <div className="flex items-center justify-center flex-grow bg-gray-100 p-4">
          <div className="bg-white shadow-lg rounded-2xl p-10 flex flex-col items-center">
            {/* Icon */}
            <FaRegFileAlt className="text-[#22418A] text-6xl mb-4" />

            {/* Message */}
            <h2 className="text-2xl font-semibold text-gray-700">
              No Reports Available
            </h2>
            <p className="text-gray-500 text-center mt-2">
              You haven't generated any reports yet. Click below to create a new
              report.
            </p>

            {/* Action Button */}
            <button
              className="mt-6 px-6 py-3 bg-[#22418A] text-white rounded-lg shadow-md hover:opacity-100 transition"
              onClick={() => {
                if (!selectedDate) {
                  toast.error("Select Date", {
                    position: "top-center",
                    hideProgressBar: true,
                  });
                  return;
                }
                setShowReportModal(true);
              }}
            >
              Generate Report
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Reports;

import "bootstrap/dist/css/bootstrap.css";
import {
  FaRegCalendarAlt,
  FaRegListAlt,
  FaEye,
  FaEyeSlash,
  FaHome,
} from "react-icons/fa";
import { FaFileExport, FaRegSquarePlus } from "react-icons/fa6";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { use, useEffect, useState } from "react";

interface ReportHeaderProps {
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
}

function ReportHeader({ setSelectedDate }: ReportHeaderProps) {
  const [selectedViewDate, setSelectedViewDate] = useState<string>("");

  return (
    <div className="flex p-2">
      {/* Main content area (header and other content) */}
      <div className="flex-1">
        <div className="bg-white top-0 left-0 w-full z-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x">
              {/* Home Button */}

              <h1 className="text-2xl font-bold bookingHeader">Reports</h1>
            </div>
            <div className="flex space-x-4">
              <button
                data-tooltip-id="bookingPageIcons"
                data-tooltip-content="Table View"
                className="btn addBooking"
                onClick={() => {}}
              >
                {/* <FaFileExport className="listViewIcon" /> */}
              </button>
            </div>
          </div>
          <div className="mt-4 flex flex-wrap items-center gap-4">
            {/* Date Selection */}
            <div className="flex items-center space-x-2">
              <label className="text-gray-700">Select Date:</label>
              <DateTime
                className="border px-2 py-1 rounded"
                value={selectedViewDate}
                onChange={(date) => {
                  if (date) {
                    const localDate = moment(date).format();
                    const displayDate = moment(date).format("MM/DD/YYYY");
                    const formattedDate = moment(date).format("YYYY-MM-DD");
                    setSelectedViewDate(displayDate);
                    setSelectedDate(formattedDate);
                  }
                }}
                inputProps={{ placeholder: "Select Date", readOnly: true }}
                timeFormat={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportHeader;

import React, { useMemo, useCallback, useState } from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FaMap } from "react-icons/fa";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer, SlotInfo, View } from "react-big-calendar";
import { BookingData } from "Models/Booking";
import { BerthData } from "Models/Berth";

interface BookingsCalendarProps {
  bookings: BookingData[];
  hideCancelled: boolean;
  handleSelect: (booking: any) => void;
  setShowNewBookingModalFromCalendar?: (value: boolean) => void;
  setSelectedDateFromCalendar: (date: Date) => void;
  setSelectedDate: (date: string) => void;
  berthNames: BerthData[];
  showBerthInCalendar?: boolean;
}

const BookingsCalendar = ({
  bookings,
  hideCancelled,
  handleSelect,
  setShowNewBookingModalFromCalendar,
  setSelectedDateFromCalendar,
  setSelectedDate,
  berthNames,
  showBerthInCalendar,
}: BookingsCalendarProps) => {
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);
  const [currentMonth, setCurrentMonth] = useState(moment());

  const bookingEvents = useMemo(() => {
    return bookings
      .filter((booking) => !hideCancelled || booking.status !== "CANCELLED")
      .map((booking) => ({
        title: booking.vesselName || "No Title",
        start: new Date(booking.mooringDateTime),
        end: new Date(booking.departureDateTime),
        status: booking.status,
        bookingId: booking.id,
        booking: booking,
        berthName:
          berthNames.find((berth) => berth.id === booking.berthId)?.name ||
          "Unknown Berth",
        date: moment(booking.mooringDateTime).format("YYYY-MM-DD"),
        type: "booking",
      }));
  }, [bookings, hideCancelled, berthNames]);

  const availableBerthEvents = useMemo(() => {
    if (!showBerthInCalendar) {
      return [];
    }

    const availableEvents: {
      title: string;
      start: Date;
      end: Date;
      status: string;
      berthName: string;
      date: string;
      type: string;
    }[] = [];

    const uniqueDates = Array.from(
      new Set(bookingEvents.map((event) => event.date))
    );

    const allDates = [];
    const startDate = moment(currentMonth).startOf("month");
    const endDate = moment(currentMonth).endOf("month");
    for (
      let m = moment(startDate);
      m.isSameOrBefore(endDate);
      m.add(1, "days")
    ) {
      allDates.push(m.format("YYYY-MM-DD"));
    }

    const datesWithoutEvents = allDates.filter(
      (date) => !uniqueDates.includes(date)
    );

    datesWithoutEvents.forEach((date) => {
      berthNames.forEach((berth) => {
        availableEvents.push({
          title: `${berth.name}`,
          start: moment(date).startOf("day").toDate(),
          end: moment(date).endOf("day").toDate(),
          status: "AVAILABLE",
          berthName: berth.name || "Unknown Berth",
          date: date,
          type: "available",
        });
      });
    });

    uniqueDates.forEach((date) => {
      const bookedBerthIds = bookingEvents
        .filter((event) => event.date === date)
        .map((event) => event.booking.berthId);

      const availableBerths = berthNames
        .filter((berth) => !bookedBerthIds.includes(berth.id))
        .map((berth) => ({
          name: berth.name,
          id: berth.id,
        }));

      availableBerths.forEach((berth) => {
        availableEvents.push({
          title: `${berth.name}`,
          start: moment(date).startOf("day").toDate(),
          end: moment(date).endOf("day").toDate(),
          status: "AVAILABLE",
          berthName: berth.name || "Unknown Berth",
          date: date,
          type: "available",
        });
      });
    });

    return availableEvents;
  }, [bookingEvents, berthNames, showBerthInCalendar, currentMonth]);

  const handleViewOperation = (booking: BookingData) => {
    const formattedDate = moment(booking.mooringDateTime).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    navigate("/operations", {
      state: formattedDate,
    });
  };

  const bookingComponents = useMemo(() => {
    return {
      event: (props: {
        event: {
          status: string;
          booking: BookingData;
          berthName: string;
          date: string;
          type: string;
        };
        title: string;
      }) => {
        const status = props?.event?.status as keyof typeof styles;
        const handleClick = () => {
          if (props.event.type === "booking") {
            handleSelect(props.event.booking);
          }
        };
        const styles = {
          TENTATIVE: "bg-yellow-400 text-black",
          CONFIRMED: "bg-green-600 text-white",
          CANCELLED: "bg-red-600 text-white",
        };

        return (
          <div
            className={`flex items-center p-2 rounded-md cursor-pointer ${styles[status]} h-6`}
          >
            <div className="flex-1 pr-2 truncate" onClick={handleClick}>
              {props.title}
              {showBerthInCalendar && (
                <div className="text-xs text-gray-500">
                  {props.event.berthName}
                </div>
              )}
            </div>
            <div>
              <button
                className={`mr-4 ${styles[status]} p-2 rounded-md`}
                onClick={() => handleViewOperation(props.event.booking)}
              >
                <FaMap className="text-xl" />
              </button>
            </div>
          </div>
        );
      },
    };
  }, [handleSelect, handleViewOperation, showBerthInCalendar]);

  const availableComponents = useMemo(() => {
    return {
      event: (props: {
        event: {
          status: string;
          berthName: string;
          date: string;
          type: string;
        };
        title: string;
      }) => {
        const styles = {
          AVAILABLE: "bg-gray-300 text-black",
        };

        return (
          <div
            className={`flex items-center p-2 rounded-md cursor-pointer ${styles.AVAILABLE} h-7`}
          >
            <div className="flex-1 pr-2 truncate">{props.title}</div>
          </div>
        );
      },
    };
  }, []);

  const handleSlotSelect = useCallback(
    (slotInfo: SlotInfo) => {
      if (setShowNewBookingModalFromCalendar) {
        setShowNewBookingModalFromCalendar(true);
        setSelectedDateFromCalendar(slotInfo.start);
      }
    },
    [setShowNewBookingModalFromCalendar, setSelectedDateFromCalendar]
  );

  const handleNavigate = useCallback((date: Date, view: View) => {
    if (view === "month") {
      setCurrentMonth(moment(date));
    }
  }, []);

  return (
    <div className="flex-1 overflow-y-auto p-4">
      {showBerthInCalendar ? (
        <Calendar
          localizer={localizer}
          events={availableBerthEvents}
          startAccessor="start"
          endAccessor="end"
          components={availableComponents}
          views={["month", "week", "day"]}
          selectable
          onSelectSlot={handleSlotSelect}
          onNavigate={handleNavigate}
        />
      ) : (
        <Calendar
          localizer={localizer}
          events={bookingEvents}
          startAccessor="start"
          endAccessor="end"
          components={bookingComponents}
          views={["month", "week", "day"]}
          selectable
          onSelectSlot={handleSlotSelect}
          onNavigate={handleNavigate}
        />
      )}
    </div>
  );
};

export default BookingsCalendar;

import { toast } from "react-toastify";
import { useApi } from "../api/api";
import { useCallback } from "react";
import { MooringLineDTO, MooringLineBookingResourceDTO } from "../Models/MooringLine";


export interface UseMooringLineRequests {
    fetchMooringLines: () => Promise<MooringLineDTO[] | undefined>;
}

const useMooringLineRequests = (): UseMooringLineRequests => {
    const { request } = useApi<MooringLineDTO[]>();


    // Fetch Mooring Lines
    const fetchMooringLines = useCallback(async (): Promise<MooringLineDTO[] | undefined> => {
        try {
            const apiUrl = `/MooringLine`;
            const response = await request(apiUrl, "GET");

            if (!Array.isArray(response)) {
                console.error("Unexpected API response format for MooringLines:", response);
                return undefined;
            }

            return response;
        } catch (error) {
            console.error("Error fetching mooring lines:", error);
            toast.error("Error fetching mooring lines");
            return undefined;
        }
    }, []);
    return { fetchMooringLines };
};

export default useMooringLineRequests;

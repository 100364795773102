import moment from "moment";

export function isSameDayInUserTimezone(
  departureDate: string,
  dateSelected: string
): boolean {
  const localSelectedDate = new Date(dateSelected);
  const localDepartureDate = new Date(departureDate);
  const compare =
    localSelectedDate.getFullYear() === localDepartureDate.getFullYear() &&
    localSelectedDate.getMonth() === localDepartureDate.getMonth() &&
    localSelectedDate.getDate() === localDepartureDate.getDate();
  return compare;
}

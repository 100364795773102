import React from 'react';
import { Line, Rect, Group } from 'react-konva';

interface PortResourcesPlatformProps {
  xPosition: number;
  yPosition: number;
  color: string;
  squareSize: number;
}

function PortResourcesPlatform({ xPosition, yPosition, color, squareSize }: PortResourcesPlatformProps) {


  const startX = xPosition;
  const startY = yPosition;

  const linesData = [
    // Make X
    {
      points: [
        startX + 2,
        startY + 2,
        startX + squareSize - 2,
        startY + squareSize - 2,
      ],
    },
    {
      points: [
        startX + squareSize - 2,
        startY + 2,
        startX + 2,
        startY + squareSize - 2,
      ],
    },
    // Make horizontal lines on either side of box
    {
      points: [
        startX - squareSize / 2,
        startY + squareSize / 2,
        startX,
        startY + squareSize / 2,
      ],
    },
    {
      points: [
        startX + squareSize,
        startY + squareSize / 2,
        startX + squareSize * 1.5,
        startY + squareSize / 2,
      ],
    },
    // Make end vertical line of (my) left side of box
    {
      points: [
        startX - squareSize / 2,
        startY,
        startX - squareSize / 2,
        startY + squareSize,
      ],
    },
    // Make end vertical line of (my) right side of box
    {
      points: [
        startX + squareSize * 1.5,
        startY,
        startX + squareSize * 1.5,
        startY + squareSize,
      ],
    },
    // Make two vertical lines on (my) left side of box
    {
      points: [
        startX - squareSize / 3,
        startY + squareSize / 4,
        startX - squareSize / 3,
        startY + (squareSize * 3) / 4,
      ],
    },
    {
      points: [
        startX - squareSize / 6,
        startY + squareSize / 4,
        startX - squareSize / 6,
        startY + (squareSize * 3) / 4,
      ],
    },
    // Make two vertical lines on (my) right side of box
    {
      points: [
        startX + (squareSize * 4) / 3,
        startY + squareSize / 4,
        startX + (squareSize * 4) / 3,
        startY + (squareSize * 3) / 4,
      ],
    },
    {
      points: [
        startX + (squareSize * 7) / 6,
        startY + squareSize / 4,
        startX + (squareSize * 7) / 6,
        startY + (squareSize * 3) / 4,
      ],
    },
  ];

  return (
    <Group>
      <Rect
        x={xPosition}
        y={yPosition}
        width={squareSize}
        height={squareSize}
        fill={color}
      />
      {linesData.map((line, index) => (
        <Line key={index} points={line.points} stroke="black" strokeWidth={1} />
      ))}
    </Group>
  );
}

export default PortResourcesPlatform;

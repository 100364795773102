import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import useBookingResourceOperations from "../../Services/BookingResourcesRequest";
import useBookingRequestOperation, {
  BookingsDetailsData,
} from "../../Services/BookingRequests";
import { EnrichedBooking, VesselDoorDTO } from "../../Models/VesselDoorDTO";
import {
  CalculationsDTO,
  SlopeCalculation,
} from "../../Models/CalculationsDTO";
import OperationGraphModaL from "../../Components/Operations/GraphsModals/OperationGraphModaL";
import OperationsShowGraphs from "../../Components/Operations/GraphsModals/OperationsShowGraphs";
import OperationsMapTide from "../../Components/Operations/Tides/OperationsMapTide";
import { TideDTO } from "../../Models/TideDTO";
import OperationsGangwayReservations from "../../Components/Operations/Gangway/OperationsGangwayReservations";
import OperationsMapContainer from "../../Components/Operations/OperationMap/OperationsMapContainer";
import OperationsBookingTable from "../../Components/Operations/Bookings/OperationBookingTable";
import ReportPage from "../../Components/Reports/Report";
import { BookingResource } from "../../Models/Booking";
import OperationHeader from "../../Components/Operations/OperationHeader/OperationHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { isSameDayInUserTimezone } from "Components/Operations/OperationUtils/timeUtils";
import moment from "moment";
import LoadingScreen from "Components/Loader/Loader";
import NewReportPage from "Components/Reports/NewReport";
import { useMooringLineBookingResourceRequests } from "Services/MooringLineBookingResourceRequests";
import useMooringLineRequests from "Services/MooringLineRequests";
import {
  MooringLineBookingResourceDTO,
  MooringLineDTO,
} from "Models/MooringLine";
import {
  fetchAllResourcesData,
  fetchDataFromAPI,
} from "Components/Operations/OperationRequests";
import { useDateContext } from "Contexts/DateContext";

export interface Refresh {
  refresh: boolean;
  showLoading: boolean;
}

/**
 * Operation component manages the main operational view, including bookings, tides, and resources.
 * It handles data fetching, state management, and rendering of various operational components.
 */
function Operation() {
  // Custom hooks for fetching booking and resource data
  const { backgroundImage, fetchBookingsWithDetails, fetchTides } =
    useBookingRequestOperation();
  const {
    fetchBookingResources,
    updateGangwayReservation,
    handleRemoveBookingResource,
  } = useBookingResourceOperations();

  const { fetchMooringLineBookingResource } =
    useMooringLineBookingResourceRequests();

  const { fetchMooringLines } = useMooringLineRequests();
  const [mooringLines, setMooringLines] = useState<MooringLineDTO[]>([]);
  const [mooringLineBookingResources, setMooringLineBookingResources] =
    useState<Record<number, MooringLineBookingResourceDTO[]>>({});

  const [deletedMLBR, setDeletedMLBR] = useState<Record<number, MooringLineBookingResourceDTO[]>>({});


  // React Router hooks for navigation and location
  const location = useLocation();
  const navigate = useNavigate();
  const [locationState, setLocationState] = useState(location.state);

  // Default background image dimensions
  const imageWidth = backgroundImage?.width || 1000;
  const imageHeight = backgroundImage?.height || 1000;

  // State variables for UI and data management
  const [showDayReportModal, setShowDayReportModal] = useState(false);
  const [showGraphModal, setShowGraphModal] = useState(false);
  const [modalVesselDoorId, setModalVesselDoorId] = useState<number>(0);
  const [modalVesselDoorName, setModalVesselDoorName] = useState<string>("");
  const [modalBooking, setModalBooking] = useState<EnrichedBooking | null>(
    null
  );
  const [vesselDoorHeight, setModalVesselDoorHeight] = useState<number>(0);
  const [doorSlopeDataAssemblies, setDoorSlopeDataAssemblies] = useState<
    CalculationsDTO[]
  >([]);
  const [bookingsDetails, setBookingDetails] =
    useState<BookingsDetailsData | null>(null);
  const [tides, setTides] = useState<TideDTO[]>([]);
  const [slopeCalculations, setSlopeCalculation] = useState<SlopeCalculation[]>(
    []
  );
  const [selectedVesselId, setSelectedVesselId] = useState<number | null>(null);
  const [selectedDoor, setSelectedDoor] = useState<VesselDoorDTO | null>(null);
  const [vesselOrientations, setVesselOrientations] = useState<
    Record<number, "PORT" | "STARBOARD">
  >({});
  const [imageUrls, setImageUrls] = useState<Record<number, string>>({});
  const [showGraphsFlag, setShowGraphsFlag] = useState(false);
  const [assemblyId, setModalAssemblyId] = useState<number>(0);
  const [assembly, setModalAssemblyName] = useState<string>("");
  const [positiveSlopeCaution, setPositiveSlopeCaution] = useState<number>(0);
  const [positiveSlopeAcceptable, setPositiveSlopeAcceptable] =
    useState<number>(0);
  const [negativeSlopeAcceptable, setNegativeSlopeAcceptable] =
    useState<number>(0);
  const [negativeSlopeCaution, setNegativeSlopeCaution] = useState<number>(0);
  const { date, setDate } = useDateContext(); // Access the context
  const [bookingResources, setBookingResources] = useState<BookingResource[]>(
    []
  );
  const [refresh, setRefresh] = useState<Refresh>({
    refresh: false,
    showLoading: false,
  });
  const [loading, setLoading] = useState(true);
  const isInitialRender = useRef(true); // Ref at the component level

  /**
   * Closes the graph or day report modal.
   */
  const closeModal = () => {
    setShowGraphModal(false);
    setShowDayReportModal(false);
  };

  /**
   * Shows the day report modal.
   */
  const showReportForDay = () => {
    setShowDayReportModal(true);
  };

  /**
   * Fetches bookings with details for a given date.
   * @param date The date to fetch bookings for.
   * @returns A promise that resolves to the bookings details.
   */
  const fetchAllBookings = useCallback(
    async (date: string) => {
      return await fetchBookingsWithDetails(date);
    },
    [fetchBookingsWithDetails]
  );

  /**
   * Fetches tide data for a given date.
   * @param date The date to fetch tide data for.
   * @returns A promise that resolves to the tide data.
   */
  const fetchAllTides = useCallback(
    async (date: string) => {
      return await fetchTides(date);
    },
    [fetchTides]
  );

  //  Handle page refresh to clear state
  useEffect(() => {
    // Check if the page was actually refreshed
    if (sessionStorage.getItem("isReloaded") === "true") {
      sessionStorage.removeItem("isReloaded"); // Clear flag after detection
      navigate("/operations", { replace: true, state: null });
    }

    // Set the flag before the page unloads
    const handleBeforeUnload = () => {
      sessionStorage.setItem("isReloaded", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    setRefresh({ refresh: true, showLoading: true }); // Trigger fetch only on first load
  }, []);

  useEffect(() => {
    if (!refresh.refresh) return;
    fetchDataFromAPI(
      locationState,
      date,
      fetchAllBookings,
      fetchAllTides,
      fetchMooringLines,
      isInitialRender,
      setLoading,
      setBookingDetails,
      setDate,
      setTides,
      setMooringLines,
      setRefresh,
      isSameDayInUserTimezone,
      setLocationState
    );
  }, [refresh.refresh, date]);

  // Memoize filtered bookings to prevent unnecessary re-renders
  const filteredBookings = useMemo(() => {
    return (
      bookingsDetails?.bookings.filter(
        (booking) => booking.status !== "CANCELLED"
      ) ?? []
    );
  }, [bookingsDetails]);

  // Fetch booking resources, mooring line booking resources and set initial vessel orientations and images
  // useEffect(() => {
  //   const fetchAllResources = async () => {
  //     const initialOrientations: Record<number, "PORT" | "STARBOARD"> = {};
  //     const initialImages: Record<number, string> = {};
  //     const resourcesPromises = filteredBookings.map(async (booking) => {
  //       initialOrientations[booking.id] = booking.vesselOrientation as
  //         | "PORT"
  //         | "STARBOARD";
  //       initialImages[booking.id] =
  //         booking.vessel.images.find(
  //           (img) => img.orientation === booking.vesselOrientation
  //         )?.imagePath || "";

  //       try {
  //         const res = await fetchBookingResources(booking.id);
  //         const mooringLineRes = await fetchMooringLineBookingResource(
  //           booking.id
  //         );
  //         return { res: res || [], mooringLineRes: mooringLineRes || [] };
  //       } catch (error) {
  //         console.error(
  //           `Error fetching resources for booking ${booking.id}:`,
  //           error
  //         );
  //         return { res: [], mooringLineRes: [] };
  //       }
  //     });

  //     try {
  //       const results = await Promise.all(resourcesPromises);
  //       const resources = results.flatMap((result) => result.res);
  //       const mooringLineResources = results.flatMap(
  //         (result) => result.mooringLineRes
  //       );

  //       setBookingResources(resources);
  //       setMooringLineBookingResources(mooringLineResources);
  //       setVesselOrientations(initialOrientations);
  //       setImageUrls(initialImages);
  //     } catch (error) {
  //       console.error("Error fetching all resources:", error);
  //     }
  //   };

  //   fetchAllResources();
  // }, [
  //   filteredBookings,
  //   fetchBookingResources,
  //   fetchMooringLineBookingResource,
  // ]);

  useEffect(() => {
    fetchAllResourcesData(
      filteredBookings,
      fetchBookingResources,
      fetchMooringLineBookingResource,
      setBookingResources,
      setMooringLineBookingResources,
      setVesselOrientations,
      setImageUrls
    );
  }, [
    filteredBookings,
    fetchBookingResources,
    fetchMooringLineBookingResource,
  ]);

  /**
   * Opens the graph modal for a given vessel door and booking.
   * @param vesselDoorId The ID of the vessel door.
   * @param vesselDoorName The name of the vessel door.
   * @param vesselDoorHeight The height of the vessel door.
   * @param vessel The booking data.
   * @param assemblyDataForDoor The slope calculation data for the door.
   * @param assemblyId The ID of the assembly.
   * @param assembly The name of the assembly.
   * @param positiveSlopeCaution The positive slope caution value.
   * @param positiveSlopeAcceptable The positive slope acceptable value.
   * @param negativeSlopeAcceptable The negative slope acceptable value.
   * @param negativeSlopeCaution The negative slope caution value.
   */
  const handleGraphModalOpen = (
    vesselDoorId: number,
    vesselDoorName: string,
    vesselDoorHeight: number,
    vessel: EnrichedBooking,
    assemblyDataForDoor: SlopeCalculation[],
    assemblyId?: number,
    assembly?: string,
    positiveSlopeCaution?: number,
    positiveSlopeAcceptable?: number,
    negativeSlopeAcceptable?: number,
    negativeSlopeCaution?: number
  ) => {
    setModalBooking(vessel);
    setModalVesselDoorId(vesselDoorId);
    setModalVesselDoorHeight(vesselDoorHeight);
    setModalVesselDoorName(vesselDoorName);
    setSlopeCalculation(assemblyDataForDoor);
    if (assemblyId && assembly) {
      setModalAssemblyId(assemblyId);
      setModalAssemblyName(assembly);
      setPositiveSlopeCaution(positiveSlopeCaution || 0);
      setPositiveSlopeAcceptable(positiveSlopeAcceptable || 0);
      setNegativeSlopeAcceptable(negativeSlopeAcceptable || 0);
      setNegativeSlopeCaution(negativeSlopeCaution || 0);
    }
    setShowGraphModal(true);
  };

  return (
    <>
      <OperationHeader />
      <div id="capture-container">
        <ToastContainer />

        {bookingsDetails && (
          <OperationsGangwayReservations
            bookingResources={bookingResources}
            bookingDetails={bookingsDetails}
            updateGangwayReservation={updateGangwayReservation}
            fetchBookingResources={fetchBookingResources}
            handleRemoveBookingResource={handleRemoveBookingResource}
            setRefresh={setRefresh}
          />
        )}

        {showDayReportModal && bookingsDetails && (
          <NewReportPage
            selectedDate={date}
            closeModal={closeModal}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            bookingDetails={bookingsDetails}
            bookingResources={bookingResources}
            tideData={tides}
            bookings={filteredBookings}
            gangway={bookingsDetails?.gangways}
            platforms={bookingsDetails?.platforms}
            vesselOrientations={vesselOrientations}
            imageUrls={imageUrls}
            mooringLines={mooringLines}
            mooringLineBookingResources={mooringLineBookingResources}
          />
        )}

        {showGraphModal && modalBooking && (
          <OperationGraphModaL
            closeModal={closeModal}
            vesselDoorId={modalVesselDoorId}
            vesselDoorName={modalVesselDoorName}
            vesselDoorHeight={vesselDoorHeight}
            booking={modalBooking}
            doorSlopeData={slopeCalculations}
            modalAssemblyId={assemblyId}
            modalAssemblyName={assembly}
            positiveSlopeCaution={positiveSlopeCaution}
            positiveSlopeAcceptable={positiveSlopeAcceptable}
            negativeSlopeAcceptable={negativeSlopeAcceptable}
            negativeSlopeCaution={negativeSlopeCaution}
            bookingResources={bookingResources}
            handleRemoveBookingResource={handleRemoveBookingResource}
            setRefresh={setRefresh}
          />
        )}

        {showGraphsFlag && selectedVesselId && selectedDoor && (
          <OperationsShowGraphs
            vesselId={selectedVesselId}
            selectedDoor={selectedDoor}
            doorSlopeDataAssemblies={doorSlopeDataAssemblies}
            bookingResources={bookingResources}
            handleGraphModalOpen={handleGraphModalOpen}
            bookingData={filteredBookings}
          />
        )}
        {bookingsDetails && (
          <OperationsBookingTable
            bookings={bookingsDetails.bookings}
            selectedDate={date}
            setSelectedDate={setDate}
            showReportForDay={showReportForDay}
            setRefresh={setRefresh}
          />
        )}
        <OperationsMapTide tide={tides} />

        <OperationsMapContainer
          bookingResources={bookingResources}
          bookingsDetails={bookingsDetails}
          setSelectedVesselId={setSelectedVesselId}
          selectedDoor={selectedDoor}
          setSelectedDoor={setSelectedDoor}
          vesselOrientations={vesselOrientations}
          setVesselOrientations={setVesselOrientations}
          imageUrls={imageUrls}
          setImageUrls={setImageUrls}
          setShowGraphsFlag={setShowGraphsFlag}
          filteredBookings={filteredBookings}
          setDoorSlopeDataAssemblies={setDoorSlopeDataAssemblies}
          mooringLines={mooringLines}
          mooringLineBookingResources={mooringLineBookingResources}
          setMooringLineBookingResources={setMooringLineBookingResources}
          deletedMLBR={deletedMLBR}
          setDeletedMLBR={setDeletedMLBR}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        {
          /* Loading screen for the entire page */ loading &&
          refresh.showLoading && <LoadingScreen />
        }
      </div>
    </>
  );
}
export default Operation;

import "bootstrap/dist/css/bootstrap.css";
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
} from "react";
import "react-datetime/css/react-datetime.css";
import "moment-timezone";
import {
  ComposedChart,
  Bar,
  BarChart,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import html2canvas from "html2canvas";
import moment from "moment";
import { SlopeCalculation } from "Models/CalculationsDTO";
import { getColorBasedOnValue } from "../Operations/OperationUtils/OperationUtility";

interface ReportsGraphsProps {
  slopeData: SlopeCalculation[];
  mooringDateTime: string;
  departureDateTime: string;
  vesselName: string;
  berth: string;
  doorName: string;
  vesselOrientation: string;
  gangWay: string;
  handleCapturedGraph: (imgData: string) => void;
  positiveSlopeCaution: number;
  positiveSlopeAcceptable: number;
  negativeSlopeAcceptable: number;
  negativeSlopeCaution: number;
}

const ReportsGraphs = forwardRef(
  (
    {
      slopeData,
      mooringDateTime,
      departureDateTime,
      vesselName,
      berth,
      doorName,
      vesselOrientation,
      gangWay,
      handleCapturedGraph,
      positiveSlopeCaution,
      positiveSlopeAcceptable,
      negativeSlopeAcceptable,
      negativeSlopeCaution,
    }: ReportsGraphsProps,
    ref
  ) => {
    const graphRef = useRef<HTMLDivElement>(null);

    const captureGraph = () => {
      if (graphRef.current) {
        html2canvas(graphRef.current).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          handleCapturedGraph(imgData);
        });
      }
    };
    const formattedMooringDateTime = moment(mooringDateTime)
      .local()
      .format("MMMM DD, YYYY, HH:mm");
    const formattedDepartureDateTime = moment(departureDateTime)
      .local()
      .format("MMMM DD, YYYY, HH:mm");

    useEffect(() => {
      const timer = setTimeout(() => {
        captureGraph();
      }, 3000);

      return () => clearTimeout(timer);
    }, [slopeData]);

    return (
      <div className="reportChartFirstContainer">
        <div className="reportChartSecondContainer" ref={graphRef}>
          <div className="reportGraphTable">
            <table>
              <tbody>
                <tr>
                  <th className="reportGraphTableHeading">Vessel Name</th>
                  <th className="reportGraphTableHeading">Door Name</th>
                  <th className="reportGraphTableHeading">Berth</th>
                  <th className="reportGraphTableHeading">
                    Mooring Date and Time
                  </th>
                  <th className="reportGraphTableHeading">
                    Departure Date and Time
                  </th>
                  <th className="reportGraphTableHeading">
                    Vessel Orientation
                  </th>
                  <th className="reportGraphTableHeading">Gangway</th>
                </tr>
                <tr>
                  <td>{vesselName}</td>
                  <td>{doorName}</td>
                  <td>{berth}</td>
                  <td>{formattedMooringDateTime}</td>
                  <td>{formattedDepartureDateTime}</td>
                  <td>{vesselOrientation}</td>
                  <td>{gangWay}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <ResponsiveContainer>
            <ComposedChart
              width={200}
              height={225}
              data={slopeData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
              barCategoryGap={0}
              barGap={0}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(tick) =>
                  new Date(tick).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })
                }
              />
              <YAxis
                domain={[-40, 40]}
                allowDataOverflow={true}
                label={{
                  value: "% Slope",
                  angle: -90,
                  position: "insideLeft",
                  style: { textAnchor: "middle", fontSize: 13 },
                }}
              />
              <Bar
                dataKey="slopeMultiples"
                label={{ position: "top" }}
                barSize={40}
              >
                {slopeData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={getColorBasedOnValue(
                      entry.slope,
                      positiveSlopeCaution,
                      positiveSlopeAcceptable,
                      negativeSlopeAcceptable,
                      negativeSlopeCaution
                    )}
                    stroke={getColorBasedOnValue(
                      entry.slope,
                      positiveSlopeCaution,
                      positiveSlopeAcceptable,
                      negativeSlopeAcceptable,
                      negativeSlopeCaution
                    )}
                    strokeWidth={10}
                  />
                ))}
              </Bar>
              <ReferenceLine
                x={mooringDateTime}
                stroke="green"
                label="Mooring Time"
              />
              <ReferenceLine
                x={departureDateTime}
                stroke="red"
                label="Departure Time"
              />
              <Line
                type="monotone"
                dataKey="slope"
                stroke="#8884d8"
                dot={false}
                strokeWidth={2}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
);

export default ReportsGraphs;
